// src/features/dialog/dialogSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dialogs: {
    info: { open: false, message: '', title: 'Information', subTitle: '' },
    terms: { open: false, message: [], title: '', subTitle: '' }, 
    success: { open: false, message: '', title: 'Success', subTitle: '' },
    guide: { open: false }, // Removed `welcome` for consistency
  },
};

const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    showDialog(state, action) {
      const { dialogType, message, title, subTitle } = action.payload;
      if (state.dialogs[dialogType]) {
        state.dialogs[dialogType].open = true;
        state.dialogs[dialogType].message = message;
        state.dialogs[dialogType].title = title || state.dialogs[dialogType].title;
        state.dialogs[dialogType].subTitle = subTitle || '';
      } else {
        console.error(`Unknown dialog type: ${dialogType}`);
      }
    },
    hideDialog(state, action) {
      const dialogType = action.payload;
      if (state.dialogs[dialogType]) {
        state.dialogs[dialogType].open = false;
        
        // Reset properties based on dialog type
        if (dialogType === 'terms') {
          state.dialogs[dialogType].message = [];
        } else if (dialogType !== 'guide') {  // Ensure 'guide' remains unchanged
          state.dialogs[dialogType].message = '';
          state.dialogs[dialogType].title = '';
          state.dialogs[dialogType].subTitle = '';
        }
      } else {
        console.error(`Unknown dialog type: ${dialogType}`);
      }
    },
  },
});

export const { showDialog, hideDialog } = dialogSlice.actions;
export default dialogSlice.reducer;
