import { validateField } from "./master";

/**
 * Generates a validation function based on the provided field requirements.
 * 
 * @param {Array} fieldRequirements - A list of field requirements. Each item in the list should be an object
 *                                    with the following structure:
 *                                    {
 *                                      field: 'fieldName',
 *                                      type: 'validationType', // 
 * Accepted values: 'email', 'phoneNumber', 'website', 'address', 'content', 'fullSSN', 'partialSSN', 'dob', 'password', 'list', 'ein'...and whatever is else is accepted in master
 *                                      required: true/false,
 *                                      customMessage: 'optional custom error message'
 *                                    }
 * @returns {Function} - A validation function that validates the provided form data based on the field requirements.
 *
 * Usage:
 * const validateLocationDetails = generateValidator([
 *   { field: 'name', type: 'text', required: true },
 *   { field: 'number', type: 'phoneNumber', required: true },
 *   { field: 'email', type: 'email', required: true },
 *   { field: 'website', type: 'website', required: false },
 *   { field: 'addressParts', type: 'address', required: true },
 *   { field: 'description', type: 'text', required: true },
 *   { field: 'type', type: 'text', required: true },
 *   { field: 'customType', type: 'text', required: formData => formData.type === 'Other' }
 * ]);
 */
export function generateValidator(fieldRequirements) {
    return function validate(formData) {
        const errors = {};

        fieldRequirements.forEach(({ field, type, required, customMessage }) => {
            if (typeof required === 'function') {
                required = required(formData);
            }

            if (typeof type === 'function') {
                type = type(formData);
            }

            if (typeof customMessage === 'function') {
                customMessage = customMessage(formData);
            }

            if (required && !formData[field]) {
                errors[field] = customMessage || `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`;
            }

            if (formData[field]) {
                const error = validateField(type, formData[field]);
                if (error) errors[field] = error;
            }
        });

        return errors;
    };
}
