// useTransactions.js
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectTransactionCount, selectTransactions, selectTransactionsLoading } from "../../../../features/transactions/transactionsSelectors";
import { addTransactions, setLoading } from "../../../../features/transactions/transactionsSlice";
import { masterRequest } from "../../../../functions/apiStructures/masterRequest";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { showAlert } from "../../../../features/alerts/alertSlice";
import { useNavigate } from "react-router-dom";
const useTransactions = () => {
  const dispatch = useDispatch();
  const transactions = useSelector(selectTransactions);
  const transactionCount = useSelector(selectTransactionCount);
  const loading = useSelector(selectTransactionsLoading);
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const rowsPerPage = 10;

  useEffect(() => {
    const loadTransactions = async () => {
      dispatch(setLoading(true));
      console.log(page);
      const firebaseAuth = getAuth(); // Initialize Firebase Auth
  
      let authKey = null;
  
      // Get Firebase Auth user and token
      const user = await new Promise((resolve) => {
        onAuthStateChanged(firebaseAuth, (user) => {
          resolve(user);
        });
      });
  
      if (user) {
        try {
          // Retrieve the token from Firebase Auth
          authKey = await user.getIdToken(true);
          console.log("authKey retrieved from Firebase:", authKey);
        } catch (error) {
          console.error("Error retrieving token from Firebase:", error);
          dispatch(
            showAlert({ type: "error", message: "Error retrieving authentication token." })
          );
          dispatch(setLoading(false));
          return;
        }
      } else {
        console.log("No user authenticated, redirecting to sign-in.");
        dispatch(
          showAlert({ type: "warning", message: "You must log in to continue." })
        );
        navigate("/sign-in");
        dispatch(setLoading(false));
        return;
      }
  let data = null
      // Fetch transactions
      const responseData = await masterRequest(
        `merchant/transactions/data/?page=${page + 1}&page_size=${rowsPerPage}`,
        "GET",
        data = data,

        authKey= authKey
      );
      console.log(responseData);
  
      // Update Redux state with fetched transactions
      if (responseData.results) {
        dispatch(addTransactions(responseData.results));
      }
  
      dispatch(setLoading(false));
    };
  
    // Calculate the number of transactions required for the current page
    const transactionsNeeded = (page + 1) * rowsPerPage;
  
    // Only fetch more transactions if needed
    if (
      transactionsNeeded > transactions.length &&
      transactions.length < transactionCount
    ) {
      loadTransactions();
    }
  }, [dispatch, page, rowsPerPage, transactions.length, transactionCount, navigate]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return {
    transactions,
    transactionCount,
    loading,
    page,
    rowsPerPage,
    handlePageChange,
  };
};

export default useTransactions;
