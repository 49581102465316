import React from "react";
import { Box, Button, Typography, Stack } from "@mui/material";
import InfoListUI from "../molecules/infoListUI";
import ChangeDataDialogueUI from "../molecules/changeDataDialogueUI";

const UpdateRetryInfoPageUI = ({
  selectedRetryData,
  navigateBackToController,
  dialogOpen,
  handleItemClick,
  setDialogOpen,
  dialogData,
  formatValue,
  handleSubmit,
  formData,
  formatAddress,
  handleInputChange,
}) => {
  const isPersonalInfo = selectedRetryData?.displayName === "Personal Information";

  return (
    <Box sx={{ maxWidth: 500, width: "100%", mx: "auto", textAlign: "center" }}>
      {/* Main Header */}
      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
        Resubmit Required Information
      </Typography>

      {/* Subheader */}
      <Typography variant="body2" sx={{ color: "text.secondary", mb: 3 }}>
        Please update the following information accurately. If you submit the same information without corrections, it may lead to another failure. Double-check all details to ensure successful verification.
      </Typography>

      {/* Additional message for Personal Information */}
      {isPersonalInfo && (
        <Typography
          variant="body2"
          sx={{
            color: "error.main",
            fontWeight: "bold",
            mb: 2,
            textAlign: "left",
          }}
        >
          Note: Ensure you enter your full Social Security number. Incomplete or incorrect information will lead to verification issues.
        </Typography>
      )}

      {/* Info List */}
      <InfoListUI
        data={selectedRetryData.retryData}
        onItemClick={handleItemClick}
        formatValue={formatValue}
      />

      {/* Change Data Dialog */}
      {dialogOpen && (
        <ChangeDataDialogueUI
          open={dialogOpen}
          handleClose={() => setDialogOpen(false)}
          dialogData={dialogData}
          selectedRetryData={selectedRetryData}
          handleSubmit={handleSubmit}
          formData={formData}
          formatAddress={formatAddress}
          handleInputChange={handleInputChange}
        />
      )}

      {/* Navigation Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={navigateBackToController}
          sx={{ fontSize: '1rem', padding: '12px 24px', borderRadius: '8px', flex: 1, mr: 1 }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={navigateBackToController}
          sx={{ fontSize: '1rem', padding: '12px 24px', borderRadius: '8px', flex: 1, ml: 1 }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default UpdateRetryInfoPageUI;
