import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  Grid,
  Paper,
  TextField,
  Stack,
  CircularProgress,
  Divider,
} from "@mui/material";
import useCashbackUpdateUI from "../../../functional/molecules/useCashbackUpdateModalUI";
import CheckIcon from "@mui/icons-material/Check";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";

const cashbackTiers = [
  {
    name: "Standard",
    min: 5,
    max: 9.99,
    description:
      "A solid cashback rate that encourages regular customer engagement.",
    icon: <StarOutlineIcon sx={{ fontSize: 40 }} />,
    accentColor: "#FFD700", // Gold color for accent
  },
  {
    name: "Premium",
    min: 10,
    max: 14.99,
    description:
      "An attractive rate that significantly boosts customer loyalty.",
    icon: <EmojiEventsOutlinedIcon sx={{ fontSize: 40 }} />,
    accentColor: "#C0C0C0", // Silver color for accent
  },
  {
    name: "Elite",
    min: 15,
    max: Infinity,
    description:
      "Top-tier cashback rate that maximizes customer retention and satisfaction.",
    icon: <CheckCircleOutlineIcon sx={{ fontSize: 40 }} />,
    accentColor: "#CD7F32", // Bronze color for accent
  },
  {
    name: "Custom",
    min: 0,
    max: 0,
    description:
      "Set your own cashback rate tailored to your business needs.",
    icon: <CalculateOutlinedIcon sx={{ fontSize: 40 }} />,
    accentColor: "#A9A9A9", // Dark gray for accent
    isCustom: true,
  },
];

const TiersCard = ({ onBack }) => {
  const [selectedTier, setSelectedTier] = useState(null);
  const [customPercentage, setCustomPercentage] = useState("");
  const { handleSetCashback, isLoading, isSuccess, resetSuccess } =
    useCashbackUpdateUI();

  const handleTierSelect = async () => {
    if (selectedTier) {
      const value = selectedTier.isCustom
        ? parseFloat(customPercentage)
        : selectedTier.min;
      await handleSetCashback(value);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        onBack(); // Navigate back after success
        resetSuccess(); // Reset success state
      }, 1500); // Adjusted to 1.5 seconds for better user experience

      return () => clearTimeout(timer); // Cleanup timeout on unmount or isSuccess change
    }
  }, [isSuccess, onBack, resetSuccess]);

  return (
    <Paper
        elevation={3}
      sx={{
        padding: { xs: 4, sm: 6 },
        backgroundColor: "#FFFFFF",
        borderRadius: 4,
        maxWidth: 1200,
        
        margin: "auto",
        
      }}
    >
      {/* Header */}
      <Stack
        direction="column"
        alignItems="center"
        spacing={2}
        sx={{ mb: 5, textAlign: "center" }}
      >
        <Typography variant="h4" fontWeight="bold">
          Choose Your Cashback Tier
        </Typography>
        <Typography variant="body1" color="textSecondary" sx={{ maxWidth: 800 }}>
          Select a cashback rate that best suits your business goals and customer engagement strategy.
        </Typography>
        <Divider sx={{ width: "60%", borderColor: "grey.300" }} />
      </Stack>

      {/* Tiers Grid */}
      <Grid container spacing={4} justifyContent="center">
        {cashbackTiers.map((tier, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Paper
              elevation={selectedTier?.name === tier.name ? 4 : 1}
              onClick={() => setSelectedTier(tier)}
              sx={{
                padding: 3,
                textAlign: "center",
                borderRadius: 4,
                cursor: "pointer",
                transition: "transform 0.3s, box-shadow 0.3s",
                border: selectedTier?.name === tier.name
                  ? `2px solid ${tier.accentColor}`
                  : "1px solid #E0E0E0",
                "&:hover": {
                  transform: "translateY(-5px)",
                  boxShadow: "0 8px 20px rgba(0,0,0,0.1)",
                },
              }}
            >
              {/* Icon */}
              <Box
                sx={{
                  width: 60,
                  height: 60,
                  borderRadius: "50%",
                  backgroundColor: tier.accentColor,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "0 auto",
                  mb: 2,
                }}
              >
                {tier.icon}
              </Box>

              {/* Tier Name */}
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                {tier.name}
              </Typography>

              {/* Cashback Percentage or Input */}
              {!tier.isCustom ? (
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  sx={{ mb: 2 }}
                >
                  {tier.min}%
                </Typography>
              ) : (
                <TextField
                  value={customPercentage}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*\.?\d{0,2}$/.test(value)) {
                      setCustomPercentage(value);
                    }
                  }}
                  placeholder="0"
                  InputProps={{
                    endAdornment: (
                      <Typography variant="h4" sx={{ ml: 0.5 }}>
                        %
                      </Typography>
                    ),
                    style: { fontSize: "2rem", textAlign: "center" },
                  }}
                  variant="standard"
                  sx={{
                    width: "80%",
                    marginBottom: 2,
                    "& .MuiInput-underline:before": { borderBottom: "1px solid #A9A9A9" },
                    "& .MuiInput-underline:after": { borderBottom: `2px solid ${tier.accentColor}` },
                    "& .MuiInputBase-input": {
                      textAlign: "center",
                      fontSize: "2rem",
                      fontWeight: "bold",
                    },
                  }}
                />
              )}

              {/* Description */}
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ mb: 2 }}
              >
                {tier.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      {/* Action Buttons */}
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        justifyContent="center"
        sx={{ mt: 5 }}
      >
        <Button
          variant="outlined"
          onClick={onBack}
          sx={{
            width: { xs: "100%", sm: "200px" },
            textTransform: "none",
            borderRadius: "8px",
            fontSize: "1rem",
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          disabled={
            !selectedTier ||
            (selectedTier.isCustom
              ? !(customPercentage && parseFloat(customPercentage) > 0)
              : false)
          }
          onClick={handleTierSelect}
          sx={{
            width: { xs: "100%", sm: "200px" },
            textTransform: "none",
            borderRadius: "8px",
            fontSize: "1rem",
          }}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : isSuccess ? (
            <>
              <CheckIcon sx={{ mr: 1 }} /> Success
            </>
          ) : (
            "Submit"
          )}
        </Button>
      </Stack>
    </Paper>
  );
};

export default TiersCard;
