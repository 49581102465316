// CashBackLogic.js

import useCashbackUpdateModalUI from '../molecules/useCashbackUpdateModalUI';
import { useSelector } from 'react-redux';
import {
  selectCashbackHistory,
  selectCurrentCashback,
} from '../../../../features/cashback/cashbackSelectors';

const CashBackLogic = () => {
  const currentCashback = useSelector(selectCurrentCashback);
  const cashbackHistory = useSelector(selectCashbackHistory);

  const {
    handleCashbackChange,
    handleUpdateCashbackSubmit,
    cashbackValue,
    error,
    openSnackbar,
    handleCloseSnackbar,
  } = useCashbackUpdateModalUI();

  return {
    currentCashback,
    cashbackHistory,
    handleCashbackChange,
    handleUpdateCashbackSubmit,
    cashbackValue,
    error,
    openSnackbar,
    handleCloseSnackbar,
  };
};

export default CashBackLogic;
