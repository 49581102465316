// useCashbackUpdateUI.js

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useFullFetchFeedback from "../../../../functions/apiFetchers/fullPageLoaders";
import {
  addCashbackHistory,
  setCurrentCashback,
  setNextGeneralCashbackRate,
  setNextGold,
  setNextGoldRequiredSpend,
} from "../../../../features/cashback/cashbackSlice";
import {
  selectCashbackHistory,
  selectCurrentCashback,
} from "../../../../features/cashback/cashbackSelectors";

const useCashbackUpdateUI = () => {
  const [cashbackValue, setCashbackValue] = useState("");
  const [error, setError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar state
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const [isSuccess, setIsSuccess] = useState(false); // Success state
  const dispatch = useDispatch();
  const fetchFeedback = useFullFetchFeedback();
  const currentCashback = useSelector(selectCurrentCashback);
  const cashbackHistory = useSelector(selectCashbackHistory);

  const handleCashbackChange = (event) => {
    const value = event.target.value;
    if (value === "" || (value >= 3 && value <= 45)) {
      setCashbackValue(value);
      setError("");
    } else {
      setError("Please enter a valid percentage between 3% and 45%.");
    }
  };

  // Function to set cashback based on selected amount
  const handleSetCashback = async (value) => {
    if (value === "" || value < 3 || value > 45) {
      setError("Please enter a valid cashback percentage between 3% and 45%.");
      return;
    }

    setIsLoading(true); // Start loading
    setError("");

    await fetchFeedback({
      endpoint: `merchant/update-cashback/`,
      loaderType: "dialogueLoader",
      data: { cashback: value },
      requestType: "POST",
    
      onSuccess: (responseData) => {
        // Update Redux state with the new cashback values
        dispatch(setCurrentCashback(responseData.new_cashback));
        if (responseData.new_history) {
          dispatch(addCashbackHistory(responseData.new_history));
        }
        setCashbackValue(""); // Clear the input field
        setOpenSnackbar(true); // Open the success snackbar
        setIsSuccess(true); // Trigger success state
        setIsLoading(false); // Stop loading
        
      },
      onError: () => {
        setError("Failed to update cashback. Please try again."); // Show error feedback
        setCashbackValue("");
        setIsLoading(false); // Stop loading
      },
    });
  };



  const handleUpdatedSetCashback = async (value) => {

   

    await fetchFeedback({
      endpoint: `merchant/update-cashback/all/`,
      loaderType: "dialogueLoader",
      data: { cashback: value.cashback,  gold_cashback: value.gold_cashback,  gold_required_spend : value.gold_required_spend },
      requestType: "POST",
    
      onSuccess: (responseData) => {
        // Update Redux state with the new cashback values
        
        if (value.cashback !== null) {
          dispatch(setNextGeneralCashbackRate(value.cashback));
        }
      
        if (value.gold_cashback !== null) {
          dispatch(setNextGold(value.gold_cashback));
        }
      
        if (value.gold_required_spend !== null) {
          dispatch(setNextGoldRequiredSpend(value.gold_required_spend));
        }
        
      },
      onError: () => {
        setError("Failed to update cashback. Please try again."); // Show error 
      },
    });
  };


  // Function to handle form submission for custom amount
  const handleCustomCashbackSubmit = async (event) => {
    event.preventDefault(); // Prevent form submission default behavior

    if (cashbackValue === "" || cashbackValue < 3 || cashbackValue > 45) {
      setError("Please enter a valid cashback percentage between 3% and 45%.");
      return;
    }

    await handleSetCashback(cashbackValue);
  };

  // Snackbar handler to close the snackbar
  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const resetSuccess = () => {
    setIsSuccess(false);
  };

  return {
    cashbackValue,
    handleCashbackChange,
    handleSetCashback,
    handleCustomCashbackSubmit,
    error,
    openSnackbar,
    handleCloseSnackbar,
    currentCashback,
    cashbackHistory,
    isLoading,
    isSuccess,
    resetSuccess,
  };
};

export default useCashbackUpdateUI;
