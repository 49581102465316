import DeleteDialog from "../../../../../components/molecules/dialogues/delete";

const DeleteDailog = ({ open, onClose, onConfirm }) => {
  return (
    <DeleteDialog
      open={open}
      onClose={onClose}
      title={"Are you sure you want to delete this Hills Images Album?"}
      content={
        "This action cannot be undone. Once deleted, these images will be permanently removed from your account"
      }
      onConfirm={onConfirm}
    />
  );
};

export default DeleteDailog;
