import { useFakeFetch } from "../../config";
import { masterComponentFakeFetch } from "../apiFakeStructures/master";
import { deleteData } from "./fetchTypes/deleteData ";
import { getData } from "./fetchTypes/get";
import { postData } from "./fetchTypes/post";
import { postFormData } from "./fetchTypes/postForm";


export async function masterRequest(endpoint, type, data = null ,authKey=null) {

  if (useFakeFetch) {
    // Use fake fetch
    return masterComponentFakeFetch(endpoint, type, data);
  }
  else {
    switch (type.toUpperCase()) {
      case "GET":
        return await getData(endpoint, authKey);
      case "POST":
        return await postData(endpoint, data, authKey);
      case "POSTFORM":
        return await postFormData(endpoint, data, authKey);
      case "DELETE":
        return await deleteData(endpoint, authKey);
      default:
        throw new Error(`Unknown request type: ${type}`);
    }
  }
}
  