import React from "react";

import MainLayout from "../../../../development/page/parts/layout/MainLayout/index";
import CashbackContainer from "../organisms/cashbackComponent";
const CashbackPage = () => {
  return (
    <MainLayout>
      <CashbackContainer />
    </MainLayout>
  );
};

export default CashbackPage;
