import React from 'react';
import { Typography, Box } from '@mui/material';

function ErrorMessage(props) {
  const { message } = props;

  return (
    <Box style={{ marginTop: '8px', textAlign: 'center' }}>
      <Typography
        variant="body2"
        style={{
          fontSize: '15px',
          fontFamily: 'SF Pro Text, Helvetica Neue, Arial, sans-serif',
          color: '#FF3B30',
        }}
      >
        {message}
      </Typography>
    </Box>
  );
}

export default ErrorMessage;
