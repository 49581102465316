// import CustomInputDialog from "../../../../components/molecules/dialogues/customInput";
import ConfirmDialog from "../../../../../components/molecules/dialogues/confirm";


const ChangeConfirmdailog = ({
    open,
    onClose,
    formVisible,
    handleFormVisible,
    }) => {
    return (
        <>
        <ConfirmDialog
            open={open && !formVisible}
            onClose={onClose}
            onConfirm={() => handleFormVisible(true)}
            title="Change Credentials?"
            content="Are you sure you want to change the username and password? All registers will need new credentials. Employees will also need to be updated."
        />
        </>
    )
}

export default ChangeConfirmdailog