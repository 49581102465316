import React from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const InfoResubmittedSuccess = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '300px',
        textAlign: 'center',
        padding: 4,
        borderRadius: '16px',
      }}
    >
      <CheckCircleIcon color="primary" sx={{ fontSize: 80, mb: 3 }} />
      <Typography
        variant="h4"
        component="h2"
        sx={{
          mb: 2,
          fontWeight: 700,
          fontFamily: 'Helvetica, Arial, sans-serif',
          color: '#333333',
        }}
      >
        Information Resubmitted Successfully
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          mb: 4,
          color: 'text.secondary',
          fontFamily: 'Helvetica, Arial, sans-serif',
          maxWidth: '600px',
        }}
      >
        Your information has been successfully resubmitted. We will review it shortly. Thank you for ensuring your information is accurate.
      </Typography>
    </Box>
  );
};

export default InfoResubmittedSuccess;
