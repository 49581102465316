import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import DynamicForm from '../../../../../../../components/atoms/inputs/dynamic';
import { useDispatch } from 'react-redux';
import { addOwner} from "../../../../../../../features/verification/verificationInfoSlice";
import { generateValidator } from '../../../../../../../functions/validators/generateValidator';
const OwnerGeneralInfoPageInputsUI = ({ ownerData, handleBack, handleSubmit }) => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    date_of_birth: '',
    ssn: '',
    address: { address: '', city: '', state: '', zip_code: '' },
  });
  const dispatch = useDispatch();

  // Populate form fields if editing an existing owner
  useEffect(() => {
    if (ownerData) {
      setFormData(ownerData);
    }
  }, [ownerData]);

  const fieldConfig = [
    { type: "text", label: "First Name", name: "first_name", grid: { xs: 12, sm: 6 } },
    { type: "text", label: "Last Name", name: "last_name", grid: { xs: 12, sm: 6 } },
    { type: "date", label: "Date of Birth", name: "date_of_birth", grid: { xs: 12, sm: 6 } },
    { type: "ssn", label: "SSN", name: "ssn", grid: { xs: 12, sm: 6 }, props: { fullSSN: true } },
    { type: "address", label: "Address", name: "address", grid: { xs: 12 } },
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleFormSubmit = () => {
    // Validation logic for required fields
    const validateOwnerDetails = generateValidator([
      { field: "first_name", type: "content", required: true, customMessage: "First name is required." },
      { field: "last_name", type: "content", required: true, customMessage: "Last name is required." },
      { field: "date_of_birth", type: "dob", required: true },
      { field: "ssn", type: "fullSsn", required: true, customMessage: "Full SSN is required." },
      { field: "address", type: "address", required: true },
    ]);

    const errors = validateOwnerDetails(formData);
    if (Object.keys(errors).length !== 0) {
      setErrors(errors);
      return;
    }
    dispatch(addOwner(formData));
    
    // Submit form data through the parent handler
    handleSubmit();
  };

  const isFormComplete = formData.first_name && formData.last_name && formData.date_of_birth && formData.address;

  return (
    <Box sx={{ maxWidth: 600, width: "100%", mx: "auto", textAlign: "center" }}>
      {/* Header */}
      <Typography variant="h5" sx={{ mb: 1 }}>
        Owner Information (25%+ Ownership)
      </Typography>
      {/* Subheader */}
      <Typography variant="subtitle1" sx={{ mb: 3, color: 'text.secondary' }}>
        Please enter details for an owner with at least 25% ownership in the business.
      </Typography>
      
      <DynamicForm
        fieldConfig={fieldConfig}
        formData={formData}
        handleInputChange={handleInputChange}
        errors={errors}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 3,
        }}
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleBack}
          sx={{
            fontSize: '1rem',
            padding: '12px 24px',
            borderRadius: '8px',
            flex: 1,
            mr: 1,
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!isFormComplete}
          onClick={handleFormSubmit}
          sx={{
            fontSize: '1rem',
            padding: '12px 24px',
            borderRadius: '8px',
            flex: 1,
            ml: 1,
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default OwnerGeneralInfoPageInputsUI;
