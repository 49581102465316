import React, { useEffect, useState } from "react";
import { TextField, Autocomplete } from "@mui/material";

const OptionOrInputField = ({
  label,
  options = [],
  value,
  onChange,
  name,
  error = false,
  errorMessageAndhelp = "",
  allowCustomInput = true,
  commonStyles = {},
  InputLabelProps = {},
  variant = "outlined",
  multiline = false,
  rows =4,

}) => {
  // Internal state to manage the input field value
  const [internalValue, setInternalValue] = useState(value || "");

  // Sync internal state with the external value prop
  useEffect(() => {
    setInternalValue(value || "");
  }, [value]);

  const handleInputChange = (_, newValue) => {
    setInternalValue(newValue); // Update internal state
    if (allowCustomInput && onChange) {
      onChange({
        target: { name, value: newValue },
      });
    }
  };

  const handleOptionChange = (_, selectedOption) => {
    const newValue = selectedOption ? selectedOption.value : "";
    setInternalValue(newValue); // Update internal state
    if (onChange) {
      onChange({
        target: { name, value: newValue },
      });
    }
  };

  return (
    <Autocomplete
        key={`${name}`} 
      freeSolo={allowCustomInput}
      options={options}
      getOptionLabel={(option) => (option.label ? option.label : "")}
      value={options.find((opt) => opt.value === internalValue) || null}
      onInputChange={handleInputChange}
      onChange={handleOptionChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          name={name}
          error={error}
          helperText={errorMessageAndhelp}
          sx={commonStyles}
          InputLabelProps={InputLabelProps}
          multiline={multiline}
      rows={multiline ? rows : undefined}

       
          
        />
      )}
    />
  );
};

export default OptionOrInputField;