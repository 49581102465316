import { useMediaQuery } from "@mui/material";
import SignUpPageContainerTwo from "../../../../components/pageStructures/authPages/combined/pages/signUpPageContainerTwo";
import SignUpFormUI from "../molecules/SignUpFormUI";

const SignUpPage = () => {
  const isMobile = useMediaQuery("(max-width:899px)"); // Use a CSS media query string

  return (
    <>
      {isMobile ? (
        <SignUpFormUI />
      ) : (
        <SignUpPageContainerTwo>
          <SignUpFormUI />
        </SignUpPageContainerTwo>
      )}
    </>
  );
};

export default SignUpPage;