import React, { useState } from "react";
import CustomInputDialog from "../../../../../../../components/molecules/dialogues/customInput";

import GeneralInput from "../../../../../../../components/atoms/inputs/GeneralInput";

// dialogData is in this structure {displayName: {serverName: value}}

const ChangeDataDialogueUI = ({
  open,
  handleClose,
  handleSubmit,
  formData,
  formatAddress,
  handleInputChange,
}) => {
  // Render the dialog
  return (
    <CustomInputDialog
      open={open}
      onClose={handleClose}
      title={"Update your information"}
      subTitle={"Update your information"}
      onSubmit={handleSubmit}
    >
      {Object.entries(formData).map(([displayName, serverData]) => {
        const serverName = Object.keys(serverData)[0];
        let value = serverData[serverName];
        let inputType;

        switch (serverName) {
          case "address":
            inputType = "address";
            value = formatAddress(value); // Format address value
            break;
          case "ssn":
            inputType = "ssn";
            break;
          case "date_of_birth":
            inputType = "date";
            break;
          default:
            inputType = "text";
            break;
        }

        return (
          <GeneralInput
            key={displayName}
            type={inputType}
            label={displayName}
            name={serverName}
            value={value}
            fullSSN={true}
            onChange={(e) =>
              handleInputChange(displayName, serverName, e.target.value)
            }
          />
        );
      })}
    </CustomInputDialog>
  );
};

export default ChangeDataDialogueUI;
