import React, { useState } from "react";
import {
  Typography,
  TextField,
  Button,
  Box,
  Divider,
  CircularProgress,
  IconButton,
  Grid,
  Paper,
  Tooltip,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CalculateIcon from "@mui/icons-material/Calculate";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";

const LargeTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .MuiTooltip-tooltip`]: {
    fontSize: theme.typography.pxToRem(16),
    maxWidth: 350,
  },
}));

const CostCalculatorModal = ({ onClose }) => {
  const [customCashback, setCustomCashback] = useState("");
  const [revenue, setRevenue] = useState("");
  const [includeIncrementalSales, setIncludeIncrementalSales] = useState(true);
  const [results, setResults] = useState({
    currentFee: "0.00",
    cashbackCost: "0.00",
    comparisonSavings: "0.00",
    incrementalSales: "0.00",
    incrementalProfit: "0.00",
  });
  const [error, setError] = useState("");
  const [isCalculating, setIsCalculating] = useState(false);

  const getIncreaseSalesPercentage = (cashback) => {
    if (cashback < 1) return 1;
    if (cashback < 3) return 3;
    if (cashback < 5) return 5;
    return 10;
  };

  const handleCashbackChange = (e) => {
    setCustomCashback(e.target.value);
    if (error) setError("");
  };

  const handleRevenueChange = (e) => {
    setRevenue(e.target.value);
    if (error) setError("");
  };

  const handleToggleChange = (e) => {
    setIncludeIncrementalSales(e.target.checked);
  };

  const handleCalculate = () => {
    const cashbackValue = parseFloat(customCashback);
    const revenueValue = parseFloat(revenue);

    if (
      isNaN(cashbackValue) ||
      cashbackValue < 0 ||
      cashbackValue > 100 ||
      isNaN(revenueValue) ||
      revenueValue < 0
    ) {
      setError("Please enter valid positive numbers for all fields.");
      setResults({
        currentFee: "0.00",
        cashbackCost: "0.00",
        comparisonSavings: "0.00",
        incrementalSales: "0.00",
        incrementalProfit: "0.00",
      });
      return;
    }

    setIsCalculating(true);

    setTimeout(() => {
      const creditCardFeeRate = 0.03;
      const cashbackCost = (cashbackValue / 100) * revenueValue;
      const currentFee = revenueValue * creditCardFeeRate;
      const comparisonSavings = currentFee - cashbackCost;

      let incrementalSales = 0;
      let incrementalProfit = 0;
      if (includeIncrementalSales) {
        const increaseSalesPercentage = getIncreaseSalesPercentage(cashbackValue);
        incrementalSales = (increaseSalesPercentage / 100) * revenueValue;
        incrementalProfit = incrementalSales - cashbackCost;
      }

      setResults({
        currentFee: currentFee.toFixed(2),
        cashbackCost: cashbackCost.toFixed(2),
        comparisonSavings: comparisonSavings.toFixed(2),
        incrementalSales: incrementalSales.toFixed(2),
        incrementalProfit: incrementalProfit.toFixed(2),
      });
      setIsCalculating(false);
    }, 1000);
  };

  const handleReset = () => {
    setCustomCashback("");
    setRevenue("");
    setIncludeIncrementalSales(true);
    setResults({
      currentFee: "0.00",
      cashbackCost: "0.00",
      comparisonSavings: "0.00",
      incrementalSales: "0.00",
      incrementalProfit: "0.00",
    });
    setError("");
  };

  return (
    <Box
      sx={{
        position: "relative",
       
    
        margin: "auto",

        borderRadius: 2,
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        gap: 4,
      }}
    >
    
      <Box sx={{ flex: 1 }}>
        <Typography
          id="cost-calculator-title"
          variant="h4"
          component="h2"
          gutterBottom
          textAlign="center"
          fontWeight="bold"
          sx={{ fontSize: { xs: "1.75rem", md: "2.25rem" } }}
        >
          Cashback Cost & Business Impact Calculator
        </Typography>

        <Divider sx={{ mb: 3 }} />

        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12}>
            <LargeTooltip
              title="Enter the cashback percentage you plan to offer your customers."
              arrow
            >
              <TextField
                label="Cashback Percentage (%)"
                variant="outlined"
                fullWidth
                value={customCashback}
                onChange={handleCashbackChange}
                error={!!error}
                helperText={error ? "" : "Enter a value between 0 and 100."}
                type="number"
                inputProps={{ min: 0, max: 100, step: 0.1 }}
                sx={{ fontSize: "1.25rem" }}
                InputProps={{
                  endAdornment: (
                    <LargeTooltip
                      title="This determines how much cashback your customers receive."
                      arrow
                    >
                      <InfoIcon
                        sx={{ ml: 2, color: "action.active", fontSize: 24 }}
                      />
                    </LargeTooltip>
                  ),
                }}
                InputLabelProps={{
                  sx: { fontSize: "1.25rem" },
                }}
                FormHelperTextProps={{
                  sx: { fontSize: "1rem" },
                }}
              />
            </LargeTooltip>
          </Grid>
          <Grid item xs={12}>
            <LargeTooltip
              title="Enter your current total revenue in USD."
              arrow
            >
              <TextField
                label="Current Revenue ($)"
                variant="outlined"
                fullWidth
                value={revenue}
                onChange={handleRevenueChange}
                error={!!error}
                helperText={error ? "" : "Enter your current revenue."}
                type="number"
                inputProps={{ min: 0, step: 0.01 }}
                sx={{ fontSize: "1.25rem" }}
                InputProps={{
                  endAdornment: (
                    <LargeTooltip
                      title="This is your total sales revenue before implementing cashback."
                      arrow
                    >
                      <InfoIcon
                        sx={{ ml: 2, color: "action.active", fontSize: 24 }}
                      />
                    </LargeTooltip>
                  ),
                }}
                InputLabelProps={{
                  sx: { fontSize: "1.25rem" },
                }}
                FormHelperTextProps={{
                  sx: { fontSize: "1rem" },
                }}
              />
            </LargeTooltip>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Switch
                    checked={includeIncrementalSales}
                    onChange={handleToggleChange}
                    color="primary"
                    sx={{ transform: "scale(1.3)" }}
                  />
                }
                label={
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "1.25rem" }}
                  >
                    Include Incremental Sales from Cashback
                  </Typography>
                }
              />
              <LargeTooltip
                title="Toggle to include or exclude the additional sales generated by offering cashback."
                arrow
              >
                <InfoIcon
                  sx={{ ml: 2, color: "action.active", fontSize: 24 }}
                />
              </LargeTooltip>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", gap: 2, mb: 4 }}>
          <Button
            variant="contained"
            startIcon={<CalculateIcon sx={{ fontSize: 24 }} />}
            onClick={handleCalculate}
            disabled={
              isCalculating ||
              customCashback === "" ||
              revenue === ""
            }
            fullWidth
            size="large"
            sx={{
              padding: "12px 20px",
              fontSize: "1.25rem",
            }}
          >
            {isCalculating ? (
              <CircularProgress size={28} color="inherit" />
            ) : (
              "Calculate"
            )}
          </Button>
          <Button
            variant="outlined"
            onClick={handleReset}
            disabled={isCalculating && !results}
            fullWidth
            size="large"
            sx={{
              padding: "12px 20px",
              fontSize: "1.25rem",
            }}
          >
            Reset
          </Button>
        </Box>
      </Box>

      <Box sx={{ flex: 1 }}>
        <Paper elevation={3} sx={{ p: 4, bgcolor: "grey.100", height: "100%" }}>
          <Typography
            variant="h5"
            gutterBottom
            textAlign="center"
            sx={{ fontSize: { xs: "1.75rem", md: "2rem" } }}
          >
            Calculation Results
          </Typography>
          <Divider sx={{ mb: 3 }} />

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <LargeTooltip
                title="This is the amount you currently pay in credit card processing fees."
                arrow
              >
                <Typography
                  variant="h6"
                  sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
                >
                  Current Credit Card Fees (3%):
                </Typography>
              </LargeTooltip>
              <Typography
                variant="h4"
                color="primary"
                sx={{ fontSize: "1.75rem", fontWeight: "bold" }}
              >
                ${new Intl.NumberFormat().format(results.currentFee)}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <LargeTooltip
                title="Total cost of offering the specified cashback percentage."
                arrow
              >
                <Typography
                  variant="h6"
                  sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
                >
                  Cashback Cost:
                </Typography>
              </LargeTooltip>
              <Typography
                variant="h4"
                color="secondary"
                sx={{ fontSize: "1.75rem", fontWeight: "bold" }}
              >
                ${new Intl.NumberFormat().format(results.cashbackCost)}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <LargeTooltip
                title="Savings achieved by choosing cashback over standard credit card fees."
                arrow
              >
                <Typography
                  variant="h6"
                  sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
                >
                  Savings Compared to Fees:
                </Typography>
              </LargeTooltip>
              <Typography
                variant="h4"
                color="green"
                sx={{ fontSize: "1.75rem", fontWeight: "bold" }}
              >
                ${new Intl.NumberFormat().format(results.comparisonSavings)}
              </Typography>
            </Grid>

            {includeIncrementalSales && (
              <Grid item xs={12} sm={6}>
                <LargeTooltip
                  title="Additional sales generated as a result of offering cashback."
                  arrow
                >
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
                  >
                    Incremental Sales:
                  </Typography>
                </LargeTooltip>
                <Typography
                  variant="h4"
                  color="info.main"
                  sx={{ fontSize: "1.75rem", fontWeight: "bold" }}
                >
                  ${new Intl.NumberFormat().format(results.incrementalSales)}
                </Typography>
              </Grid>
            )}

            {includeIncrementalSales && (
              <Grid item xs={12}>
                <Divider sx={{ my: 3 }} />
                <LargeTooltip
                  title="Additional profit generated after accounting for cashback costs."
                  arrow
                >
                  <Typography
                    variant="h6"
                    textAlign="center"
                    sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
                  >
                    Incremental Profit:
                  </Typography>
                </LargeTooltip>
                <Typography
                  variant="h3"
                  color="success.main"
                  textAlign="center"
                  sx={{ fontSize: "2rem", fontWeight: "bold" }}
                >
                  ${new Intl.NumberFormat().format(results.incrementalProfit)}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Paper>
      </Box>
    </Box>
  );
};

export default CostCalculatorModal;
