import { Box, Typography } from "@mui/material";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import GeneralButton from "../../../../components/atoms/buttons/GeneralButton";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import { useSelector } from "react-redux";
import { selectEmployees } from "../../../../features/register/registerSelectors";
import useFullFetchFeedback from "../../../../functions/apiFetchers/fullPageLoaders";

const EmployeeManagementCardUI = ({ manageEmployees }) => {
  const employeeList = useSelector(selectEmployees);
  const fetchFeedback = useFullFetchFeedback();

  // Function to send email to a single employee
  const sendEmailToOneEmployeeSubmit = async (email) => {
    await fetchFeedback({
      endpoint: `merchant/send/credentials/`,
      loaderType: "dialogueLoader",
      data: { emails: [email] },
      requestType: "POST",
      loaderMessage: `Sending credentials to ${email}...`,
      successMessage: `Credentials sent to ${email} successfully!`,
    });
  };

  // Function to send emails to all employees
  const sendEmailToAllEmployeesSubmit = async () => {
    const emailList = employeeList.map((employee) => employee.email);
    await fetchFeedback({
      endpoint: `merchant/send/credentials/`,
      loaderType: "dialogueLoader",
      data: { emails: emailList },
      requestType: "POST",
      loaderMessage: "Sending credentials to all employees...",
      successMessage: "Credentials sent to all employees successfully!",
    });
  };

  return (
<Box
  sx={{
    flex: 1,
    maxWidth: "50%",
    "@media (max-width: 768px)": {
      maxWidth: "100%",
    },
  }}
>
  <Box
    sx={{
      background: "white",
      display: "flex",
      flexDirection: "column",
      padding: 4,
      borderRadius: 2,
      width: "90%",
      margin: "auto",
      height: "100%",
      "@media (max-width: 768px)": {
        width: "100%",
        marginTop: "20px",
        padding: 2,
      },
    }}
  >
    {/* Title and Subtitle */}
    <Box sx={{ textAlign: "center", marginBottom: 4 }}>
      <Typography variant="h2" component="h1" sx={{ marginBottom: 1 }}>
        Employee Management
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "text.secondary",
          paddingY: "10px",
          letterSpacing: "0.5px",
          width: "80%",
          lineHeight: "1.5",
          margin: "auto",
        }}
      >
        Manage employees who will receive login credentials for the register.
      </Typography>
    </Box>

    {/* Employee List or No Employees */}
    <Box sx={{ width: "100%", marginBottom: 4 }}>
      {employeeList.length === 0 ? (
        <Box
          sx={{
            textAlign: "center",
            marginY: 4,
          }}
        >
          <PersonAddAltOutlinedIcon
            sx={{ fontSize: 64, color: "lightgray", marginBottom: 2 }}
          />
          <Typography variant="h5">No Employees Added</Typography>
          <Typography
            variant="body2"
            sx={{ marginTop: 1, color: "text.secondary" }}
          >
            Add employees to assign login credentials for the register.
          </Typography>
        </Box>
      ) : (
        <Box>
          {employeeList.map((employee) => (
            <Box
              key={employee.email}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "1px solid #e0e0e0",
                paddingY: "10px",
              }}
            >
              <Typography variant="body1" sx={{ fontWeight: "normal" }}>
                {employee.first_name} {employee.last_name}
              </Typography>
              <GeneralButton
                label={
                  <>
                    <ArrowOutwardOutlinedIcon />
                    Send Credentials
                  </>
                }
                type="PlainText"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={() => sendEmailToOneEmployeeSubmit(employee.email)}
              />
            </Box>
          ))}
        </Box>
      )}
    </Box>

    {/* Buttons at the Bottom */}
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "auto", // Ensures buttons stay at the bottom
        width: "100%",
      }}
    >
      <GeneralButton
        label={
          <>
            Send To All
            <ArrowOutwardOutlinedIcon style={{ marginLeft: "8px" }} />
          </>
        }
        type="circularIcon"
        variant="outlined"
        onClick={sendEmailToAllEmployeesSubmit}
      />

      <GeneralButton
        label={
          <>
            Manage Employees
            <ArrowOutwardOutlinedIcon style={{ marginLeft: "8px" }} />
          </>
        }
        type="circularIcon"
        variant="outlined"
        onClick={ manageEmployees}
      />
    </Box>
  </Box>
</Box>
  );
};

export default EmployeeManagementCardUI;