// src/components/VerificationDialog.js
import React, { useEffect } from 'react';
import {
  Dialog,
  Box,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CodeInputField from './molecule/CodeInputField';
import ResendCodeLink from './molecule/ResendCodeLink';
import ErrorMessage from './molecule/ErrorMessage';
import AccountLoading from './molecule/AccountLoading';
import CodeLoading from './molecule/CodeLoading';
import ResendCodeLoading from './molecule/ResendCodeLoading';
import Success from './molecule/Success'; // Import the Success component

function VerificationDialog(props) {
  const {
    email,
    isLoadingCode,
    isLoadingAccount,
    isResendingCode,
    isSuccess,
    errorMessage,
    onResendCode,
    open,
    inputRefs,
    codeValues,
    onChange,
    onKeyDown,
    onClose,
    onGoToDashboard,
  } = props;

  // Focus the first input field when dialog opens or when loading finishes
  useEffect(() => {
    if (!isLoadingCode && !isLoadingAccount && open && !isSuccess) {
      // Ensure the inputRefs are properly populated before focusing
      if (inputRefs.current[0]) {
        inputRefs.current[0].focus();
      }
    }
  }, [isLoadingCode, isLoadingAccount, open, inputRefs, isSuccess]);

  const inputFields = [];
  for (let i = 0; i < 5; i++) {
    inputFields.push(
      <CodeInputField
        key={i}
        index={i}
        totalLength={5}
        inputRef={(el) => {
          inputRefs.current[i] = el;
        }}
        value={codeValues[i]}
        onChange={(e) => onChange(i, e.target.value)}
        onKeyDown={(e) => onKeyDown(i, e)}
      />
    );
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderRadius: '20px',
          padding: '50px',
          backgroundColor: '#FFFFFF',
          boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
          maxWidth: '500px',
          width: '90%',
          height: '420px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      }}
    >
      <Box
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <IconButton
          onClick={onClose}
          style={{
            position: 'absolute',
            right: '2px',
            top: '2px',
            color: '#8E8E93',
          }}
        >
          <CloseIcon style={{ fontSize: '24px' }} />
        </IconButton>

        {/* Render Success component when isSuccess is true */}
        {isSuccess ? (
          <Success onGoToDashboard={onGoToDashboard} />
        ) : isLoadingAccount ? (
          <AccountLoading />
        ) : isLoadingCode ? (
          <CodeLoading />
        ) : isResendingCode ? (
          <ResendCodeLoading />
        ) : (
          <>
            <Typography
              variant="h6"
              style={{
                fontSize: '24px',
                fontWeight: '600',
                fontFamily: 'SF Pro Text, Helvetica Neue, Arial, sans-serif',
                color: '#000000',
                marginBottom: '24px',
              }}
            >
              Verify Your Identity
            </Typography>
            <Typography
              variant="body1"
              style={{
                fontSize: '17px',
                fontFamily: 'SF Pro Text, Helvetica Neue, Arial, sans-serif',
                color: '#3C3C43',
                textAlign: 'center',
                marginBottom: '24px',
              }}
            >
              Enter the verification code sent to{' '}
              <strong>{email}</strong>.
            </Typography>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '12px',
                marginBottom: '24px',
              }}
            >
              {inputFields}
            </Box>
            {errorMessage && <ErrorMessage message={errorMessage} />}
            <ResendCodeLink onResendCode={onResendCode} />
          </>
        )}
      </Box>
    </Dialog>
  );
}

export default VerificationDialog;
