// src/components/NotFound/NotFoundUI.js
import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import PropTypes from 'prop-types';

const NotFoundUI = ({ onGoHome }) => {
  return React.createElement(
    Box,
    {
      sx: {
        minHeight: '100vh', // Full viewport height
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFFFFF', // White background
        textAlign: 'center',
        padding: 2,
      },
    },
    React.createElement(
      Typography,
      {
        variant: 'h2',
        component: 'h1',
        sx: {
          color: '#00A884', // Chime Green
          fontWeight: 700,
          mb: 3,
          fontFamily: 'Roboto, sans-serif', // Modern sans-serif font
        },
      },
      'Clavaa'
    ),
    React.createElement(
      Typography,
      {
        variant: 'h4',
        component: 'h2',
        sx: {
          color: '#333333', // Dark Gray
          fontWeight: 500,
          mb: 2,
        },
      },
      "Oops! Page Not Found"
    ),
    React.createElement(
      Typography,
      {
        variant: 'subtitle1',
        sx: {
          color: '#555555',
          mb: 4,
          maxWidth: '600px',
        },
      },
      "The page you're looking for doesn't exist or has been moved. Let us help you get back on track."
    ),
    React.createElement(
      Button,
      {
        variant: 'contained',
        onClick: onGoHome,
        sx: {
          backgroundColor: '#00A884', // Chime Green
          color: '#FFFFFF',
          paddingX: 4,
          paddingY: 1.5,
          fontSize: '1rem',
          textTransform: 'none',
          borderRadius: '8px',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'background-color 0.3s ease, transform 0.3s ease',
          '&:hover': {
            backgroundColor: '#008e6b', // Darker green on hover
            transform: 'translateY(-2px)',
            boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.15)',
          },
        },
      },
      'Go to Home'
    )
  );
};

// Define PropTypes for better type checking
NotFoundUI.propTypes = {
  onGoHome: PropTypes.func.isRequired,
};

export default NotFoundUI;
