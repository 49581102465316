
import { Box } from "@mui/material";
import PageHeader from "../../../../components/molecules/pageHeader/PageHeader";
import MainLayout from "../../../../development/page/parts/layout/MainLayout/index";
import BankPageContainer from './../molecules/bankPageContainer';

const BankPage = () => {
    return (
        <MainLayout>
                  <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
          alignItems: "center",
        }}
      >
        <PageHeader
          title={"Banking"}
          description={
            "Manage your bank accounts and pending funds."
          }
          backArrow={false}
         
        
        />
            <BankPageContainer/>
        </Box>
        </MainLayout>
    );
}    
export default BankPage;
