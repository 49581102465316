import React from 'react';

import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router
import NotFoundUI from '../../ui/page/main';

const NotFoundCombined = () => {
  const navigate = useNavigate();

  // Define the onGoHome handler that navigates to '/email'
  const handleGoHome = () => {
    navigate('/email'); // Navigates to the /email route
  };

  return <NotFoundUI onGoHome={handleGoHome} />;
};

export default NotFoundCombined;
