// Financial Menu
import { FormattedMessage } from 'react-intl';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

const icons = {
    SwapHorizIcon,
    AccountBalanceIcon
};

const financialMenu = {
    id: 'financial',
    title: <FormattedMessage id="financial" />,
    type: 'group',

    children: [
        {
            id: 'transactions',
            title: <FormattedMessage id="Transactions" />,
            type: 'item',
            url: '/transactions',
            icon: icons.SwapHorizIcon,
            breadcrumbs: false
        },
        {
            id: 'bank',
            title: <FormattedMessage id="Bank" />,
            type: 'item',
            url: '/bank',
            icon: icons.AccountBalanceIcon,
            breadcrumbs: false
        }
    ]
};

export default financialMenu;
