import React from "react";
import { Stepper, Step, StepLabel, StepConnector, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import stepConnectorClasses from "@mui/material/StepConnector/stepConnectorClasses";
import useMediaQuery from "@mui/material/useMediaQuery";

// Customizing the StepConnector directly with inline styles
const customConnectorStyles = {
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#eaeaf0",
  },
  [`&.${stepConnectorClasses.active} .${stepConnectorClasses.line}`]: {
    borderColor: "#1BC575",
  },
  [`&.${stepConnectorClasses.completed} .${stepConnectorClasses.line}`]: {
    borderColor: "#1BC575",
  },
};

const CustomConnector = styled(StepConnector)(customConnectorStyles);

const StepIndicator = ({ currentStep }) => {
  const stepLabels = [
    "Set Email & Password",
    "Enter Business Details",
  "Enter Personal Details",
    
  ];

  const totalSteps = stepLabels.length;

  // Function to customize the StepIcon color based on its state
  const stepIconColor = (index) => ({
    color: index < currentStep ? "#1BC575" : "#eaeaf0",
  });

  // Using Media Query to detect if the screen size is small (e.g., mobile)
  const isMobile = useMediaQuery("(max-width:900px)");

  // Calculating progress percentage
  const percentageComplete = Math.round(((currentStep - 1) / (totalSteps - 1)) * 100);

  return isMobile ? (
    <Typography variant="body1" align="center" sx={{ mt: 2 }}>
      {`Step ${currentStep} of ${totalSteps} - ${percentageComplete}% Complete`}
    </Typography>
  ) : (
    <Stepper
      alternativeLabel
      activeStep={currentStep - 1}
      connector={<CustomConnector />}
      sx={{
        mt: 4,
      }}
    >
      {stepLabels.map((label, index) => (
        <Step key={index} completed={index < currentStep - 1}>
          <StepLabel StepIconProps={{ style: stepIconColor(index) }}>
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepIndicator;
