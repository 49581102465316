// src/components/SetPageTitle.js

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Mapping of routes to their corresponding titles
const titleMapping = {
  '/bank': 'Bank - Clavaa',
  '/cashback': 'Cashback - Clavaa',
  '/dashboard': 'Dashboard - Clavaa',
  '/dashboard/kyc': 'Verify Your Identity - Clavaa', // Added KYC route with user-friendly title
  '/profile': 'Profile - Clavaa',
  '/pos/register': 'Register - Clavaa',
  '/settings': 'Settings - Clavaa',
  '/transactions': 'Transactions - Clavaa',
  '/personal-info': 'Personal Information - Clavaa',
  '/business-info': 'Business Information - Clavaa',
  '/status': 'Status - Clavaa',
  '/code-verification': 'Code Verification - Clavaa',
  '/account-info': 'Account Information - Clavaa',
  '/status/retry': 'Retry Information - Clavaa',
  '/status/document': 'Document Status - Clavaa',
  '/status/pending': 'Pending Status - Clavaa',
  '/status/suspended': 'Suspended Status - Clavaa',
  '/sign-in': 'Sign In - Clavaa',
  '/email': 'Email Verification - Clavaa',
  // Add more routes and their titles as needed
  '*': 'Page Not Found - Clavaa', // Fallback title for undefined routes
};

const SetPageTitle = () => {
  const location = useLocation();

  useEffect(() => {
    // Extract the pathname without query parameters
    const pathname = location.pathname.split('?')[0];

    // Determine the title based on the current pathname
    const title = titleMapping[pathname] || titleMapping['*'];

    // Set the document title
    document.title = title;

    // Optional: Cleanup function (not necessary here)
    return () => {};
  }, [location]);

  return null; // This component does not render anything
};

export default SetPageTitle;
