const useInfoList = () => {
  // Function to format the value of each field
  // If the field is 'address', it concatenates all the address fields into one string

  const formatValue = (details) => {
    if (typeof details === "object" && details.hasOwnProperty("address")) {
      const addressDetails = details.address;
      // Join all values in the address object into a single string
      return Object.values(addressDetails).join(", ");
    }
    // Return the value for other fields
    const [key, value] = Object.entries(details)[0];
    return value;
  };

  return {
    formatValue,
  };
};

export default useInfoList;
