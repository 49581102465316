import { Box, Typography } from "@mui/material";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import GeneralButton from "../../../../components/atoms/buttons/GeneralButton";
import GeneralInput from "../../../../components/atoms/inputs/GeneralInput";
import { useDispatch } from "react-redux";
import useFullFetchFeedback from "../../../../functions/apiFetchers/fullPageLoaders";
import { addEmployee } from "../../../../features/register/registerSlice";
import { useState } from "react";

const EmployeeManagementCardUI = () => {
  const fetchFeedback = useFullFetchFeedback();
  const dispatch = useDispatch();

  // State for form inputs
  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const addEmplyeeSubmit = async () => {
    const { firstName, lastName, email } = form;

    await fetchFeedback({
      endpoint: `merchant/add/employee/`,
      loaderType: "dialogueLoader",
      data: { first_name: firstName, last_name: lastName, email },
      requestType: "POST",
      loaderMessage: "Adding Employee...",
      successMessage: "Added Employee successfully!",
      onSuccess: (responseData) => {
        dispatch(addEmployee({ first_name: firstName, last_name: lastName, email }));
        // Reset form after successful submission
        setForm({ firstName: "", lastName: "", email: "" });
      },
    });
  };

  return (
    <Box
      sx={{
        flex: 1,
        maxWidth: "50%",
        "@media (max-width: 768px)": {
          maxWidth: "100%",
        },
      }}
    >
      <Box
        sx={{
          background: "white",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          padding: 4,
          borderRadius: 2,
          width: "90%",
          margin: "auto",
          "@media (max-width: 768px)": {
            width: "100%",
            marginTop: "20px",
            padding: 2,
          },
        }}
      >
        <Typography variant="h2" component="h1">
          Add New Employee
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "text.secondary",
            paddingY: "10px",
            letterSpacing: "0.5px",
            width: "80%",
            lineHeight: "1.5",
            margin: "auto",
          }}
        >
          Add new employees and manage updates to login credentials with
          automated email notifications.
        </Typography>

        {/* Input for First Name */}
        <GeneralInput
          type="text"
          label="First Name *"
          name="firstName"
          value={form.firstName}
          onChange={handleInputChange}
          commonStyles={{
            marginTop: "1rem",
            border: "2px solid text.primary",
            borderRadius: "50px",
          }}
        />

        {/* Input for Last Name */}
        <GeneralInput
          type="text"
          label="Last Name *"
          name="lastName"
          value={form.lastName}
          onChange={handleInputChange}
          commonStyles={{
            marginTop: "1rem",
            border: "2px solid text.primary",
            borderRadius: "50px",
          }}
        />

        {/* Input for Email */}
        <GeneralInput
          type="text"
          label="Employee's Email *"
          name="email"
          value={form.email}
          onChange={handleInputChange}
          commonStyles={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "1rem",
            },
            marginTop: "1rem",
          }}
        />

        {/* Submit Button */}
        <GeneralButton
          label={
            <>
              Add Employee
              <ArrowOutwardOutlinedIcon style={{ marginLeft: "8px" }} />
            </>
          }
          type="circularIcon"
          variant="outlined"
          sx={{
            marginTop: 3,
          }}
          onClick={addEmplyeeSubmit}
        />
      </Box>
    </Box>
  );
};

export default EmployeeManagementCardUI;