import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from "react-redux";
// material-ui
import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import {
    selectDashboardData,
  
  } from "../../../../../features/dashboard/dashboardSelectors";
// project imports
import MainCard from 'ui-component/cards/MainCard';
import TotalIncomeCard from 'ui-component/cards/Skeleton/TotalIncomeCard';

// assets
import RedeemIcon from '@mui/icons-material/Redeem';

const CardWrapper = styled(MainCard)(({ theme }) => ({
    backgroundColor: '#ffffff',
    color: '#333',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: '12px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
    '&:after': {
        content: '""',
        position: 'absolute',
        width: 210,
        height: 210,
       
        borderRadius: '50%',
        top: -20,
        right: -130
    }
}));

const TotalCashbackRedeemedCard = ({ isLoading }) => {
    const { cashbackRedeemed } =
    useSelector(selectDashboardData);
    const theme = useTheme();

    return (
        <>
            {isLoading ? (
                <TotalIncomeCard />
            ) : (
                <CardWrapper border={false} content={false}>
                    <Box sx={{ p: 2 }}>
                        <List sx={{ py: 0 }}>
                            <ListItem alignItems="center" disableGutters sx={{ py: 0 }}>
                                <ListItemAvatar>
                                    <Avatar
                                        variant="rounded"
                                        sx={{
                                            ...theme.typography.commonAvatar,
                                            ...theme.typography.largeAvatar,
                                            backgroundColor: '#F2F2F7', // Light grey background
                                            color: '#333'
                                        }}
                                    >
                                        <RedeemIcon fontSize="inherit" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    sx={{
                                        py: 0,
                                        mt: 0.45,
                                        mb: 0.45
                                    }}
                                    primary={<Typography variant="h4" sx={{ color: '#333' }}>
                                    ${cashbackRedeemed != null && !isNaN(cashbackRedeemed) ? Number(cashbackRedeemed).toFixed(2) : '0.00'}
                                  </Typography>
                                  }
                                    secondary={
                                        <Typography
                                            variant="subtitle2"
                                            sx={{
                                                color: '#666',
                                                mt: 0.5
                                            }}
                                        >
                                            Total Cashback Redeemed by Users (Last Thirty Days)
                                        </Typography>
                                    }
                                />
                            </ListItem>
                        </List>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

TotalCashbackRedeemedCard.propTypes = {
    isLoading: PropTypes.bool
};

export default TotalCashbackRedeemedCard;
