import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import DynamicForm from '../../../../../../components/atoms/inputs/dynamic';
import { setPersonalIsSet, setPersonalInfo } from '../../../../../../features/verification/verificationInfoSlice';
import { selectBusinessType, selectPersonalInfo } from '../../../../../../features/verification/verificationInfoSelectors';
import { generateValidator } from '../../../../../../functions/validators/generateValidator';

const PersonalGeneralInfoPageInputsUI = ({ onBack }) => {
  const dispatch = useDispatch();

  const personalInfo = useSelector(selectPersonalInfo);
  const businessType = useSelector(selectBusinessType);
  const [errors, setErrors] = useState("");
  console.log(errors)
  console.log(personalInfo)

  const fieldConfig = [
    {
      type: "text",
      label: "First Name",
      name: "first_name",
      grid: { xs: 12, sm: 6 },
    },
    {
      type: "text",
      label: "Last Name",
      name: "last_name",
      grid: { xs: 12, sm: 6 },
    },
    { type: "text", label: "Title", name: "title", grid: { xs: 12, sm: 6 } },
    {
      type: "date",
      label: "Date of Birth",
      name: "date_of_birth",
      grid: { xs: 12, sm: 6 },
    },
    // Conditionally add the address field if it's not a sole proprietorship
    ...(businessType !== "soleProprietorship"
      ? [{ type: "address", label: "Address", name: "address", grid: { xs: 12 } }]
      : []),
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(setPersonalInfo({ [name]: value }));
  };

  const handleSubmit =  () => {
    console.log("gergerh")
    const validatePersonalDetails = generateValidator([
      {
        field: "first_name",
        type: "content",
        required: true,
        customMessage: "First name is required.",
      },
      {
        field: "last_name",
        type: "content",
        required: true,
        customMessage: "Last name is required.",
      },
      {
        field: "title",
        type: "content",
        required: true,
        customMessage: "Title is required.",
      },
      {
        field: "date_of_birth",
        type: "dob",
        required: true,
        customMessage: "Valid date of birth is required. Use format DD/MM/YYYY.",
      },
      ...(businessType !== "soleProprietorship"
        ? [
            { field: "address", type: "address", required: true },
          ]
        : []),
    ]);
    const errors = validatePersonalDetails(personalInfo);
    console.log(errors)

    if (Object.keys(errors).length !== 0) {
      console.log(errors)
      setErrors(errors);
      return;
    }
console.log("I am here now")
    dispatch(setPersonalIsSet(true));
    console.log("I am here now 2")
  };
// Updated function name to handleBackClickInternal
const handleBackClickInternal = () => {
  dispatch(setPersonalInfo({
    first_name: "",
    last_name: "",
    title: "",
    date_of_birth: "",
    address: { address: "", city: "", state: "", zip_code: "" },
    ssn: "",
    isOwner: null,
    isSet: false,
  }));
  onBack(); // This calls the `handleBack` function passed as a prop from `PersonalMaster`
};


  const isFormComplete = personalInfo.first_name &&
    personalInfo.last_name && personalInfo.date_of_birth &&
    personalInfo.title &&
    (businessType === "soleProprietorship" || personalInfo.address);

  return (
    <Box sx={{ maxWidth: 500, width: "100%", mx: "auto", textAlign: "center" }}>
      {/* Header */}
      <Typography variant="h5" sx={{ mb: 1 }}>
        Key Personnel Information
      </Typography>
      {/* Subheader */}
      <Typography variant="subtitle1" sx={{ mb: 3, color: 'text.secondary' }}>
        Enter details for the controller, employee, or owner responsible for managing this account.
      </Typography>
      
      <DynamicForm
        fieldConfig={fieldConfig}
        formData={personalInfo}
        handleInputChange={handleInputChange}
        errors={errors}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 3,
        }}
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleBackClickInternal} // Updated to avoid recursion
          sx={{
            fontSize: '1rem',
            padding: '12px 24px',
            borderRadius: '8px',
            flex: 1,
            mr: 1,
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!isFormComplete}
          onClick={handleSubmit}
          sx={{
            fontSize: '1rem',
            padding: '12px 24px',
            borderRadius: '8px',
            flex: 1,
            ml: 1,
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default PersonalGeneralInfoPageInputsUI;
