import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addOwner, setPersonalSsn, removeOwner } from '../../../../../../../features/verification/verificationInfoSlice';
import { selectOwnerList } from '../../../../../../../features/verification/verificationInfoSelectors';
import OwnerManagerUIInputs from './manager';
import OwnerGeneralInfoPageInputsUI from './generalInfo';

const OwnerManager = () => {
  const dispatch = useDispatch();
  const owners = useSelector(selectOwnerList);

  // State to track current view and selected owner
  const [isEditing, setIsEditing] = useState(false);
  const [selectedOwner, setSelectedOwner] = useState(null);

  // Handler to switch to the editing form with selected owner data
  const handleOwnerClick = (owner) => {
    setSelectedOwner(owner);
    setIsEditing(true);
  };

  // Handler to reset the view to the owner list
  const handleBack = () => {
    setIsEditing(false);
    setSelectedOwner(null);
   
  };

  // Handler to save new or edited owner
  const handleSaveOwner = (ownerData) => {

   
    setIsEditing(false);
    setSelectedOwner(null);
  };

  return (
    <div>
      {isEditing ? (
        // Show the OwnerGeneralInfoPageInputsUI for editing or adding
        <OwnerGeneralInfoPageInputsUI
          ownerData={selectedOwner}
          handleBack={handleBack}
          handleSubmit={handleSaveOwner}
        />
      ) : (
        // Show the OwnerManagerUIInputs list view
        <OwnerManagerUIInputs
          onOwnerClick={handleOwnerClick}
          onAdd={() => setIsEditing(true)}
        />
      )}
    </div>
  );
};

export default OwnerManager;
