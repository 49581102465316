import dashboard from './main';
import financialMenu from './financial';
import accountMenu from './account';
import posManagementMenu from "./pos"
import cashbackMenu from "./cashback"
import locationMenu from "./locations"

const menuItems = {
    items: [dashboard,  financialMenu, cashbackMenu, posManagementMenu, ,locationMenu, accountMenu, ]
};

export default menuItems;
