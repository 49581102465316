import { useState } from "react";
import { useDispatch } from "react-redux";
import useFetchFeedback from "./../../../../functions/apiFetchers/fullPageLoaders";
import { updateRegisterData } from "./../../../../features/register/registerSlice";

import RegisterSettingsLogic from "./../molecules/registerSettingLogic";
import { handleInputChange } from "./../../../../functions/general/setData";
import EmployeeLogic from "./../molecules/employeeLogic";
import useInfoDailog from "../molecules/useInfoDailog";
import { useNavigate } from "react-router-dom";

const RegisterLogic = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchFeedback = useFetchFeedback();
  const [accountInfo, setAccountInfo] = useState({
    username: "",
    password: "",
  });
  const [activeInfoDailog, setActiveInfoDailog] = useState(false);
  const [error, setError] = useState("");
  const [formVisible, setFormVisible] = useState(false);

  const handleFormVisible = () => {
    setFormVisible(true);
  };
  const handleFormChange = (e) => {
    handleInputChange(e, setAccountInfo);
  };
  const {
    employeesList,
    sendEmailToAllEmplyesSubmit,
    sendEmailToOneEmplyeeSubmit,
    DeleteEmplyeeSubmit,
    addEmplyeeSubmit,
  } = EmployeeLogic();

  const {
    registerSettings,
    showUpdateDialog,
    handleSubmit,
    handleUpdateModal,
    handleOpenResetPasswordDailog,
    handleCloseResetPasswordDailog,
    handleSubmitResetPassword,
    showResetPasswordDailog,
  } = RegisterSettingsLogic();

  const {
    showInfoDailog,
    handleOpenInfoDailog,
    handleCloseInfoDailog,
    handleNextButtonClick,
    handleBackButtonClick,
    carouselItems,
    activeIndex,
  } = useInfoDailog();

  const handleSetupMasterRegisterSubmit = async (username, password) => {
    console.log("username", username);
    console.log("password", password);
    console.log("accountInfo", "fdfregh");



    await fetchFeedback({
      endpoint: `merchant/register-account/`,
      loaderType: "dialogueLoader",
      data: { username, password },
      requestType: "POST",
      
      loaderMessage: "Updating registration...",
      successMessage: "Registration successful!",
      // setError, // function to handle validation errors
      onSuccess: (responseData) => {
        dispatch(updateRegisterData(responseData));
        handleCloseResetPasswordDailog();
      },
    });
  };

  const handleSubmitForm = (username, password) => {
    handleSubmit();
    setFormVisible(false);
    handleSetupMasterRegisterSubmit(username, password);
  };

  const handleInfoDailog = () => {
    setActiveInfoDailog(!activeInfoDailog);
  };

  return {
    activeInfoDailog,
    formVisible,
    navigate,

    registerSettings,
    showUpdateDialog,
    accountInfo,
    error,
    handleInfoDailog,
    handleUpdateModal,
    handleSubmitForm,
    handleFormVisible,
    handleSetupMasterRegisterSubmit,
    handleFormChange,
    employeesList,
    sendEmailToAllEmplyesSubmit,
    sendEmailToOneEmplyeeSubmit,
    DeleteEmplyeeSubmit,
    addEmplyeeSubmit,

    handleOpenResetPasswordDailog,
    handleCloseResetPasswordDailog,
    handleSubmitResetPassword,
    showResetPasswordDailog,

    showInfoDailog,
    handleOpenInfoDailog,
    handleCloseInfoDailog,
    handleNextButtonClick,
    handleBackButtonClick,
    carouselItems,
    activeIndex,
  };
};

export default RegisterLogic;
