export const employeeConstant = [
  {
    id: 1,
    name: "Darlene Roberston",
  },
  {
    id: 2,
    name: "Eleanor Pena",
  },
  {
    id: 3,
    name: "Eleanor Pena",
  },
  {
    id: 4,
    name: "Darlene Roberston",
  },
  {
    id: 5,
    name: "Courtney Henry",
  },
  {
    id: 6,
    name: "Jane Cooper",
  },
  {
    id: 7,
    name: "Jerome Bell",
  },
];
