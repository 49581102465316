import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom"; // Assuming you're using react-router for navigation
import {
  activateLoader,
  deactivateLoader,
} from "../../features/loading/loadingSlice";
import { showAlert } from "../../features/alerts/alertSlice";
import { masterRequest } from "../apiStructures/masterRequest";
import { generateValidator } from "../validators/generateValidator";
import { showDialog } from "../../features/dialog/dialogSlice";
import { getAuth, onAuthStateChanged } from "firebase/auth";
/**
 * Custom hook to handle API fetch with loading and error handling.
 *
 * @returns {Function} A function to call the API with feedback handling.
 *
 * Usage:
 * const fetchFeedback = useFullFetchFeedback();
 * fetchFeedback({
 *   requestType: 'get', // 'get', 'post', 'postform', 'delete'
 *   endpoint: '/api/endpoint',
 *   data: { key: value },
 *   loaderType: 'pageLoader',
 *   loaderMessage: 'Loading...',
 *   onSuccess: (response) => { console.log(response); },
 *   onError: (error) => { console.error(error); },
 *   validate: (data) => { return {}; }, // validation function
 *   setError: (errors) => { console.error(errors); }, // function to handle validation errors
 *   auth: true, // if true, include authorization header
 * });
 */
const useFullFetchFeedback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return async ({
    requestType,
    endpoint,
    data,
    loaderType = "pageLoader",
    loaderMessage = "",
    setLoader= () => {},
    successMessage = null,
    onSuccess = () => {},
    onError = () => {},
    validationRules = [],
    setError = () => {},
    auth = true,
  }) => {
 
    // Validate the data if validation rules are provided
    if (validationRules.length !== 0) {
      const validate = generateValidator(validationRules);
      const errors = validate(data);

      if (Object.keys(errors).length !== 0) {
        console.log(errors);
        setError(errors);
        onError(errors);
       
        return;
      }
    }
    if (loaderMessage){
      dispatch(activateLoader({ loaderType, loaderMessage }));
    }
    setLoader(true);


    let authKey = null;
    const firebaseAuth = getAuth(); // Initialize Firebase Auth

    console.log(auth, firebaseAuth); // logs the value of 'auth'

    if (auth) {
      const user = await new Promise((resolve) => {
        onAuthStateChanged(firebaseAuth, (user) => {
          resolve(user);
        });
      });

      if (user) {
        try {
          // Retrieve the token from Firebase Auth, using await to wait for the token
          authKey = await user.getIdToken(true);
          console.log("authKey retrieved from Firebase:", authKey); // log authKey after retrieval
        } catch (error) {
          console.error("Error retrieving token from Firebase:", error);
          // Handle token retrieval failure if needed
          dispatch(showAlert({ type: "error", message: "Error retrieving authentication token." }));
          return;
        }
      } else {
        // If no user is signed in, log a message and redirect to sign-in
        console.log("No user authenticated, redirecting to sign-in.");
        dispatch(showAlert({ type: "warning", message: "You must log in to continue." }));
        navigate("/sign-in"); // Redirect the user to the login page
        return;
      }
    }

    // If authKey exists, continue the logic
    console.log("Final authKey:", authKey);
   

    try {
      // Make the request with the retrieved authKey
      const response = await masterRequest(endpoint, requestType, data, authKey);
      console.log(response);
      dispatch(deactivateLoader(loaderType));
      setLoader(false);


      if (response.error) {
        // Check if response.error contains an object with an error key
        if (response.error.error) {
          const errorMessage = response.error.error;
          onError(response.error);
          dispatch(showAlert({ type: "error", message: errorMessage }));
        } else {
          setError(response.error);
          onError(response.error);
        }
      } else {
        if (successMessage) {
          dispatch(showDialog({ dialogType: "success", message: successMessage }));
        }
        onSuccess(response);
      }
    } catch (err) {
      setLoader(false);
      dispatch(deactivateLoader(loaderType));
      onError(err);
    }
  };
};

export default useFullFetchFeedback;
