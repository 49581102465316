import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
} from "@mui/material";
import GeneralButton from "../../atoms/buttons/GeneralButton";

const CustomInputDialog = ({
  open,
  onClose,
  onSubmit,
  title,
  subTitle,
  children,
}) => {
  const [inputValue, setInputValue] = useState("");

  const handleClose = () => {
    onClose();
    console.log(children)
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        sx={{
          margin: "auto",
        }}
      >
        {title}
      </DialogTitle>
      {subTitle && (
        <DialogContentText
          sx={{
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          {subTitle}
        </DialogContentText>
      )}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <GeneralButton label="Cancel" onClick={handleClose} type="PlainText" />
        <GeneralButton label="Enter" onClick={onSubmit} type="PlainText" />
      </DialogActions>
    </Dialog>
  );
};

export default CustomInputDialog;
