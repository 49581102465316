
const DevelopmentComponentUI = ({
 
}) => {
  return (
    <>
     
    </>
  );
};

export default DevelopmentComponentUI;
