import { Box, Grid, Typography } from "@mui/material";

const AuthCarousel = ({ transitions, animated, slides, activeIndex }) => {
  return (
    <Box
      sx={{
        position: "relative",
        height: "400px",
        overflow: "hidden",
        color: "white",
        display: "flex",
        width: {
          lg: "46%",
          md: "100%",
        },
      }}
    >
      <Grid container spacing={2} sx={{ p: 3 }}>
        {transitions((style, index) => (
          <animated.div style={style}>
            <Typography
              variant="h4"
              sx={{
                fontSize: "1.8rem",
                fontWeight: "700",
                color: "white",
                lineHeight: "2.8rem",
                mt: "5rem",
                fontFamily: "Epilogue, sans-serif",
              }}
              fontWeight="bold"
              gutterBottom
            >
              {slides[index].title}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: "1rem",
                fontWeight: "400",
                color: "white",
                lineHeight: "1.5rem",
                marginTop: "2rem",
                fontFamily: "Epilogue, sans-serif",
              }}
            >
              {slides[index].description}
            </Typography>
          </animated.div>
        ))}
      </Grid>
      <Box
        sx={{
          position: "absolute",
          bottom: "20px",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        {slides.map((_, index) => (
          <Box
            key={index}
            sx={{
              height: activeIndex === index ? "4px" : "2px",
              width: activeIndex === index ? "48px" : "32px",
              backgroundColor: activeIndex === index ? "white" : "gray",
              margin: "0 4px",
              transition: "background-color 0.3s ease",
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default AuthCarousel;
