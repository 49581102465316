import { Password } from '@mui/icons-material';
import { createSlice } from '@reduxjs/toolkit';
import { add } from 'lodash';
import { set } from 'lodash';

const initialState = {
  registerData: {
    username: "",
    register_pin: "",
    visible_password: "",
    register_count: "",
  },
  // employees is in the form of an array of objects with keys first_name, last_name, and email
  employees: [],  
  currentRegister: 0,
  isLoading: false,
  error: null,
};

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    updateRegisterData: (state, action) => {

      console.log("state", "dfjkldsjfsdjgklsfjg")
      console.log("action.payload", action.payload)
      state.registerData = {
        ...state.registerData,
        ...action.payload, // Update only the keys within registerData
      };
    },
    setEmployees: (state, action) => {
      state.employees = action.payload;
    },
    addEmployee: (state, action) => {
      state.employees.push(action.payload);
    },
    deleteEmployee: (state, action) => {
      state.employees = state.employees.filter(
        (employee) => employee.email !== action.payload
      );
    },
   
    setCurrentRegister: (state, action) => {
      state.currentRegister = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  updateRegisterData,
  setCurrentRegister,
  setLoading,
  setError,
  setEmployees,
  addEmployee,
  deleteEmployee,
} = registerSlice.actions;

export default registerSlice.reducer;
