// src/components/SocialInfoPageInputsUI.js

import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  Checkbox,
  FormControlLabel,
  Alert,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPersonalSsn,
  setPersonalOwnerStatus,
  setVerificationstatus,
  setPersonalLoading,
  setPersonalInfoComplete,
} from '../../../../../../features/verification/verificationInfoSlice';
import {
  selectPersonalInfo,
  selectBusinessType,
  selectOwnerStatus,
  selectBusinessName,

} from '../../../../../../features/verification/verificationInfoSelectors';
import useFullFetchFeedback from '../../../../../../functions/apiFetchers/fullPageLoaders';
const SocialInfoPageInputsUI = ({ full_social }) => {
  const dispatch = useDispatch();

  // Retrieve personal information from Redux store
  const ownerStatus = useSelector(selectOwnerStatus);
  const businessType = useSelector(selectBusinessType);
  const personalInfo = useSelector(selectPersonalInfo);
  const businessName = useSelector(selectBusinessName);
  const fetchFeedback = useFullFetchFeedback();
  const { first_name, last_name } = personalInfo;

  // Local state for inputs, errors, and ownership confirmation
  const [ssn, setSsn] = useState('');
  const [lastFour, setLastFour] = useState('');
  const [errors, setErrors] = useState({});
  const [isOwnershipConfirmed, setIsOwnershipConfirmed] = useState(false);
  const validateFullSSN = () => {
    const newErrors = {};
    const ssnRegex = /^\d{3}-\d{2}-\d{4}$/;

    if (!ssnRegex.test(ssn)) {
      newErrors.fullSSN = 'Please enter a valid SSN (e.g., 123-45-6789).';
    }

    return newErrors;
  };

  const validateLastFour = () => {
    const newErrors = {};
    const lastFourRegex = /^\d{4}$/;

    if (!lastFourRegex.test(lastFour)) {
      newErrors.lastFour = 'Please enter the last four digits of your SSN.';
    }

    return newErrors;
  };
  // Updated isFormComplete to include ownership confirmation when needed
  const isFormComplete = (full_social
    ? ssn && ssn.indexOf('_') === -1 && ssn.length === 11
    : lastFour.length === 4) &&
    (businessType !== "soleProprietorship" && ownerStatus !== "full_owner" || isOwnershipConfirmed);

  // Handle SSN input with automatic formatting
  const handleSsnChange = (e) => {
    let value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters

    if (value.length > 9) {
      value = value.slice(0, 9);
    }

    // Automatically insert dashes
    if (value.length > 5) {
      value = `${value.slice(0, 3)}-${value.slice(3, 5)}-${value.slice(5)}`;
    } else if (value.length > 3) {
      value = `${value.slice(0, 3)}-${value.slice(3)}`;
    }

    setSsn(value);
  };

  // Handle form submission
  const handleSubmit = async () => {
    let validationErrors = {};

    if (full_social) {
      validationErrors = validateFullSSN();
    } else {
      validationErrors = validateLastFour();
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Dispatch the SSN to Redux store
    if (full_social) {
      dispatch(setPersonalSsn(ssn));
    } else {
      dispatch(setPersonalSsn(lastFour));
    }

    // Clear errors after successful submission
    setErrors({});
    if (businessType === "soleProprietorship" || ownerStatus === "full_owner") {
      const updatedPersonalInfo = {
        ...personalInfo,
        ssn: ssn || lastFour,
      };
      const isSoleProp = businessType === "soleProprietorship";
      const data = {
        personal_info: updatedPersonalInfo,
        isSoleProp,
        ...(isSoleProp ? {} : { owners: [{ ...updatedPersonalInfo, title: undefined }] }),
      };
      await fetchFeedback({
        endpoint: "merchant/personal_info/",
        data,
        requestType: "POST",
        setLoader: (isLoading) => dispatch(setPersonalLoading(isLoading)),
        onSuccess: (responseData) => {
          dispatch(setPersonalInfoComplete());
          dispatch(setVerificationstatus("loading"));
        },
        onError: (error) => {
          dispatch(setPersonalSsn(""));
          console.error(error);
        },
      });
    }
  };

  // Handle Back button click
  const handleBack = () => {
    dispatch(setPersonalOwnerStatus(null));
  };

  return (
    <Box
      sx={{
        maxWidth: 500,
        width: '100%',
        mx: 'auto',
        textAlign: 'center',
      }}
    >
      {/* Header */}
      <Typography variant="h4" sx={{ mb: 2 }}>
        SSN Information for {first_name} {last_name}, Account Administrator
      </Typography>
      {/* Subheader */}
      <Typography variant="subtitle1" sx={{ mb: 4, color: 'text.secondary' }}>
        Your Social Security Number is safe with us. We do not store it in our database.
        It is submitted directly to Dwolla for regulatory verification purposes.
      </Typography>

      {/* Display validation errors */}
      {Object.keys(errors).length > 0 && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {Object.values(errors).map((error, index) => (
            <div key={index}>{error}</div>
          ))}
        </Alert>
      )}

      {/* SSN Input Field */}
      <Box component="form" noValidate autoComplete="off">
        {full_social ? (
          <TextField
            label="Social Security Number"
            variant="outlined"
            fullWidth
            required
            value={ssn}
            onChange={handleSsnChange}
            placeholder="123-45-6789"
            inputProps={{ maxLength: 11 }}
            error={!!errors.fullSSN}
            helperText={errors.fullSSN}
            sx={{
              mb: 3,
            }}
          />
        ) : (
          <TextField
            label="Last Four Digits of SSN"
            variant="outlined"
            value={lastFour}
            onChange={(e) => setLastFour(e.target.value.replace(/\D/g, '').slice(0, 4))}
            inputProps={{ maxLength: 4 }}
            fullWidth
            required
            error={!!errors.lastFour}
            helperText={errors.lastFour}
            sx={{
              mb: 3,
            }}
          />
        )}
      </Box>

      {/* Conditional Ownership Confirmation Checkbox */}
      {(businessType === "soleProprietorship" || ownerStatus === "full_owner") && (
        <FormControlLabel
          control={
            <Checkbox
              checked={isOwnershipConfirmed}
              onChange={(e) => setIsOwnershipConfirmed(e.target.checked)}
              color="primary"
            />
          }
          label={`I hereby certify that the information provided for ${businessName} Controller and Beneficial Owners is complete and correct`}
          sx={{ mb: 2, color: "text.secondary" }}
        />
      )}

      {/* Action Buttons */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 4,
        }}
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleBack}
          sx={{
            fontSize: '1rem',
            padding: '12px 24px',
            borderRadius: '8px',
            flex: 1,
            mr: 1,
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!isFormComplete}
          onClick={handleSubmit}
          sx={{
            fontSize: '1rem',
            padding: '12px 24px',
            borderRadius: '8px',
            flex: 1,
            ml: 1,
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default SocialInfoPageInputsUI;
