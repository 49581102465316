// ContainedButton.js
import React from "react";
import Button from "@mui/material/Button";

const ContainedButton = ({ label, onClick, disabled, importedSx, fullWidth }) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      fullWidth={fullWidth}
 
      disabled={disabled}
      sx={{
        ...importedSx,
        mt: 1,
        mb: 1,
        display: "block",
        fontFamily: "SF Pro Display, Arial, sans-serif",
        fontSize: "0.875rem",
        fontWeight: 500,
        textTransform: "none",
        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        backgroundColor: "#1EC674",
        color: "#ffffff",
        "&:hover": {
          backgroundColor: "#3da843",
          boxShadow: "0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12), 0px 2px 4px -1px rgba(0,0,0,0.2)",
        },
        "&:disabled": {
          backgroundColor: "#aeaeae",
          color: "#ffffff",
        },
        borderRadius: "4px",
      }}
    >
      {label}
    </Button>
  );
};

export default ContainedButton;
