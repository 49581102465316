import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PersonalStart from './start';
import PersonalInfoPageInputsUI from './generalInfo';
import OwnerStatusPageInputsUI from './isControllerOwner';
import LoadingComponent from './loading';
import PreStart from './preStart';
import { Box, useTheme } from '@mui/material';
import { selectUserStatus } from '../../../../../../features/user/userSelectors';
import { selectOwnerStatus, selectPersonalInfoIsSet, selectPersonalSsn, selectPersonalLoading, selectPersonalInfoComplete, selectVerificationstatus } from '../../../../../../features/verification/verificationInfoSelectors';
import SocialInfoPageInputsUI from './ssn';
import OwnerManager from './owner/container';
import VerificationCompleteComponent from './complete';
import VerificationModal from '../../dwollaCheckModal/main';


const PersonalMaster = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const userStatus = useSelector(selectUserStatus);
  const personalLoading = useSelector(selectPersonalLoading);
  const personalInfoIsSet = useSelector(selectPersonalInfoIsSet);
  const personalInfoComplete = useSelector(selectPersonalInfoComplete);
  const ownerStatus = useSelector(selectOwnerStatus);
  const personalSsn = useSelector(selectPersonalSsn);
  const verificationStatus = useSelector(selectVerificationstatus); // Select the verification status
  const [started, setStarted] = useState(false);
console.log(personalSsn)
  const handleGetStarted = () => {
    setStarted(true);
  };

  const handleBack = () => {
    setStarted(false);
  };

  if (userStatus === 'businessInfo' || userStatus === 'notAuthenticated') {
    return <PreStart />;
  }
  console.log("is set " + personalInfoIsSet)

  return (
    <Box
      sx={{
        width: 600,
        border: `1px solid #E0E0E0`,
        borderRadius: '16px',
        padding: theme.spacing(4),
        textAlign: 'center',
        backgroundColor: '#FFFFFF',
        transition: 'box-shadow 0.3s ease, transform 0.3s ease',
        '&:hover': {
          boxShadow: '0 8px 30px rgba(0, 0, 0, 0.1)',
          transform: 'translateY(-4px)',
        },
      }}
    >
      {personalLoading ? (
        <LoadingComponent />
      ) : personalInfoComplete ? (
        <VerificationCompleteComponent />
      ) : personalSsn && ownerStatus !== 'full_owner' ? (
        <OwnerManager />
      ) : ownerStatus != null ? (
        <SocialInfoPageInputsUI full_social={ownerStatus === 'full_owner' || ownerStatus === true} />
      ) : personalInfoIsSet ? (
        <OwnerStatusPageInputsUI />
      ) : !started ? (
        <PersonalStart onGetStarted={handleGetStarted} />
      ) : (
        <PersonalInfoPageInputsUI onBack={handleBack} />
      )}

      {/* Conditionally render VerificationStatusComponent if verificationStatus is not null */}
      {verificationStatus && (
        <VerificationModal />
      )}
    </Box>
  );
};

export default PersonalMaster;
