import { styled, useTheme } from '@mui/material/styles';
import { Button, Card, CardContent, Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import AnimateButton from 'ui-component/extended/AnimateButton';

const CardStyle = styled(Card)(({ theme }) => ({
    background: "#FAFAFA",
    marginTop: '22px',
    marginBottom: '16px',
    padding: '24px',
    borderRadius: '12px',
  
    maxWidth: '300px', // Fixed width for smaller size
    marginLeft: 'auto',
    marginRight: 'auto', // Center the card
}));

const AccountStatusCard = ({ accountStatus, onPress }) => {
    const theme = useTheme();
    const isPending = accountStatus === 'Pending';

    return (
        <CardStyle>
            <CardContent>
                <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    textAlign="center"
                >
                    {/* Status Icon */}
                    <Box mb={2}>
                        {isPending ? (
                            <HourglassEmptyIcon sx={{ fontSize: 40, color: theme.palette.warning.main }} />
                        ) : (
                            <CheckCircleIcon sx={{ fontSize: 40, color: theme.palette.success.main }} />
                        )}
                    </Box>

                    {/* Status Title */}
                    <Typography variant="h6" component="div" sx={{ fontWeight: 600, mb: 0.5 }}>
                        {isPending ? 'Account Pending' : 'Account Active'}
                    </Typography>

                    {/* Status Description */}
                    <Typography variant="body2" color="text.secondary" sx={{ mb: isPending ? 2 : 0 }}>
                        {isPending
                            ? "Complete all steps to activate your account."
                            : "Your account is active and ready to go!"}
                    </Typography>

                    {/* Call-to-Action Button */}
                    {isPending && (
                        <AnimateButton>
                            <Button
                                onClick={onPress}
                                variant="outlined"
                                color="primary"
                                sx={{
                                    paddingX: 2,
                                    paddingY: 0.5,
                                    borderRadius: '6px',
                                    textTransform: 'none',
                                    fontWeight: 500,
                                    boxShadow: 'none',
                                    '&:hover': {
                                        backgroundColor: theme.palette.action.hover,
                                        boxShadow: 'none', // Remove hover shadow
                                    },
                                }}
                            >
                                Finish Setup
                            </Button>
                        </AnimateButton>
                    )}
                </Box>
            </CardContent>
        </CardStyle>
    );
};

export default AccountStatusCard;
