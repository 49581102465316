import React from 'react';
import { Grid, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import BusinessMaster from './business/main';
import PersonalMaster from './personal/main';

const KYCPageStart = () => {
  const theme = useTheme();
  const isSmallishScreen = useMediaQuery('(max-width: 1500px)'); // Example for a "smallish" range

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: isSmallishScreen ? 'column' : 'row', // Stack vertically on small screens
        justifyContent: 'center',

        gap: 4, // Add spacing between items
        padding: 2,
      }}
    >
      <Box sx={{ width: isSmallishScreen ? '100%' : '50%' }}>
        <BusinessMaster />
      </Box>
      <Box sx={{ width: isSmallishScreen ? '100%' : '50%' }}>
        <PersonalMaster />
      </Box>
    </Box>
  );
};

export default KYCPageStart;