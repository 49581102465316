import React from 'react';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'isLast',
})(({ isLast }) => ({
  width: '56px',
  height: '56px',
  marginRight: isLast ? '0' : '12px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '12px',
    backgroundColor: '#F2F2F7',
    padding: '0', // Remove default padding
    '& fieldset': {
      borderColor: '#E5E5EA',
    },
    '&:hover fieldset': {
      borderColor: '#D1D1D6',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#007AFF',
      boxShadow: '0 0 0 2px rgba(0, 122, 255, 0.2)', // Add focus ring
    },
  },
  '& input': {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: '500',
    fontFamily: 'SF Pro Text, Helvetica Neue, Arial, sans-serif',
    padding: '16px', // Add padding to increase the clickable area
    caretColor: 'transparent', // Hide the blinking cursor
  },
}));

function CodeInputField(props) {
  const { index, totalLength, inputRef, value, onChange, onKeyDown } = props;

  return (
    <StyledTextField
      variant="outlined"
      isLast={index === totalLength - 1}
      inputProps={{
        maxLength: 1,
        'aria-label': `Digit ${index + 1}`,
      }}
      inputRef={inputRef}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
}

export default CodeInputField;
