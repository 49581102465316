import React, { useState, useEffect } from 'react';
import {
  Box,
  CircularProgress,
  Typography,
  useTheme,
  Modal,
  Backdrop,
  LinearProgress,
  Skeleton,
  Grid,
} from '@mui/material';
import MainLayout from '../../development/page/parts/layout/MainLayout';

const InitialLoadingPage = () => {
  const theme = useTheme();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Simulate loading progress
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          clearInterval(timer);
          return oldProgress;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500); // Increment every 500ms

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <MainLayout>
      <Box
        sx={{
          padding: theme.spacing(4),
          animation: 'fadeIn 1s ease-in-out',
        }}
      >
        {/* Dashboard Skeletons */}
        <Grid container spacing={4}>
          {/* Header Skeleton */}
          <Grid item xs={12}>
            <Skeleton variant="text" width="60%" height={40} />
          </Grid>

          {/* Statistic Cards Skeleton */}
          <Grid item xs={12} sm={6} md={3}>
            <Skeleton variant="rectangular" width="100%" height={150} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Skeleton variant="rectangular" width="100%" height={150} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Skeleton variant="rectangular" width="100%" height={150} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Skeleton variant="rectangular" width="100%" height={150} />
          </Grid>

          {/* Charts Skeleton */}
          <Grid item xs={12} md={6}>
            <Skeleton variant="rectangular" width="100%" height={300} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Skeleton variant="rectangular" width="100%" height={300} />
          </Grid>

          {/* Recent Activity Skeleton */}
          <Grid item xs={12}>
            <Skeleton variant="text" width="40%" height={30} />
            <Skeleton variant="rectangular" width="100%" height={200} sx={{ mt: 1 }} />
          </Grid>
        </Grid>
      </Box>

      {/* Loading Modal */}
      <Modal
        open={progress < 100}
        aria-labelledby="loading-modal"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
          style: { backgroundColor: 'rgba(255, 255, 255, 0.8)', cursor: 'not-allowed' },
        }}
        sx={{ pointerEvents: 'none' }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: 'none',
            boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)', // Transparent shadow
            outline: 'none', // Remove outline
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <CircularProgress
            size={60}
            thickness={4}
            sx={{
              color: '#1BC575',
              mb: 2,
              animation: 'spin 2s linear infinite',
            }}
          />
          <Typography
            variant="h5"
            sx={{
              color: '#1BC575',
              fontWeight: 'bold',
              mb: 1,
            }}
          >
            Setting Up Your Account
          </Typography>
          <Box sx={{ width: '100%', mt: 2 }}>
            <LinearProgress variant="determinate" value={progress} />
            <Typography
              variant="body2"
              sx={{
                mt: 1,
                color: theme.palette.text.secondary,
                textAlign: 'center',
              }}
            >
              {`Loading... ${Math.round(progress)}%`}
            </Typography>
          </Box>
        </Box>
      </Modal>

      {/* Footer */}
      <Box
        sx={{
          position: 'absolute',
          bottom: theme.spacing(4),
          width: '100%',
          textAlign: 'center',
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: theme.palette.text.disabled,
          }}
        >
          © {new Date().getFullYear()} Clavaa. All rights reserved.
        </Typography>
      </Box>

      {/* Keyframe Animations */}
      <style>
        {`
          @keyframes fadeIn {
            0% { opacity: 0; }
            100% { opacity: 1; }
          }
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </MainLayout>
  );
};

export default InitialLoadingPage;
