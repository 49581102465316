import adminSetupImage from "../../../../images/administrator-setup-image.png";
import employeeLoginImage from "../../../../images/employee-login.png";
import acceptPaymentImage from "../../../../images/accept-payment-image.png";

export const employeeConstant = [
  {
    id: 1,
    name: "Darlene Roberston",
  },
  {
    id: 2,
    name: "John Doe",
  },
  {
    id: 3,
    name: "John Doe",
  },
  {
    id: 4,
    name: "John Doe",
  },
  {
    id: 5,
    name: "John Doe",
  },
];

export const carouselItems = [
  {
    title: "Administrator Setup",
    description:
      "Setup your admin username and password, and add employees to receive updates on login credentials and refund PINs.",
    image: adminSetupImage,
  },
  {
    title: "Employee Login",
    description:
      "Employees log in to the POS add-on app, pair the provided QR code scanner with their device, and start using the system. Need more devices? Email help@clavaa.com.",
    image: employeeLoginImage,
  },
  {
    title: "Accept Payments",
    description:
      "Employees process payments using the POS add-on app and mark them as paid in your current POS system—quick and seamless.",
    image: acceptPaymentImage,
  },
];
