import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { showAlert } from "../../../../../../../features/alerts/alertSlice";


const useDocumentUploadField = ({documentDetails, setDocumentList, setSelectedDocument}) => {
  const [type, setType] = useState("");
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      documentDetails &&
      documentDetails.document &&
      documentDetails.document.errors
    ) {
      const errorMessage = `Uploaded document was invalid. ${documentDetails.document.errors.join(", ")}`;
      dispatch(showAlert({ type: "error", message: errorMessage }));
    }
  }, [documentDetails, dispatch]);

  const handleDocumentUpload = () => {
    setDocumentList((prev) =>
      prev.map((doc, index) =>
        index === documentDetails.index
          ? { ...doc, type, file, isCompleted: true }
          : doc
      )
    );
    setSelectedDocument(null); // Reset selected document if needed
  };

  return {
    documentDetails,
    setSelectedDocument,
    handleDocumentUpload,
    type,
    setType,
    setFile,
   
  };

}

export default useDocumentUploadField