import React from 'react';
import DialogManager from './dialogues';
import ConditionalLoader from './loaders';
import AlertManager from './alerts';



const ReduxComponents = () => {
 

  return (
    <>
    <AlertManager/>
    <DialogManager/>
    <ConditionalLoader/>
    </>
  );
};

export default ReduxComponents;
