import React from "react";
import { Box, Button, Typography } from "@mui/material";
import DocumentStackUI from "../molecules/documentStackUI";

const DocumentManagerUI = ({
  onDocumentUpload,
  isAllDocumentsUploaded,
  documentList,
  setSelectedDocument,
  onBack
}) => {
  return (
    <Box sx={{ maxWidth: 500, width: "100%", mx: "auto", textAlign: "center" }}>
      <Typography variant="h5" sx={{ fontWeight: '600', mb: 1 }}>
        Document Manager
      </Typography>
      <Typography variant="body1" sx={{ color: 'text.secondary', mb: 3 }}>
        Please review and ensure all documents are uploaded. Submit once all documents are ready.
      </Typography>

      <DocumentStackUI
        data={documentList}
        onItemClick={(document, index) =>
          setSelectedDocument({ document, index })
        }
      />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onBack}
          sx={{
            fontSize: '1rem',
            padding: '12px 24px',
            borderRadius: '8px',
            flex: 1,
            mr: 1,
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onDocumentUpload}
          disabled={!isAllDocumentsUploaded}
          sx={{
            fontSize: '1rem',
            padding: '12px 24px',
            borderRadius: '8px',
            flex: 1,
            ml: 1,
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default DocumentManagerUI;
