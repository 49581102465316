// src/components/IntroCard.jsx

import React from "react";
import {
  Typography,
  Button,
  Box,
  Card,
  CardContent,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { styled } from "@mui/system";

// Styled Components for Enhanced Styling
const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 800,
  margin: "auto",
  borderRadius: theme.shape.borderRadius * 2,
  boxShadow: theme.shadows[10],
  background: `linear-gradient(135deg, #f2f2f2 30%, #e6e6e6 90%)`,

  color: theme.palette.common.white,
  padding: theme.spacing(6),
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(4),
  },
}));

const IconWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: theme.spacing(4),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(4),
  padding: theme.spacing(1.5, 4),
  borderRadius: theme.shape.borderRadius,
  fontSize: "1.2rem",
  textTransform: "none",
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.common.white,
  transition: "background-color 0.3s, transform 0.3s",
  "&:hover": {
    backgroundColor: theme.palette.secondary.dark,
    transform: "scale(1.05)",
  },
}));

const IntroCard = ({ onGetStarted }) => {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledCard>
      <CardContent>
        <IconWrapper>
          <MonetizationOnIcon
            sx={{ fontSize: isSmDown ? 80 : 100, color: theme.palette.primary.main }}
            aria-hidden="true"
          />
        </IconWrapper>
        <Typography
          variant={isSmDown ? "h3" : "h1"}
          component="h1"
          fontWeight="bold"
          align="center"
          gutterBottom
        >
          Reward Your Customers with Cashback
        </Typography>
        <Typography
          variant={isSmDown ? "h5" : "h3"}
          align="center"
          sx={{ opacity: 0.9, marginBottom: theme.spacing(4) }}
          aria-label="Set a cashback amount that customers can redeem only in your store."
        >
          Encourage repeat business by offering tailored cashback rewards that bring customers back to your store.
        </Typography>
        <Box display="flex" justifyContent="center">
          <StyledButton
            variant="contained"
            onClick={onGetStarted}
            aria-label="Get Started"
          >
            Get Started
          </StyledButton>
        </Box>
      </CardContent>
    </StyledCard>
  );
};

export default IntroCard;
