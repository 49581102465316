// src/features/dashboard/dashboardSelectors.js

export const selectDashboardData = (state) => ({
  transactions: state.dashboard.transactions,
  cashbackEarned: state.dashboard.cashbackEarned,
  cashbackRedeemed: state.dashboard.cashbackRedeemed,
  totalUsers: state.dashboard.totalUsers,
  monthlyTransactions: state.dashboard.monthlyTransactions,
  monthlyCashback: state.dashboard.monthlyCashback,
  isLoading: state.dashboard.isLoading,
  error: state.dashboard.error
});

export const selectDuration = (state) => state.dashboard.duration;
