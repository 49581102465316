// src/components/BusinessMaster.js

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';
import InfoResubmittedSuccess from './complete';
import LoadingResubmittedInfo from './loading';
import CustomerRetryInfoPage from './customerRetryInfoPage';
import ResubmitIdentity from './start';
import { selectRetryLoading, selectRetryComplete, selectVerificationstatus } from '../../../../../../../features/verification/verificationInfoSelectors';
import VerificationModal from '../../../dwollaCheckModal/main';

const RetryMaster = () => {
    const theme = useTheme();
   
    const verificationStatus = useSelector(selectVerificationstatus);
    const retryLoading = useSelector(selectRetryLoading);
    const retrycomplete = useSelector(selectRetryComplete);
    
    const [started, setStarted] = useState(false);
  
    const handleGetStarted = () => {
      setStarted(true);
    };
  
    const handleBack = () => {
      setStarted(false);
    };
  
    return (
      <Box
        sx={{
          width: 600,
          border: `1px solid #E0E0E0`,
          borderRadius: '16px',
          padding: theme.spacing(4),
          textAlign: 'center',
          cursor: 'pointer',
          transition: 'box-shadow 0.3s ease, transform 0.3s ease',
          backgroundColor: '#FFFFFF',
          '&:hover': {
            boxShadow: '0 8px 30px rgba(0, 0, 0, 0.1)',
            transform: 'translateY(-4px)',
          },
        }}
      >
        {retryLoading ? (
          <LoadingResubmittedInfo />
        ) : retrycomplete ? (
          <InfoResubmittedSuccess />
        ) : (
          <>
            {!started && <ResubmitIdentity onResubmit={handleGetStarted} />}
            {started  && <CustomerRetryInfoPage onBack={handleBack} />}
          
          </>
        )}


{verificationStatus && (
        <VerificationModal />
      )}
      </Box>
    );
  };
  
  export default RetryMaster;