import { Box } from "@mui/material";
import MainLayout from "./parts/layout/MainLayout";


const DevelopmentPageUI = ({
 
}) => {
  return (
    <>
    
<MainLayout>
<Box
  sx={{
    height: '500px',
    width: '500px',
    backgroundColor: 'black',
  }}
></Box>

</MainLayout>

    </>
  );
};

export default DevelopmentPageUI;
