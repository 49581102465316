import React from "react";
import { TextField } from "@mui/material";

const DateFormattedTextField = ({
  label,
  value,
  onChange,
  name,
  error,
  errorMessageAndhelp,
  onFocus,
  multiline = false,
  rows,
  commonStyles,
}) => {
  const handleDateChange = (e) => {
    const inputValue = e.target.value;
    // Remove all non-digit characters
    const cleanedValue = inputValue.replace(/[^0-9]/g, '');

    // Format the cleaned value to mm-dd-yyyy
    let formattedValue = cleanedValue;
    if (cleanedValue.length > 2) {
      formattedValue = `${cleanedValue.slice(0, 2)}-${cleanedValue.slice(2)}`;
    }
    if (cleanedValue.length > 4) {
      formattedValue = `${cleanedValue.slice(0, 2)}-${cleanedValue.slice(2, 4)}-${cleanedValue.slice(4, 8)}`;
    }

    // Restrict to mm-dd-yyyy format length
    if (cleanedValue.length > 8) {
      formattedValue = formattedValue.slice(0, 10);
    }

    onChange({ target: { name, value: formattedValue } });
  };

  return (
    <TextField
      label={label}
      variant="outlined"
      value={value}
      name={name}
      onChange={handleDateChange}
      onFocus={onFocus}
      fullWidth
      error={error}
      helperText={errorMessageAndhelp}
      multiline={multiline}
      rows={multiline ? rows : undefined}
      sx={commonStyles}
    />
  );
};

export default DateFormattedTextField;
