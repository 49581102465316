import React from "react";
import { TextField, InputAdornment, IconButton, MenuItem } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const DocumentTypeFieldUI = ({
  selectedType,
  handleDocumentTypeChange,
  document,
  onClick,
}) => {
  return (
    <TextField
      select
      label="Document Type"
      value={selectedType}
      onChange={handleDocumentTypeChange}
      fullWidth
      variant="outlined"
      sx={{ mb: 2 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={onClick} sx={{ mr: "6px" }}>
              <HelpOutlineIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    >
      {document?.types?.map((type, index) => (
        <MenuItem key={index} value={type}>
          {type}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default DocumentTypeFieldUI;
