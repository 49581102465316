import { Box, IconButton, Typography, useTheme, useMediaQuery } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const PageHeader = ({
  title,
  description,
  onBackClick,
  backArrow,
  infoText,
  handleOpenInfoDailog,
  action
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm")); // Detect mobile screens

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row", // Stack vertically on mobile
        justifyContent: isMobile ? "center" : "space-between",
        alignItems: "center",
        width: "100%",
        px: ".75rem",
        py: "1.6rem",
        borderBottom: "1px solid gray",
        textAlign: isMobile ? "center" : "left", // Center text on mobile
        mb: "1.2rem",
      }}
    >
      <Box
        sx={{
          flex: 1,
          mb: isMobile ? "1rem" : 0, // Add bottom spacing for mobile
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row", // Stack title and icon for mobile
            alignItems: isMobile ? "center" : "flex-start",
            mb: isMobile ? 1 : "0",
          }}
        >
          {backArrow && (
            <IconButton
              aria-label="back"
              onClick={onBackClick}
              size={isMobile ? "medium" : "small"}
              sx={{ mr: isMobile ? 0 : "1rem" }}
            >
              <KeyboardBackspaceIcon
                sx={{
                  color: "black",
                  fontSize: isMobile ? "1.5rem" : "1rem", // Larger icons on mobile
                }}
              />
            </IconButton>
          )}
          <Typography
            variant={isMobile ? "h5" : "h2"} // Smaller title on mobile
            component="h1"
            sx={{
              fontWeight: 700,
              lineHeight: isMobile ? 1.3 : 1,
              mt: isMobile && backArrow ? "0.5rem" : 0,
              mb:1
            }}
          >
            {title}
          </Typography>
        </Box>
        <Typography
          variant="body2"
          sx={{
            color: "text.secondary",
            fontSize: isMobile ? "0.9rem" : "1rem", // Adjust font size for mobile
            px: isMobile ? "1rem" : 0,
          }}
        >
          {description}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: isMobile ? "center" : "flex-end",
          cursor: "pointer",
          mt: isMobile ? "1rem" : 0, // Add spacing on mobile
        }}
        onClick={handleOpenInfoDailog}
      >
        {infoText && (
          <IconButton
            aria-label="info"
            size={isMobile ? "medium" : "small"}
            sx={{
              fontSize: isMobile ? "1.5rem" : "1rem", // Larger icon for mobile
            }}
          >
            <InfoOutlinedIcon sx={{ color: "green" }} />
          </IconButton>
        )}
        <Typography
          variant="body2"
          sx={{
            color: "green",
            fontSize: isMobile ? "0.9rem" : "1rem", // Adjust font size for mobile
            ml: isMobile ? "0.5rem" : "1rem",
          }}
        >
          {infoText}
        </Typography>
          {action && action}
      </Box>
    </Box>
  );
};

export default PageHeader;