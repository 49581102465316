import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography } from '@mui/material'; // Add Typography to use for styling text

// project imports
import { DASHBOARD_PATH } from 'config';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <Link component={RouterLink} to={DASHBOARD_PATH} aria-label="clavaa logo" sx={{ textDecoration: 'none' }}>
        <Typography
            variant="h3" // Increased size to h3 (you can adjust further if needed)
            component="div"
            color="primary"
            sx={{ mb: 2 }} // Added margin-bottom of 2 (you can adjust the number as needed)
        >
            Clavaa
        </Typography>
    </Link>
);

export default LogoSection;
