import SignInPage from "../../ui/organisms/signInPage";

const SignInPageTwoContainer = () => {
  





  return <SignInPage />;
};

export default SignInPageTwoContainer;
