import { useState } from "react";
import { useSelector } from "react-redux";
import { selectRegisterData } from "./../../../../features/register/registerSelectors";

const RegisterSettingsLogic = () => {
  const registerData = useSelector(selectRegisterData);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);
  const [showResetPasswordDailog, setShowResetPasswordDailog] = useState(false);

  const registerSettings = [
    { primary: "Username", secondary: registerData.username },
    { primary: "Password", secondary: "******" },
    { primary: "Refund PIN", secondary: registerData.register_pin },
    { primary: "Registers in Use", secondary: registerData.register_count },
  ];

  const handleOpenResetPasswordDailog = () => {
    setShowResetPasswordDailog(true);
  };

  const handleCloseResetPasswordDailog = () => {
    setShowResetPasswordDailog(false);
  };

  const handleSubmitResetPassword = () => {
    handleCloseResetPasswordDailog();
  };

  const handleSubmit = (username, password) => {
    setShowUpdateDialog(false);
  };

  const handleUpdateModal = (value) => {
    setShowUpdateDialog(value);
  };

  return {
    registerSettings,
    showUpdateDialog,
    handleSubmit,
    handleUpdateModal,
    handleOpenResetPasswordDailog,
    handleCloseResetPasswordDailog,
    handleSubmitResetPassword,
    showResetPasswordDailog,
  };
};

export default RegisterSettingsLogic;
