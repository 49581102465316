// src/components/BusinessMaster.js

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, useTheme } from '@mui/material';
import LoadingDocuments from './loading';
import DocumentsSubmittedSuccess from './complete';
import RequestDocumentation from './start';
import DocumentPage from './documentPage';
import { selectDocumentComplete, selectDocumentLoading, selectVerificationstatus } from '../../../../../../../features/verification/verificationInfoSelectors';
import VerificationModal from '../../../dwollaCheckModal/main';

const DocumentMaster = () => {
    const theme = useTheme();
    const verificationStatus = useSelector(selectVerificationstatus);
    const documentLoading = useSelector(selectDocumentLoading);
    const documentcomplete = useSelector(selectDocumentComplete);
    
    const [started, setStarted] = useState(false);
  
    const handleGetStarted = () => {
      setStarted(true);
    };
  
    const handleBack = () => {
      setStarted(false);
    };
  
    return (
      <Box
        sx={{
          width: 600,
          border: `1px solid #E0E0E0`,
          borderRadius: '16px',
          padding: theme.spacing(4),
          textAlign: 'center',
          cursor: 'pointer',
          transition: 'box-shadow 0.3s ease, transform 0.3s ease',
          backgroundColor: '#FFFFFF',
          '&:hover': {
            boxShadow: '0 8px 30px rgba(0, 0, 0, 0.1)',
            transform: 'translateY(-4px)',
          },
        }}
      >
        {documentLoading ? (
          <LoadingDocuments />
        ) : documentcomplete ? (
          <DocumentsSubmittedSuccess />
        ) : (
          <>
            {!started && <RequestDocumentation onUploadDocs={handleGetStarted} />}
            {started  && <DocumentPage onBack={handleBack} />}
          
          </>
        )}

{verificationStatus && (
        <VerificationModal />
      )}
      </Box>
    );
  };
  
  export default DocumentMaster;