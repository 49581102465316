import React from "react";
import { Box, Grid } from "@mui/material";
import AuthBanner from "../../../../../images/auth-banner.png";
import AuthCarousel from "../molecule/authCarousel";

const AuthPageTwo = ({
  transitions,
  animated,
  slides,
  activeIndex,
  children,
}) => {
  return (
    <Box
      sx={{
        position: "relative",
        minHeight: "100vh",
        backgroundImage: `url(${AuthBanner})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.5)",
          zIndex: 1,
        }}
      />
      <Grid
        container
        sx={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: {
              lg: "center",
              md: "flex-start",
            },
            p: 3,
            height: "100vh",
          }}
        >
          <AuthCarousel
            transitions={transitions}
            animated={animated}
            slides={slides}
            activeIndex={activeIndex}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-start",
            p: 3,
            pb: 0,
            minHeight: "100vh",
          }}
        >
          {children}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AuthPageTwo;
