import { createSlice } from '@reduxjs/toolkit';
import locationImageOne from "../../images/location-one.png";
import locationImageTwo from "../../images/location-two.png";
const initialState = {
  storeLocations: [],
  isLoading: false,
  error: null,
};

const storesSlice = createSlice({
  name: 'stores',
  initialState,
  reducers: {
    setStoreLocation: (state, action) => {
      state.storeLocations = action.payload;
    },
    addStoreLocation: (state, action) => {
      state.storeLocations.push(action.payload);
    },
    deleteStoreLocation: (state, action) => {
      state.storeLocations = state.storeLocations.filter(location => location.id !== action.payload);
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setStoreLocation,
  addStoreLocation,
  deleteStoreLocation,
  setLoading,
  setError,
} = storesSlice.actions;

export default storesSlice.reducer;
