// Loader.jsx
import React from 'react';
import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';

const Loader = ({ text = 'Loading...' }) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bgcolor: 'rgba(255, 255, 255, 0.9)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1300,
      }}
    >
      <motion.div
        animate={{ rotate: 360 }}
        transition={{ repeat: Infinity, duration: 1, ease: 'linear' }}
        style={{
          width: 40,
          height: 40,
          border: '3px solid #e0e0e0',
          borderTop: `6px solid #1EC674`,
          borderRadius: '50%',
        }}
      />
      <Typography
        sx={{
          mt: 2,
          color: '#1EC674',
          fontSize: '14px',
          fontWeight: '500',
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default Loader;
