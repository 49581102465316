import { FormattedMessage } from 'react-intl';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';

const icons = {
    AppRegistrationIcon,
    AccountCircleIcon,
    SettingsIcon
};

const locationMenu = {
    id: 'Locations',
    title: <FormattedMessage id="Location Management" />,
    type: 'group',
  
    children: [
       
        {
            id: 'profile',
            title: <FormattedMessage id="Locations" />,
            type: 'item',
            url: '/profile',
            icon: icons.AccountCircleIcon,
            breadcrumbs: false
        }
    ]
};

export default locationMenu;
