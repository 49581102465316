// validateList.js
// Validates that a list (array) has items in it

export function validateList(list) {

  if (!Array.isArray(list)) {
    return "Input is not a valid array.";
  }
  return list.length > 0 ? "" : "The list is empty.";
}
