import { useState } from "react";

import useInfoList from "../molecules/useInfoList";

const useUpdateRetryInfo = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({});

  const handleItemClick = (details) => {
    setDialogData(details);

    // setDialogData(details);
    setDialogOpen(true);
  };

  const { formatValue } = useInfoList();

  return {
    dialogOpen,
    handleItemClick,
    setDialogOpen,
    dialogData,
 
    formatValue,
  };
};

export default useUpdateRetryInfo;
