import React from 'react';
import {
  Box,
  Typography,
  Divider,
  Card,
  CardContent,
  Button,
} from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuthHelpers } from '../../../../functions/auth/signout';
import PageHeader from '../../../../components/molecules/pageHeader/PageHeader';

const SettingsComponent = () => {
  const { handleSignOut } = useAuthHelpers();

  return (
    <Box >
      {/* Header */}
  
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
         
       
        }}
      >
        <PageHeader
          title={"Settings"}
          description={
            "Manage your account settings and preferences."
          }
          backArrow={false}
         
        
        />
      <Box sx={{px: 5}}>
      {/* Help Center Coming Soon */}
      <Card sx={{ mb: 4, p: 2 }}>
        <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
          <HelpOutlineIcon sx={{ fontSize: 50, mr: 3 }} color="primary" />
          <Box>
            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
              Help Center Coming Soon
            </Typography>
            <Typography variant="h5" color="textSecondary">
              We're working hard to bring you a better help experience.
            </Typography>
          </Box>
        </CardContent>
      </Card>

      {/* Contact Information */}
      <Card sx={{ mb: 4, p: 2 }}>
        <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
          <ContactSupportIcon sx={{ fontSize: 50, mr: 3 }} color="secondary" />
          <Box>
            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
              Need Assistance?
            </Typography>
            <Typography variant="h5" color="textSecondary">
              If you need any technical, payment, or other assistance, please
              reach out to your account representative or email us at{' '}
              <a href="mailto:help@clavaa.com">help@clavaa.com</a>.
            </Typography>
          </Box>
        </CardContent>
      </Card>

      {/* Logout Section */}
      <Card sx={{ mb: 4, p: 2 }}>
        <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
          <LogoutIcon sx={{ fontSize: 50, mr: 3 }} color="error" />
          <Box>
            <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
              Want to Logout?
            </Typography>
            <Typography variant="h5" color="textSecondary">
              Click the button below to safely log out of your account.
            </Typography>
            <Button
              variant="contained"
              color="error"
              onClick={handleSignOut}
              startIcon={<LogoutIcon />}
              sx={{ mt: 2, fontSize: '1rem', padding: '8px 16px' }}
            >
              Logout
            </Button>
          </Box>
        </CardContent>
      </Card>

      </Box>
        
      </Box>
    </Box>
  );
};

export default SettingsComponent;
