import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useDispatch } from 'react-redux';
import { setBusinessType } from '../../../../../../features/verification/verificationInfoSlice';

const options = [
  { label: 'Sole Proprietorship', value: 'soleProprietorship' },
  { label: 'Partnership', value: 'partnership' },
  { label: 'Limited Liability Company (LLC)', value: 'llc' },
  { label: 'Corporation', value: 'corporation' },
];

const OptionCard = ({ option, selected, onSelect }) => (
  <Card
    variant="outlined"
    sx={{
      mb: 2,
      borderColor: selected ? '#1976d2' : 'grey.300',
      backgroundColor: selected ? 'rgba(25, 118, 210, 0.1)' : '#fff',
      boxShadow: selected ? '0 4px 20px rgba(0, 0, 0, 0.1)' : 'none',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
        cursor: 'pointer',
      },
    }}
    onClick={onSelect}
  >
    <CardActionArea>
      <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
        {selected ? (
          <CheckCircleIcon sx={{ mr: 2, color: '#1976d2' }} />
        ) : (
          <RadioButtonUncheckedIcon sx={{ mr: 2, color: 'grey.500' }} />
        )}
        <Typography variant="body1">{option.label}</Typography>
      </CardContent>
    </CardActionArea>
  </Card>
);

const BusinessInfoPageInputsUI = ({ onBack }) => {
  const [selectedBusinessType, setSelectedBusinessType] = useState(null);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    if (selectedBusinessType) {
      dispatch(setBusinessType(selectedBusinessType));
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 500,
        width: '100%',
        mx: 'auto',
        textAlign: 'center',
      }}
    >
      <Typography variant="h3" sx={{ mb: 3 }}>
      Select Your Business Type
    </Typography>
      {options.map((option) => (
        <OptionCard
          key={option.value}
          option={option}
          selected={selectedBusinessType === option.value}
          onSelect={() => setSelectedBusinessType(option.value)}
        />
      ))}
 <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      mt: 3,
    }}
  >
    <Button
      variant="outlined"
      color="secondary"
      onClick={onBack}
      sx={{
        fontSize: '1rem',
        padding: '12px 24px',
        borderRadius: '8px',
        flex: 1,
        mr: 1,
      }}
    >
      Back
    </Button>
    <Button
      variant="contained"
      color="primary"
      disabled={!selectedBusinessType}
      onClick={handleSubmit}
      sx={{
        fontSize: '1rem',
        padding: '12px 24px',
        borderRadius: '8px',
        flex: 1,
        ml: 1,
      }}
    >
      Submit
    </Button>
  </Box>
    </Box>
  );
};

export default BusinessInfoPageInputsUI;
