
// Validates the given dictionary, returning an empty string if no errors

export function validateDetails(data) {
    const errors = {};
  
    // Iterate over the keys in the dictionary
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        // Check if the value is empty
        if (!data[key]) {
          errors[key] = `${key.charAt(0).toUpperCase() + key.slice(1)} is required.`;
        }
      }
    }
  
    // Return an empty string if no errors, otherwise return the errors object
    return Object.keys(errors).length === 0 ? '' : errors;
  }
  