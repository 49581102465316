import { useState } from "react";
import RegisterPage from "../../ui/organims/registerPage";
import RegisterLogic from "./../../functional/organisms/registerLogic";
import EmployeeManagementPageComponent from "../../../employeeManagement/combined/organisms/employeeManagementPageComponent";

const RegisterPageComponent = () => {
  const {
    activeInfoDailog,
    formVisible,
    error,
    accountInfo,
    registerSettings,
    masterRegister,
    showUpdateDialog,
    handleInfoDailog,
    handleUpdateModal,
    handleSubmitForm,
    handleSetupMasterRegisterSubmit,
    handleFormChange,
    handleFormVisible,
    employeesList,
    sendEmailToAllEmplyesSubmit,
    sendEmailToOneEmplyeeSubmit,
    DeleteEmplyeeSubmit,
    addEmplyeeSubmit,
    handleOpenResetPasswordDailog,
    handleCloseResetPasswordDailog,
    handleSubmitResetPassword,
    showResetPasswordDailog,
    showInfoDailog,
    handleOpenInfoDailog,
    handleCloseInfoDailog,
    handleNextButtonClick,
    handleBackButtonClick,
    carouselItems,
    activeIndex,
    navigate,
  } = RegisterLogic();
  const [showEmployeeManagement, setShowEmployeeManagement] = useState(false);

   


  return (
    <>
      {showEmployeeManagement ? (
        <EmployeeManagementPageComponent
          onBackClick={() => setShowEmployeeManagement(false)}
        />
      ) : (
        <RegisterPage
          activeInfoDailog={activeInfoDailog}
          formVisible={formVisible}
          masterRegister={masterRegister}
          registerSettings={registerSettings}
          accountInfo={accountInfo}
          error={error}
          showUpdateDialog={showUpdateDialog}
          handleInfoDailog={handleInfoDailog}
          handleUpdateModal={handleUpdateModal}
          handleSetupMasterRegisterSubmit={handleSetupMasterRegisterSubmit}
          handleFormVisible={handleFormVisible}
          handleSubmitForm={handleSubmitForm}
          handleFormChange={handleFormChange}
          handleOpenResetPasswordDailog={handleOpenResetPasswordDailog}
          handleCloseResetPasswordDailog={handleCloseResetPasswordDailog}
          handleSubmitResetPassword={handleSetupMasterRegisterSubmit}
          showResetPasswordDailog={showResetPasswordDailog}
          handleOpenInfoDailog={handleOpenInfoDailog}
          handleCloseInfoDailog={handleCloseInfoDailog}
          showInfoDailog={showInfoDailog}
          handleNextButtonClick={handleNextButtonClick}
          handleBackButtonClick={handleBackButtonClick}
          carouselItems={carouselItems}
          activeIndex={activeIndex}
          manageEmployees={() => setShowEmployeeManagement(true)}
        />
      )}
    </>
  );
};

export default RegisterPageComponent;
