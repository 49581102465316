import { Box } from "@mui/material";
import UserCredentialsCardUI from "../userCredentialsCardUI";
import EmployeeManagementCardUI from "../employeeManagementCardUI";

const CardsUIContainer = ({ handleOpenResetPasswordDailog, manageEmployees }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        width: "100%",
        "@media (max-width: 768px)": {
          flexDirection: "column",
        },
      }}
    >
      <UserCredentialsCardUI
        handleOpenResetPasswordDailog={handleOpenResetPasswordDailog}
      />
      <EmployeeManagementCardUI manageEmployees={manageEmployees} />
    </Box>
  );
};

export default CardsUIContainer;
