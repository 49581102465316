import Box from "@mui/material/Box"; // Import Box component from MUI

import RefisterSettingUI from "./../molecules/registerSettingUI";
import ChangeConfirmdailog from "./../molecules/container/changeConfirmdailog";
import UpdateRegisterDailog from "./../molecules/container/updateRegisterDailog";
import PageHeader from "../../../../components/molecules/pageHeader/PageHeader";
import CardsUIContainer from "./../molecules/container/cardsUIContainer";
import ResetPasswordDialog from "../molecules/container/resetPasswordDailog";
import InfoDailog from "../molecules/container/infoDailog";

const RegisterPage = ({
  activeInfoDailog,
  handleInfoDailog,
  formVisible,
  handleSetupMasterRegisterSubmit,

  registerSettings,
  accountInfo,
  error,
  handleSubmitForm,
  handleFormChange,
  showUpdateDialog,
  handleUpdateModal,
  handleFormVisible,

  handleOpenResetPasswordDailog,
  handleCloseResetPasswordDailog,
  handleSubmitResetPassword,
  showResetPasswordDailog,

  showInfoDailog,
  handleOpenInfoDailog,
  handleCloseInfoDailog,
  handleNextButtonClick,
  handleBackButtonClick,
  carouselItems,
  activeIndex,
  manageEmployees,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "auto",
        alignItems: "center",
      }}
    >
      <PageHeader
        title={"Streamline User And Employee Management"}
        description={
          "Effertless credential setup and sealmess employee oversight"
        }
        backArrow={false}
        infoText={"Learn How this Works?"}
        handleOpenInfoDailog={handleOpenInfoDailog}
      />

      <CardsUIContainer
        handleOpenResetPasswordDailog={handleOpenResetPasswordDailog}
        manageEmployees={manageEmployees}
      />

      {showResetPasswordDailog && (
        <ResetPasswordDialog
          open={showResetPasswordDailog}
          onClose={handleCloseResetPasswordDailog}
          onSubmit={handleSubmitResetPassword}
        />
      )}

      {showInfoDailog && (
        <InfoDailog
          open={showInfoDailog}
          onClose={handleCloseInfoDailog}
          handleNextButtonClick={handleNextButtonClick}
          handleBackButtonClick={handleBackButtonClick}
          carouselItems={carouselItems}
          activeIndex={activeIndex}
        />
      )}

      {/* <RefisterSettingUI
                activeInfoDailog={activeInfoDailog}
                handleInfoDailog={handleInfoDailog}
                onClick={handleSetupMasterRegisterSubmit} 
                registerSettings={registerSettings} 
                handleUpdateModal={handleUpdateModal}
                showUpdateDialog={showUpdateDialog}
                handleSubmitForm={handleSubmitForm}
            /> */}

      {showUpdateDialog && (
        <ChangeConfirmdailog
          formVisible={formVisible}
          open={showUpdateDialog}
          onClose={() => handleUpdateModal(false)}
          handleFormVisible={handleFormVisible}
        />
      )}
      {formVisible && showUpdateDialog && (
        <UpdateRegisterDailog
          accountInfo={accountInfo}
          error={error}
          open={showUpdateDialog}
          onClose={() => handleUpdateModal(false)}
          onSubmit={handleSubmitForm}
          handleFormChange={handleFormChange}
        />
      )}
    </Box>
  );
};
export default RegisterPage;
