export const selectBusinessType = (state) => state.verificationInfo.businessInfo.business_structure;
export const selectBusinessInfo = (state) => state.verificationInfo.businessInfo;
export const selectBusinessName = (state) => state.verificationInfo.businessInfo.business_name;
export const selectBusinessLoading = (state) => state.verificationInfo.businessLoading;
export const selectPersonalLoading = (state) => state.verificationInfo.personalLoading;

export const selectPersonalInfo = (state) => state.verificationInfo.personalInfo;
export const selectPersonalInfoComplete = (state) => state.verificationInfo.personalInfoComplete;
export const selectPersonalInfoIsSet = (state) => state.verificationInfo.personalInfo.isSet;
export const selectPersonalSsn = (state) => state.verificationInfo.personalInfo.ssn;
export const selectOwnerStatus = (state) => state.verificationInfo.personalInfo.isOwner;
export const selectOwnerList = (state) => state.verificationInfo.owners;
export const selectVerificationstatus = (state) => state.verificationInfo.verificationstatus;

export const selectRetryData = (state) => state.verificationInfo.retryData;
export const selectDocumentData = (state) => state.verificationInfo.documentData;

// New selectors for retryComplete, retryLoading, documentComplete, and documentLoading
export const selectRetryComplete = (state) => state.verificationInfo.retryComplete;
export const selectRetryLoading = (state) => state.verificationInfo.retryLoading;
export const selectDocumentComplete = (state) => state.verificationInfo.documentComplete;
export const selectDocumentLoading = (state) => state.verificationInfo.documentLoading;