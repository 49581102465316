import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Stack, IconButton, Box, Typography, Checkbox, FormControlLabel } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import { setPersonalLoading, removeOwner, setPersonalSsn, setVerificationstatus, setPersonalInfoComplete } from "../../../../../../../features/verification/verificationInfoSlice";
import { selectOwnerList, selectPersonalInfo, selectOwnerStatus, selectBusinessName } from "../../../../../../../features/verification/verificationInfoSelectors";
import useFullFetchFeedback from '../../../../../../../functions/apiFetchers/fullPageLoaders';

function OwnerManagerUIInputs({ onOwnerClick, onAdd }) {
  const dispatch = useDispatch();
  const owners = useSelector(selectOwnerList);
  const personalInfo = useSelector(selectPersonalInfo);
  const businessName = useSelector(selectBusinessName);
  const ownerStatus = useSelector(selectOwnerStatus);
  const fetchFeedback = useFullFetchFeedback();

  // State for the checkbox to confirm 25% ownership
  const [isOwnershipConfirmed, setIsOwnershipConfirmed] = useState(false);

  // Handler to reset owner list and clear SSN data on "Back" button click
  const handleBack = () => {
    dispatch(setPersonalSsn(null)); // Clear any entered SSN data
    owners.forEach(owner => dispatch(removeOwner(owner.id))); // Remove all owners
  };

  const handleDeleteClick = (ownerId) => (e) => {
    e.stopPropagation();
    dispatch(removeOwner(ownerId));
  };

  const onClick = (ownerInfo) => {
    dispatch(removeOwner(ownerInfo.id));
    onOwnerClick(ownerInfo);
  };

  const handleSubmit = async () => {
    if (owners.length === 0) return;

    const finalOwners = ownerStatus
      ? [...owners, { ...personalInfo, title: undefined }]
      : owners;

    await fetchFeedback({
      endpoint: "merchant/personal_info/",
      data: { owners: finalOwners, personal_info: personalInfo, isSoleProp: false },
      requestType: "POST",
      setLoader: (isLoading) => dispatch(setPersonalLoading(isLoading)),
      onSuccess: (responseData) => {
        dispatch(setPersonalInfoComplete());
        dispatch(setVerificationstatus("loading"));
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  return (
    <Box
      sx={{
        maxWidth: 600, width: "100%", mx: "auto", textAlign: "center"
      }}
    >
      {/* Header */}
      <Typography variant="h4" sx={{ mb: 2 }}>
        Manage Owners
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 4, color: "text.secondary" }}>
        Add, edit, or remove owners associated with this account. Please ensure that you have entered all owners representing at least 25% ownership.
      </Typography>

      {/* Owner List and Add Button */}
      <Stack spacing={0} sx={{ mb: 4 }}>
        {owners.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "background.paper",
              border: 1,
              borderColor: "grey.300",
              py: 1,
              px: 2,
            }}
          >
            No owners added
          </Box>
        ) : (
          owners.map((owner) => (
            <Button
              key={owner.id}
              fullWidth
              sx={{
                justifyContent: "space-between",
                bgcolor: "background.paper",
                border: 0.2,
                borderColor: "grey.300",
                py: 1,
                px: 2,
                textTransform: "none",
                borderRadius: "0",
                boxShadow: "none",
                ":hover": {
                  bgcolor: "grey.100",
                  boxShadow: "none",
                },
              }}
              onClick={() => onClick(owner)}
            >
              <Typography
                variant="body1"
                sx={{
                  color: 'text.secondary',
                  textAlign: 'center',
                  fontWeight: 600,
                  paddingY: 2,
                  flex: 1,
                }}
              >
                Owner: {owner.first_name} {owner.last_name}
              </Typography>

              <IconButton onClick={() => handleDeleteClick(owner.id)} size="large">
                <DeleteIcon />
              </IconButton>
            </Button>
          ))
        )}
        <Button
          fullWidth
          variant="contained"
          startIcon={<AddCircleOutlineIcon />}
          onClick={onAdd}
          sx={{
            bgcolor: "grey.200",
            color: "black",
            ":hover": {
              bgcolor: "grey.300",
              boxShadow: "none",
            },
            border: 1,
            borderTop: 0,
            borderColor: "grey.300",
            borderRadius: "0",
            boxShadow: "none",
          }}
        >
          Add Owner
        </Button>
      </Stack>

      {/* Ownership Confirmation Checkbox */}
      <FormControlLabel
  control={
    <Checkbox
      checked={isOwnershipConfirmed}
      onChange={(e) => setIsOwnershipConfirmed(e.target.checked)}
      color="primary"
    />
  }
  label={`I hereby certify that the information provided for ${businessName} Controller and Beneficial Owners is complete and correct`}
  sx={{ mb: 2, color: "text.secondary" }}
/>

      {/* Action Buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mt: 4,
        }}
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleBack}
          sx={{
            fontSize: "1rem",
            padding: "12px 24px",
            borderRadius: "8px",
            flex: 1,
            mr: 1,
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={owners.length === 0 || !isOwnershipConfirmed} // Disable if no owners or checkbox not confirmed
          color="primary"
          sx={{
            fontSize: "1rem",
            padding: "12px 24px",
            borderRadius: "8px",
            flex: 1,
            ml: 1,
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}

export default OwnerManagerUIInputs;
