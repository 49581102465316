import { useState } from "react";
import { useDispatch } from "react-redux";
import { showDialog } from "../../../../../../../features/dialog/dialogSlice";
import documentUploadInstructions from "../constants/documentUploadInstructions";

const useUploadField = ({ onFileSelect }) => {
  const dispatch = useDispatch();
  const [documentName, setDocumentName] = useState("No document uploaded");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setDocumentName(file.name);
  
      const reader = new FileReader();
      reader.onloadend = () => {
        if (onFileSelect) {
          const base64Data = reader.result.split(',')[1]; // Strip the data URL prefix
          onFileSelect(base64Data); // Pass only the base64 data
        }
      };
      reader.readAsDataURL(file);
    }
  };
  

  const onClick = () => {
    dispatch(
      showDialog({
        dialogType: "info",
        message: documentUploadInstructions,
        title: "Upload Guidelines",
      })
    );
  };

  return {
    handleFileChange,
    onClick,
    documentName,
  };
};

export default useUploadField;
