import { Box } from "@mui/material";
import GeneralInput from "../../../../../components/atoms/inputs/GeneralInput";
import CustomInputDialog from "../../../../../components/molecules/dialogues/customInput";


const UpdateRegisterDailog = ({
    open,
    onClose,
    onSubmit,
    accountInfo,
    error,
    handleFormChange
    }) => {
    return (
        <>
        <CustomInputDialog
                open={open}
                onClose={onClose}
                onSubmit={() =>
                    onSubmit(accountInfo.username, accountInfo.password)
                }
                title="Update Register Control"
                subTitle="Change the username and password for all your store clavaa registers. We will generate a new refund pin too."
             >
                <Box sx={{ display: "grid", gap: 2, width: "85%", margin: "auto" }}>
                    <GeneralInput
                        type={"text"}
                        label="Username"
                        name={"username"}
                        value={accountInfo.username}
                        onChange={(e) => handleFormChange(e)}
                        error={!!error.username}
                        errorMessageAndhelp={error.username || ""}
                    />
                    <GeneralInput
                        type={"password"}
                        label="Password"
                        name={"password"}
                        value={accountInfo.password}
                        onChange={(e) => handleFormChange(e)}
                        error={!!error.password}
                        errorMessageAndhelp={error.password || ""}
                    />
                </Box>
                </CustomInputDialog>
        </>
    )
}

export default UpdateRegisterDailog;