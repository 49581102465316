import React from 'react';
import { Link, Box } from '@mui/material';

function ResendCodeLink(props) {
  const { onResendCode } = props;

  return (
    <Box style={{ marginTop: '16px', textAlign: 'center' }}>
      <Link
        component="button"
        variant="body2"
        onClick={onResendCode}
        underline="none"
        style={{
          fontSize: '15px',
          fontFamily: 'SF Pro Text, Helvetica Neue, Arial, sans-serif',
          color: '#007AFF',
          fontWeight: '500',
        }}
      >
        Resend Code
      </Link>
    </Box>
  );
}

export default ResendCodeLink;
