import { useState } from "react";
import GeneralInput from "../../../../../components/atoms/inputs/GeneralInput";
import GeneralButton from "../../../../../components/atoms/buttons/GeneralButton";
import { Dialog, DialogContent, Typography } from "@mui/material";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import useFullFetchFeedback from "../../../../../functions/apiFetchers/fullPageLoaders";
import { updateRegisterData } from "../../../../../features/register/registerSlice";
import { useDispatch } from "react-redux";

const UpdateRegisterControlDialog = ({ open, onClose }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ username: false, password: false });
  const fetchFeedback = useFullFetchFeedback();
  const dispatch = useDispatch();

  const handleValidation = () => {
    const newErrors = {
      username: username.trim() === "",
      password: password.trim() === "",
    };
    setErrors(newErrors);

    // Return true if there are no errors
    return !Object.values(newErrors).includes(true);
  };

  const handleSetupMasterRegisterSubmit = async () => {
    if (!handleValidation()) {
      return;
    }

    await fetchFeedback({
      endpoint: `merchant/register-account/`,
      loaderType: "dialogueLoader",
      data: { username, password },
      requestType: "POST",
      loaderMessage: "Updating registration...",
      successMessage: "Registration successful!",
      onSuccess: (responseData) => {
        dispatch(updateRegisterData(responseData));
        onClose();
      },
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          padding: 2,
          borderRadius: 2,
          width: "100%",
          textAlign: "center",
        },
      }}
    >
      <Typography
        sx={{ fontSize: "1.5rem", fontWeight: "bold", marginY: "10px" }}
      >
        Update Register Control
      </Typography>
      <DialogContent
        sx={{
          color: "text.secondary",
          paddingY: "10px",
          letterSpacing: "0.5px",
          width: "100%",
          lineHeight: "1.5",
          margin: "auto",
          textAlign: "center",
          fontSize: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        Change the username and password for all your Clavaa store registers.
        A new refund PIN will also be generated.
      </DialogContent>

      <GeneralInput
        type="text"
        label="Username"
        name="username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        commonStyles={{
          marginTop: "2rem",
        }}
        error={errors.username}
        errorMessageAndhelp={errors.username ? "Username is required" : ""}
      />
      <GeneralInput
        type="password"
        label="Password"
        name="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        commonStyles={{
          marginTop: "2rem",
        }}
        error={errors.password}
        errorMessageAndhelp={errors.password ? "Password is required" : ""}
      />
      <GeneralButton
        label={
          <>
            Submit <ArrowOutwardOutlinedIcon style={{ marginLeft: "8px" }} />
          </>
        }
        onClick={handleSetupMasterRegisterSubmit}
        type="circularIcon"
        variant="outlined"
        sx={{
          width: "40%",
          margin: "auto",
          marginTop: "20px",
        }}
      />
    </Dialog>
  );
};

export default UpdateRegisterControlDialog;