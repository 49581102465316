import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

const VerificationFailed = () => {
  const theme = useTheme();

  return (
    <Box
    sx={{
      border: `1px solid #E0E0E0`,
      borderRadius: '16px',
      padding: theme.spacing(4),
      textAlign: 'center',
      backgroundColor: '#FFFFFF',
      transition: 'box-shadow 0.3s ease, transform 0.3s ease',
      '&:hover': {
        boxShadow: '0 8px 30px rgba(0, 0, 0, 0.1)',
        transform: 'translateY(-4px)',
      },
    }}
  >
    <Box textAlign="center">
      <ErrorIcon sx={{ fontSize: 50, color: '#FF4C4C', marginBottom: theme.spacing(2) }} />
      <Typography variant="h5" gutterBottom sx={{ color: '#333333', fontWeight: '600' }}>
        Verification Failed
      </Typography>
      <Typography variant="body1" sx={{ color: '#666666', marginBottom: theme.spacing(4) }}>
        We couldn’t verify your information. Please reach out to <strong>help@clavaa.com</strong> for assistance.
      </Typography>
    </Box>
    </Box>
  );
};

export default VerificationFailed;
