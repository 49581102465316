import React, { useState } from "react";
import { TextField, InputAdornment, Button, IconButton } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const UploadFieldUI = ({ documentName, handleFileChange, onClick }) => {
  return (
    <TextField
      fullWidth
      variant="outlined"
      value={documentName}
      label="Document Name"
      InputProps={{
        disabled: true,
        endAdornment: (
          <InputAdornment position="end">
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              sx={{
                bgcolor: "primary.main",
                ":hover": { bgcolor: "primary.dark" },
              }}
            >
              Upload
              <input type="file" hidden onChange={handleFileChange} />
            </Button>
            <IconButton onClick={onClick} sx={{ mr: "6px" }}>
              <HelpOutlineIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default UploadFieldUI;
