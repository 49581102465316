// PastCashbackOffers.jsx

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
} from '@mui/material';
import { format, parse, differenceInDays, differenceInHours } from 'date-fns';
import { History, CheckCircle, Cancel } from '@mui/icons-material';
import Chart from 'react-apexcharts';
import { useTheme } from '@mui/material/styles';

const PastCashbackOffers = ({ cashbackHistory }) => {
  const theme = useTheme();

  // Determine the granularity based on the date range
  const { granularity, formattedCategories } = useMemo(() => {
    if (!cashbackHistory || cashbackHistory.length === 0) {
      return { granularity: 'month', formattedCategories: [] };
    }

    // Extract all dates
    const dates = cashbackHistory.map((offer) => new Date(offer.start_date));
    const minDate = new Date(Math.min(...dates));
    const maxDate = new Date(Math.max(...dates));

    const dayDifference = differenceInDays(maxDate, minDate);
    const hourDifference = differenceInHours(maxDate, minDate);

    let gran = 'month';
    let categories = [];

    if (dayDifference > 30) {
      gran = 'month';
      // Group by month
      const dataMap = {};
      cashbackHistory.forEach((offer) => {
        const date = new Date(offer.start_date);
        const key = format(date, 'MMM yyyy');
        if (dataMap[key]) {
          dataMap[key] += offer.amount;
        } else {
          dataMap[key] = offer.amount;
        }
      });
      categories = Object.keys(dataMap).sort((a, b) => {
        const dateA = parse(a, 'MMM yyyy', new Date());
        const dateB = parse(b, 'MMM yyyy', new Date());
        return dateA - dateB;
      });
    } else if (dayDifference >= 1) {
      gran = 'day';
      // Group by day
      const dataMap = {};
      cashbackHistory.forEach((offer) => {
        const date = new Date(offer.start_date);
        const key = format(date, 'dd MMM');
        if (dataMap[key]) {
          dataMap[key] += offer.amount;
        } else {
          dataMap[key] = offer.amount;
        }
      });
      categories = Object.keys(dataMap).sort((a, b) => {
        const dateA = parse(a, 'dd MMM', new Date());
        const dateB = parse(b, 'dd MMM', new Date());
        return dateA - dateB;
      });
    } else {
      gran = 'hour';
      // Group by hour
      const dataMap = {};
      cashbackHistory.forEach((offer) => {
        const date = new Date(offer.start_date);
        const key = format(date, 'HH:00');
        if (dataMap[key]) {
          dataMap[key] += offer.amount;
        } else {
          dataMap[key] = offer.amount;
        }
      });
      categories = Object.keys(dataMap).sort((a, b) => {
        const dateA = parse(a, 'HH:00', new Date());
        const dateB = parse(b, 'HH:00', new Date());
        return dateA - dateB;
      });
    }

    return { granularity: gran, formattedCategories: categories };
  }, [cashbackHistory]);

  // Function to process data for ApexCharts based on granularity
  const processGraphData = useMemo(() => {
    if (!cashbackHistory || cashbackHistory.length === 0) return [];

    const dataMap = {};

    cashbackHistory.forEach((offer) => {
      const date = new Date(offer.start_date);
      let key = '';

      switch (granularity) {
        case 'hour':
          key = format(date, 'HH:00');
          break;
        case 'day':
          key = format(date, 'dd MMM');
          break;
        case 'month':
        default:
          key = format(date, 'MMM yyyy');
          break;
      }

      if (dataMap[key]) {
        dataMap[key] += offer.amount;
      } else {
        dataMap[key] = offer.amount;
      }
    });

    // Ensure categories are sorted and align with formattedCategories
    return formattedCategories.map((category) => ({
      category,
      totalAmount: dataMap[category] || 0,
    }));
  }, [cashbackHistory, granularity, formattedCategories]);

  // ApexCharts configuration
  const chartOptions = useMemo(() => ({
    chart: {
      type: 'area',
      height: 300,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: false,
      },
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.5,
        gradientToColors: [theme.palette.primary.main],
        inverseColors: false,
        opacityFrom: 0.7,
        opacityTo: 0.2,
        stops: [0, 100],
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: [theme.palette.primary.main],
    xaxis: {
      categories: formattedCategories,
      title: {
        text:
          granularity === 'month'
            ? 'Month'
            : granularity === 'day'
            ? 'Day'
            : 'Hour',
        style: {
          fontWeight: 'bold',
          color: theme.palette.text.primary,
        },
      },
      labels: {
        rotate: -45,
        rotateAlways: true,
        trim: false,
        style: {
          fontSize: '12px',
          colors: theme.palette.text.secondary,
        },
      },
    },
    yaxis: {
      title: {
        text: 'Total Cashback Amount (%)',
        style: {
          fontWeight: 'bold',
          color: theme.palette.text.primary,
        },
      },
      labels: {
        formatter: function (val) {
          return `${val}%`;
        },
        style: {
          colors: theme.palette.text.secondary,
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `${val}% Cashback`;
        },
        title: {
          formatter: (seriesName) => `${seriesName}`,
        },
      },
    },
    grid: {
      borderColor: theme.palette.divider,
      strokeDashArray: 4,
    },
    markers: {
      size: 4,
      colors: [theme.palette.primary.main],
      strokeColors: '#fff',
      strokeWidth: 2,
    },
    legend: {
      show: false,
    },
  }), [theme.palette.divider, theme.palette.primary.main, theme.palette.text.primary, theme.palette.text.secondary, formattedCategories, granularity]);

  const chartSeries = useMemo(() => [
    {
      name: 'Total Cashback Amount',
      data: processGraphData.map((data) => data.totalAmount),
    },
  ], [processGraphData]);

  return (
    <Card
      variant="outlined"
      sx={{
        borderRadius: 3,
        backgroundColor: '#FFFFFF',
        borderColor: '#E0E0E0',
        textAlign: 'left',
        p: { xs: 2, md: 4 },
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
      }}
    >
      <CardContent>
        {/* Header */}
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
          <History sx={{ fontSize: 48, color: theme.palette.primary.main, mr: 2 }} />
          <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
            Cashback Offer History
          </Typography>
        </Box>

        {/* Content */}
        {cashbackHistory && cashbackHistory.length > 0 ? (
          <Grid container spacing={4}>
            {/* List Section */}
            <Grid item xs={12} md={4}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'medium' }}>
                Recent Offers
              </Typography>
              <Box
                sx={{
                  maxHeight: 300, // Adjust as needed
                  overflowY: 'auto',
                  pr: 1, // For scrollbar padding
                }}
              >
                <List>
                  {cashbackHistory
                    .slice(-10) // Show up to the last 10 offers
                    .reverse()
                    .map((offer, index) => {
                      const formattedStartDate = format(new Date(offer.start_date), 'MMM dd, yyyy');
                      const formattedEndDate = offer.end_date
                        ? format(new Date(offer.end_date), 'MMM dd, yyyy')
                        : 'Ongoing';
                      return (
                        <React.Fragment key={index}>
                          <ListItem alignItems="flex-start" disableGutters>
                            <ListItemIcon sx={{ minWidth: 40 }}>
                              {offer.end_date ? (
                                <Cancel color="error" />
                              ) : (
                                <CheckCircle color="success" />
                              )}
                            </ListItemIcon>
                            <ListItemText
                              primary={`${offer.amount}% Cashback`}
                              secondary={
                                <Typography variant="body2" color="text.secondary">
                                  {formattedStartDate} - {formattedEndDate}
                                </Typography>
                              }
                              primaryTypographyProps={{
                                fontSize: '1rem',
                                fontWeight: 'bold',
                              }}
                              secondaryTypographyProps={{
                                fontSize: '0.875rem',
                              }}
                            />
                          </ListItem>
                          {index < cashbackHistory.slice(-10).length - 1 && <Divider component="li" />}
                        </React.Fragment>
                      );
                    })}
                </List>
              </Box>
            </Grid>

            {/* Graph Section */}
            <Grid item xs={12} md={8}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'medium' }}>
                Cashback Amount Over Time
              </Typography>
              <Box sx={{ borderRadius: 2, overflow: 'hidden' }}>
                <Chart options={chartOptions} series={chartSeries} type="area" height={300} />
              </Box>
            </Grid>
          </Grid>
        ) : (
          // No Offers Message
          <Box sx={{ textAlign: 'center', py: 8 }}>
            <History sx={{ fontSize: 80, color: 'action.disabled', mb: 3 }} />
            <Typography variant="h5" color="text.secondary" gutterBottom>
              No Previous Cashback Offers
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
              Engage your customers by creating a new cashback offer.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              sx={{
                textTransform: 'none',
                borderRadius: 8,
                px: 4,
                py: 1.5,
              }}
            >
              Create Cashback Offer
            </Button>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

PastCashbackOffers.propTypes = {
  cashbackHistory: PropTypes.arrayOf(
    PropTypes.shape({
      start_date: PropTypes.string.isRequired,
      end_date: PropTypes.string,
      amount: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default PastCashbackOffers;
