import Box from "@mui/material/Box";

import PageHeader from "../../../../components/molecules/pageHeader/PageHeader";
import CardsUIContainer from "../molecules/container/cardsUIContainer";
import DeleteDailog from "../molecules/container/deleteDialog";

const EmployeeManagementPage = ({
  handleOpenDeleteDialog,
  handleCloseDeleteDialog,
  showDeleteDialog,
  employeeConstant,
  handleDelete,
  onBackClick,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        margin: "auto",
        alignItems: "center",
      }}
    >
      <PageHeader
        title={"Employee Management"}
        description={
          "Effortless credential setup and seamless employee oversight."
        }
        backArrow 
        onBackClick={onBackClick}
      
      />

      <CardsUIContainer
        handleOpenDeleteDialog={handleOpenDeleteDialog}
        employeeConstant={employeeConstant}
      />

      {showDeleteDialog && (
        <DeleteDailog
          open={showDeleteDialog}
          onClose={handleCloseDeleteDialog}
          onConfirm={handleDelete}
        />
      )}
    </Box>
  );
};
export default EmployeeManagementPage;
