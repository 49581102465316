import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { selectPendingBalance } from "./../../../../features/banking/bankSelectors";
import { selectBankData } from "./../../../../features/banking/bankSelectors";
import useInitializePlaid from "./plaid";
const BankLogic = () => {
    const pendingBalance = useSelector(selectPendingBalance);
    const bankData = useSelector(selectBankData);
    const hasBankAccount = bankData && bankData.accountNumber;
    const {
      open,
      ready,
      openModal,
      success,
      setSuccess,
      activeInfoDailog,
      activeCardInfoDailog,
    } = useInitializePlaid();
  
    const handleAccountClick = () => {
      localStorage.removeItem("plaidLinkToken");
      localStorage.removeItem("plaidLinkTokenExpiry");
      ready && open();
    };
  
    const onClose = () => {
      setSuccess(false); // Assuming setSuccess is a function to update the success state
    };
  
    return {
      bankData,
      hasBankAccount,
      pendingBalance,
      openModal,
      success,
      onClose,
      activeInfoDailog,
      activeCardInfoDailog,
      handleAccountClick,
    };
  };
  
  export default BankLogic;
  