const documentUploadInstructions = [
  "All 4 edges of the document should be visible.",
  "A dark/high contrast background should be used.",
  "At least 90% of the image should be the document.",
  "Should be at least 300dpi.",
  "Capture image from directly above the document.",
  "Make sure that the image is properly aligned, not rotated, tilted, or skewed.",
  "No flash to reduce glare.",
  "No black and white documents.",
  "No expired IDs.",
]

export default documentUploadInstructions
