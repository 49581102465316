import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { hideAlert } from '../features/alerts/alertSlice';
import { selectAlertMessage, selectIsAlertOpen } from '../features/alerts/alertSelectors';

const AlertManager = () => {
  const dispatch = useDispatch();

  const infoIsOpen = useSelector(state => selectIsAlertOpen(state, 'info'));
  const infoMessage = useSelector(state => selectAlertMessage(state, 'info'));

  const successIsOpen = useSelector(state => selectIsAlertOpen(state, 'success'));
  const successMessage = useSelector(state => selectAlertMessage(state, 'success'));

  const warningIsOpen = useSelector(state => selectIsAlertOpen(state, 'warning'));
  const warningMessage = useSelector(state => selectAlertMessage(state, 'warning'));

  const errorIsOpen = useSelector(state => selectIsAlertOpen(state, 'error'));
  const errorMessage = useSelector(state => selectAlertMessage(state, 'error'));

  const handleAlertClose = (type) => {
    dispatch(hideAlert(type));
  };

  const alerts = [
    { type: 'info', isOpen: infoIsOpen, message: infoMessage },
    { type: 'success', isOpen: successIsOpen, message: successMessage },
    { type: 'warning', isOpen: warningIsOpen, message: warningMessage },
    { type: 'error', isOpen: errorIsOpen, message: errorMessage },
  ];

  return (
    <div style={{ position: 'fixed', bottom: 20, width: '100%', display: 'flex', justifyContent: 'center', zIndex: 9999 }}>
      {alerts.map(({ type, isOpen, message }) => 
        isOpen && (
          <Alert
            key={type}
            severity={type}
            action={
              <IconButton
                color="inherit"
                size="small"
                onClick={() => handleAlertClose(type)}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            style={{ margin: '10px', paddingRight: 10,paddingLeft: 10, maxWidth: '90%', whiteSpace: 'nowrap',  }}
          >
            {message}
          </Alert>
        )
      )}
    </div>
  );
};

export default AlertManager;
