// TransactionsContainer.js
import React from "react";

import TransactionsTable from "../../ui/organisms/TransactionsTable";
import useTransactions from "../../functional/organisms/useTransactions";


const TransactionsContainer = () => {
  const {
    transactions,
    transactionCount,
    loading,
    page,
    rowsPerPage,
    handlePageChange,
  } = useTransactions();

  return (
    <TransactionsTable
      data={transactions}
      loading={loading}
      rowsPerPage={rowsPerPage}
      totalRows={transactionCount}
      page={page}
      handlePageChange={handlePageChange}
    />
  );
};

export default TransactionsContainer;
