import { combineReducers } from '@reduxjs/toolkit';
import cashbackReducer from '../features/cashback/cashbackSlice';
import bankReducer from '../features/banking/bankSlice';
import userReducer from '../features/user/userSlice';
import registerReducer from '../features/register/registerSlice';
import storesReducer from '../features/stores/storesSlice';
import transactionsReducer from '../features/transactions/transactionsSlice';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import loadingReducer from '../features/loading/loadingSlice';
import dialogReducer from '../features/dialog/dialogSlice';
import alertReducer from '../features/alerts/alertSlice';
import initialDataReducer from '../features/initialData/initialDataSlice'; // Import the initialData reducer
import menuReducer from '../features/slices/menu';
import verificationReducer from '../features/verification/verificationInfoSlice';

const rootReducer = combineReducers({
    cashback: cashbackReducer,
    bank: bankReducer,
    user: userReducer,
    register: registerReducer,
    stores: storesReducer,
    transactions: transactionsReducer,
    dashboard: dashboardReducer,
    loading: loadingReducer,
    dialog: dialogReducer,
    alert: alertReducer,
    initialData: initialDataReducer, 
    menu: menuReducer,
    verificationInfo: verificationReducer,
});

export default rootReducer;
