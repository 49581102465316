import { Box } from "@mui/material";
import EmployeesListCardUI from "../employeesListCardUI";
import EmployeeManagementCardUI from "../employeeManagementCardUI";

const CardsUIContainer = ({ handleOpenDeleteDialog, employeeConstant }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        width: "100%",
        "@media (max-width: 768px)": {
          flexDirection: "column",
        },
      }}
    >
      <EmployeesListCardUI
        handleOpenDeleteDialog={handleOpenDeleteDialog}
        employeeConstant={employeeConstant}
      />
      <EmployeeManagementCardUI />
    </Box>
  );
};

export default CardsUIContainer;
