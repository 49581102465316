// KYCPage.js

import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import KYCPageStart from './start/main';
import VerificationSuccess from './verified/main';
import VerificationInProcess from './status/main';
import VerificationPending from './documentPending/main';
import VerificationFailed from './denied/main';
import DocumentMaster from './documents/combined/page/main';
import RetryMaster from './retry/combined/page/main';
import { selectUserStatus } from '../../../../features/user/userSelectors';
import { useSelector } from 'react-redux';

const KYCPage = () => {
  const currentState = useSelector(selectUserStatus);

  // Render the component based on the current state
  const renderComponent = () => {
    switch (currentState) {
      case 'retry':
        return <RetryMaster />;
      case 'verify':
        return <VerificationInProcess />;
      case 'document':
        return <DocumentMaster />;
      case 'verifyPending':
      case 'documentpending':
        return <VerificationPending />;
      case 'verifySuspended':
        return <VerificationFailed />;
      case 'complete':
        return <VerificationSuccess />;
      default:
        return <KYCPageStart />;
    }
  };

  // Set the title based on the current state
  const getTitle = () => {
    switch (currentState) {
      case 'retry':
        return 'We Need to Verify Your Information Again';
      case 'document':
        return 'Please Provide the Required Documents';
      case 'verifyPending':
      case 'documentpending':
        return 'Your Verification is Being Processed';
      case 'verifySuspended':
        return 'Verification Unsuccessful';
      case 'complete':
        return 'Your Verification is Complete';
      default:
        return 'Let’s Start Your Verification';
    }
  };

  // Set the subtitle based on the current state
  const getSubtitle = () => {
    switch (currentState) {
      case 'retry':
        return 'There was an issue with your previous submission. Please review and update your information to proceed.';
      case 'document':
        return 'Upload clear and legible copies of your identification and business documents to complete the verification process.';
      case 'verifyPending':
      case 'documentpending':
        return 'Our team is reviewing your submitted information. This process typically takes a few minutes. Thank you for your patience.';
      case 'verifySuspended':
        return 'Unfortunately, we couldn’t verify your information. Please contact our support team for further assistance.';
      case 'complete':
        return 'Your account has been successfully verified. You can now enjoy all the features and services we offer.';
      default:
        return 'To comply with regulatory requirements, we need to verify your identity and business information.';
    }
  };

  return (
    <Box
      sx={{
        p: { xs: 3, md: 6 },
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {/* Header Section */}
      <Box sx={{ textAlign: 'center', maxWidth: 700, mb: { xs: 4, md: 6 } }}>
        <Typography
          variant="h1"
          gutterBottom
          sx={{
            fontWeight: 'bold',
            color: '#333333',
            fontSize: { xs: '2.5rem', sm: '3rem', md: '3.5rem' },
            mb: 1,
          }}
        >
          {getTitle()}
        </Typography>
        <Typography
          variant="h5"
          color="textSecondary"
          sx={{ fontSize: { xs: '1.125rem', sm: '1.25rem', md: '1.5rem' }, mb: 3 }}
        >
          {getSubtitle()}
        </Typography>
        <Divider sx={{ mb: { xs: 4, md: 6 } }} />
      </Box>

      {/* Render the appropriate component based on the state */}
      {renderComponent()}
    </Box>
  );
};

export default KYCPage;
