// third-party
import { FormattedMessage } from 'react-intl';

// assets
import DashboardIcon from '@mui/icons-material/Dashboard';

// Icons constant
const icons = { DashboardIcon };

// ==============================|| MENU ITEMS - MAIN ||============================== //
const dashboard = {
    id: 'dashboard',
    title: <FormattedMessage id="dashboard" />,
   
    type: 'group',
    children: [
        {
            id: 'main',
            title: <FormattedMessage id="Main" />,
            type: 'item',
            url: '/dashboard',
            icon: icons.DashboardIcon,
            breadcrumbs: false
        },
       
    ]
};

export default dashboard;
