import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';

const LoadingResubmittedInfo = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '200px',
        textAlign: 'center',
      }}
    >
      <ReplayIcon color="primary" sx={{ fontSize: 60, mb: 2 }} />
      <CircularProgress />
      <Typography variant="h6" sx={{ mt: 2 }}>
        Resubmitting your information...
      </Typography>
    </Box>
  );
};

export default LoadingResubmittedInfo;
