import React from 'react';
import { Box, Typography, Button, useTheme } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const AccountStatusComponent = ({ onPress, status = 'active' }) => {
  const isActive = status.toLowerCase() === 'active';
  const theme = useTheme();

  return (
    <Box
      sx={(theme) => ({
        ...styles.container,
        [theme.breakpoints.down('sm')]: styles.containerMobile,
      })}
      role="group"
      aria-label={`Account is ${isActive ? 'active' : 'inactive'}`}
    >
      <Box
        sx={(theme) => ({
          ...styles.statusBox,
          [theme.breakpoints.down('sm')]: styles.statusBoxMobile,
        })}
      >
        {isActive ? (
          <CheckCircleIcon
            sx={(theme) => ({
              ...styles.icon,
              color: '#1BC575',
              [theme.breakpoints.down('sm')]: styles.iconMobile,
            })}
          />
        ) : (
          <CancelIcon
            sx={(theme) => ({
              ...styles.icon,
              color: '#b0bec5',
              [theme.breakpoints.down('sm')]: styles.iconMobile,
            })}
          />
        )}
        <Typography
          variant="body1"
          sx={(theme) => ({
            ...styles.statusText,
            [theme.breakpoints.down('sm')]: styles.statusTextMobile,
          })}
        >
          {isActive ? 'Account Activated' : 'Account Inactive'}
        </Typography>
      </Box>

      <Button
        variant={isActive ? 'contained' : 'outlined'}
        color={isActive ? 'primary' : 'secondary'}
        onClick={onPress}
        sx={(theme) => ({
          ...(isActive ? styles.activeButton : styles.inactiveButton),
          [theme.breakpoints.down('sm')]: isActive
            ? styles.activeButtonMobile
            : styles.inactiveButtonMobile,
        })}
        aria-label={isActive ? 'See account details' : 'Activate account'}
      >
        {isActive ? 'Details' : 'Activate'}
      </Button>
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '8px 16px',
    backgroundColor: '#ffffff',
    border: '1px solid #e0e0e0',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '320px',
  },
  containerMobile: {
    padding: '4px 8px',
    maxWidth: '100%',
  },
  statusBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  statusBoxMobile: {
    gap: '4px',
  },
  icon: {
    fontSize: '24px',
  },
  iconMobile: {
    fontSize: '18px',
  },
  statusText: {
    fontSize: '1rem',
    fontWeight: 500,
    color: '#333333',
  },
  statusTextMobile: {
    fontSize: '0.875rem',
  },
  activeButton: {
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '0.875rem',
  },
  activeButtonMobile: {
    fontSize: '0.75rem',
    padding: '4px 6px',
    minWidth: 'auto',
  },
  inactiveButton: {
    textTransform: 'none',
    fontWeight: 600,
    fontSize: '0.875rem',
    borderColor: '#b0bec5',
    color: '#b0bec5',
    '&:hover': {
      borderColor: '#90a4ae',
      backgroundColor: 'rgba(176, 190, 197, 0.04)',
    },
  },
  inactiveButtonMobile: {
    fontSize: '0.75rem',
    padding: '4px 6px',
    minWidth: 'auto',
  },
};

export default AccountStatusComponent;