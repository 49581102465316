import React, { useState } from "react";
import { useTransition, animated } from "react-spring";

const useAuthCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const transitions = useTransition(activeIndex, {
    key: activeIndex,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    config: { duration: 500 },
  });

  React.useEffect(() => {
    const timer = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 2000);
    return () => clearInterval(timer);
  }, []);

  const slides = [
    {
      title: "Swipe Fees as In-Store Rewards",
      description:
        "Instead of Paying Swipe Fees to Big Banks and Networks like Visa and Mastercard, You Can Invest Those Swipe Fees into Cashback That Customers Can Only Use in Your Store.",
    },
    {
      title: "Boost Retention with Store Cashback",
      description: "Cashback to Your Store Will Foster Retention.",
    },
    {
      title: "Pay Cashback Only for Proven Retention",
      description:
        "You Only Pay the Cashback When the Customer Comes Back, So You Only Pay When Retention Works.",
    },
    {
      title: "100% Free—No Charges At All!",
      description: "The Best Part: We Don’t Charge Anything—Completely Free!",
    },
  ];

  return {
    transitions,
    animated,
    slides,
    activeIndex,
  };
};

export default useAuthCarousel;
