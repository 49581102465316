import React from 'react';
import VerificationDialog from '../../ui/organisms/dialog/main';
import CombinedFunctionality from '../../functional/organism/Functionality';

function DialogCombined(props) {
  const {
    email,
    password,
    profilePk,
    open,
    setProfilePk,
    navigate,

    handleCloseCodeDialog,
  } = props;

  const {
    isLoadingCode,
    isLoadingAccount,
    isResendingCode,
    errorMessage,
    verificationStep,
    codeValues,
    inputRefs,
    handleChange,
    handleKeyDown,
    resendCode,
    handleGoToDashboard,
    isSuccess,
  } = CombinedFunctionality({
    email,
    password,
    profilePk,
    setProfilePk,
    navigate,
  
    handleCloseCodeDialog,
  });

  return (
    <VerificationDialog
      email={email}
      isLoadingCode={isLoadingCode}
      isLoadingAccount={isLoadingAccount}
      errorMessage={errorMessage}
      onResendCode={resendCode}
      open={open}
      onClose={handleCloseCodeDialog}
      isSuccess={isSuccess}
      codeLength={6}
      onGoToDashboard={handleGoToDashboard}
      inputRefs={inputRefs}
      codeValues={codeValues}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      isResendingCode={isResendingCode}
    />
  );
}

export default DialogCombined;
