import { useCallback, useState } from 'react';

// material-ui
import { Avatar, Box, Tooltip } from '@mui/material';

// assets
import { IconArrowsMaximize, IconArrowsMinimize } from '@tabler/icons-react';

// ==============================|| HEADER CONTENT - FULLSCREEN ||============================== //

const FullScreen = () => {
    const [open, setOpen] = useState(false);

    const handleToggle = useCallback(() => {
        setOpen((prevOpen) => !prevOpen);
        if (document && !document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
        }
    }, []);

    // Define your color palette here
    const colors = {
        apple: {
            background: '#f0f0f0',
            border: '#d1d1d1',
            hoverBackground: '#e0e0e0',
            color: '#333333',
        },
        google: {
            background: '#4285F4',
            border: '#357AE8',
            hoverBackground: '#3367D6',
            color: '#ffffff',
        },
    };

    // Choose which color palette to use
    const selectedColor = open ? colors.google : colors.apple;

    return (
        <Box sx={{ ml: 2, mr: 2 }}>
            <Tooltip title={open ? 'Exit Fullscreen' : 'Fullscreen'}>
                <Avatar
                    variant="rounded"
                    sx={{
                        width: 40,
                        height: 40,
                        border: `1px solid ${selectedColor.border}`,
                        backgroundColor: selectedColor.background,
                        color: selectedColor.color,
                        transition: 'all 0.2s ease-in-out',
                        cursor: 'pointer',
                        '&:hover': {
                            borderColor: colors.google.border,
                            backgroundColor: colors.google.hoverBackground,
                            color: colors.google.color,
                        },
                    }}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="inherit"
                >
                    {open ? <IconArrowsMinimize /> : <IconArrowsMaximize />}
                </Avatar>
            </Tooltip>
        </Box>
    );
};

export default FullScreen;
