import React from "react";
import { Grid, Typography, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const InfoListUI = ({ data, onItemClick, formatValue }) => {
  return (
    <>
      {/* Iterate over each entry in the data object */}
      {Object.entries(data).map(([displayName, details]) => (
        <Grid container key={displayName} spacing={2} alignItems="center">
          {/* Display the field name */}
          <Grid item xs={4} sx={{ textAlign: "left" }}>
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {displayName}:
            </Typography>
          </Grid>
          {/* Display the field value */}
          <Grid item xs={5}>
            <Typography variant="body2">{formatValue(details)}</Typography>
          </Grid>
          {/* Display the edit button */}
          <Grid item xs={3} display="flex" justifyContent="flex-end">
            <IconButton
              onClick={() => onItemClick({ [displayName]: details })}
              color="primary"
              size="large"
            >
              <EditIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default InfoListUI;
