import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Button, CardActions, CardContent, Divider, Grid, Typography } from '@mui/material';
import { useSelector } from "react-redux";
import {
  selectTransactions,

} from "../../../../../features/transactions/transactionsSelectors";
// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonPopularCard from 'ui-component/cards/Skeleton/PopularCard';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';
// Fake transaction data (leave empty to test no-transaction message)
// const transactions = [
//     { name: 'John Doe', date: '2024-01-01', amount: '$500.00', processing_status: 'success' },
//     { name: 'Jane Smith', date: '2024-01-05', amount: '$750.00', processing_status: 'failed' },
//     { name: 'Alice Johnson', date: '2024-01-08', amount: '$200.00', processing_status: 'success' },
//     { name: 'Bob Brown', date: '2024-01-10', amount: '$1,200.00', processing_status: 'success' },
//     { name: 'Charlie Green', date: '2024-01-12', amount: '$450.00', processing_status: 'failed' },
//     { name: 'Daisy White', date: '2024-01-15', amount: '$900.00', processing_status: 'success' },
//     { name: 'Edward Black', date: '2024-01-18', amount: '$600.00', processing_status: 'failed' }
// ];

// ==============================|| DASHBOARD DEFAULT - ALL TRANSACTIONS CARD ||============================== //

const AllTransactionsCard = ({ isLoading }) => {
    const transactions = useSelector(selectTransactions);
    const theme = useTheme();

    return (
        <>
            {isLoading ? (
                <SkeletonPopularCard />
            ) : (
                <MainCard content={false} sx={{ minHeight: '688px', display: 'flex', flexDirection: 'column' }}>
                    <CardContent sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container alignContent="center" justifyContent="space-between">
                                    <Grid item>
                                        <Typography variant="h4">All Transactions</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Transactions List */}
                            {transactions.length > 0 ? (
                                transactions.slice(0, 8).map((transaction, index) => (
                                    <Grid item xs={12} key={index}>
                                        <Grid container alignItems="center" justifyContent="space-between">
                                            <Grid item>
                                                <Typography variant="subtitle1" color="inherit">
                                                    {transaction.name}
                                                </Typography>
                                                <Typography variant="caption" color="textSecondary">
                                                    {transaction.date}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Grid container alignItems="center">
                                                    <Typography variant="subtitle1" color="inherit">
                                                        {transaction.amount}
                                                    </Typography>
                                                    <Avatar
    variant="rounded"
    sx={{
        width: 16,
        height: 16,
        borderRadius: '5px',
        backgroundColor:
            transaction.processing_status === 'SUCCESS'
                ? theme.palette.success.light
                : transaction.processing_status === 'FAILED'
                ? theme.palette.error.light
                : theme.palette.warning.light, // Fallback for PENDING
        color:
            transaction.processing_status === 'SUCCESS'
                ? theme.palette.success.dark
                : transaction.processing_status === 'FAILED'
                ? theme.palette.error.dark
                : theme.palette.warning.dark, // Fallback for PENDING
        ml: 2
    }}
>
    {transaction.processing_status === 'SUCCESS' ? (
        <CheckCircleOutlineIcon fontSize="small" />
    ) : transaction.processing_status === 'FAILED' ? (
        <CancelOutlinedIcon fontSize="small" />
    ) : (
        <HourglassEmptyOutlinedIcon fontSize="small" />
    )}
</Avatar>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {index < transactions.length - 1 && <Divider sx={{ my: 1.5 }} />}
                                    </Grid>
                                ))
                            ) : (
                                <Grid item xs={12} sx={{ textAlign: 'center', mt: 2 }}>
                                    <Avatar
                                        sx={{
                                            backgroundColor: theme.palette.grey[200],
                                            color: theme.palette.text.secondary,
                                            width: 56,
                                            height: 56,
                                            margin: '0 auto'
                                        }}
                                    >
                                        <ReceiptLongOutlinedIcon fontSize="large" />
                                    </Avatar>
                                    <Typography variant="subtitle1" sx={{ mt: 1.5 }}>
                                        No transactions
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Set up Clavaa to keep your customers coming back
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>

                    <CardActions sx={{ p: 1.25, pt: 0, justifyContent: 'center' }}>
                        <Button size="small" disableElevation>
                            View All
                            <ChevronRightOutlinedIcon />
                        </Button>
                    </CardActions>
                </MainCard>
            )}
        </>
    );
};

AllTransactionsCard.propTypes = {
    isLoading: PropTypes.bool
};

export default AllTransactionsCard;
