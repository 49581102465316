import React from "react";
import DocumentUploadFieldUIContainer from "../organisms/documentUploadFieldUIContainer";
import DocumentManagerUIContainer from "../organisms/documentManagerUIContainer";
import useDocument from "../../functional/page/useDocument";
import DocumentUploadFieldUI from "../../ui/organisms/documentUploadFieldUI";
import DocumentManagerUI from "../../ui/organisms/documentManagerUI";

const DocumentPage = ({onBack}) => {
  const {
    documentDetails,
    setSelectedDocument,
    handleDocumentUpload,
    type,
    setType,
    setFile,
    submittedImage,

    onDocumentUpload,
    isAllDocumentsUploaded,
    documentList,
    documentTypeFieldOnClick,
    documentUploadFieldOnClick,
    handleFileChange,
    documentName,
  } = useDocument();
  if (documentDetails) {
    return (
      <DocumentUploadFieldUI
        documentDetails={documentDetails}
        setSelectedDocument={setSelectedDocument}
        handleDocumentUpload={handleDocumentUpload}
        type={type}
        setType={setType}
        setFile={setFile}
        submittedImage={submittedImage}
        documentUploadFieldOnClick={documentUploadFieldOnClick}
        documentTypeFieldOnClick={documentTypeFieldOnClick}
        handleFileChange={handleFileChange}
        documentName={documentName}
      />
    );
  }

  return (
    <DocumentManagerUI

      onDocumentUpload={onDocumentUpload}
      isAllDocumentsUploaded={isAllDocumentsUploaded}
      documentList={documentList}
      setSelectedDocument={setSelectedDocument}
      onBack={onBack}
    />
  );
};

export default DocumentPage;
