import React from "react";
import AuthPageTwo from "../../UI/pages/mainTwo";
import useAuthCarousel from "../../Functional/molecule/useAuthCarousel";

const SignInPageContainerTwo = ({ children }) => {
  const { transitions, animated, slides, activeIndex } = useAuthCarousel();
  return (
    <AuthPageTwo
      transitions={transitions}
      animated={animated}
      slides={slides}
      activeIndex={activeIndex}
    >
      {children}
    </AuthPageTwo>
  );
};

export default SignInPageContainerTwo;
