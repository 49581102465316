import React from "react";
import CustomTable from "../../../../components/molecules/tables/transactions";
import { Box, Typography, Divider } from "@mui/material";
import PageHeader from "../../../../components/molecules/pageHeader/PageHeader";

// Function to format currency in "$00.00" format
const formatCurrency = (value) => {
  return `$${parseFloat(value).toFixed(2)}`;
};

const TransactionsTable = ({
  data,
  loading,
  rowsPerPage,
  totalRows,
  page,
  handlePageChange,
}) => {
  // Preprocess data to format currency fields
  const formattedData = data.map((item) => ({
    ...item,
    amount: formatCurrency(item.amount),
    cashbackEarned: formatCurrency(item.cashbackEarned),
    cashbackRedeemed: formatCurrency(item.cashbackRedeemed),
  }));

  // Define columns without changing the keys
  const columns = [
    { title: "Customer", key: "name" },
    { title: "Invoice ID", key: "id" },
    { title: "Date", key: "date" },
    { title: "Amount", key: "transaction_amount" },
    { title: "Cashback Earned", key: "cashbackEarned" },
    { title: "Cashback Redeemed", key: "cashbackRedeemed" },
    { title: "Status", key: "processingStatus" },
    { title: "Register", key: "register" },
  ];

  return (
    <Box >


      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
          alignItems: "center",
        }}
      >
        <PageHeader
          title={"Transactions"}
          description={
            "See and manage all of your transactions here."
          }
          backArrow={false}
         
        
        />
      <CustomTable
        title="Consumer Transactions"
        columns={columns}
        data={formattedData} // Pass formatted data here
        loading={loading}
        rowsPerPage={rowsPerPage}
        totalRows={totalRows}
        page={page}
        handlePageChange={handlePageChange}
        showPagination={true}
      />
      </Box>
    </Box>
  );
};

export default TransactionsTable;
