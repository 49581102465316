import { createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';

const initialState = {
  cashbackHistory: [],
  currentCashback: 0,
  // structure of cashbackDetails
// "current_cashback": current_cashback,
// "current_gold": current_gold,
// "current_gold_required_spend": current_gold_required_spend,
// "last_gold": last_gold,
// "last_gold_required_spend": last_gold_required_spend,
//  "next_gold": next_gold,
// "next_gold_required_spend": next_gold_required_spend,
// "next_general_cashback_rate": next_general_cashback_rate,

  cashbackDetails: {},
  isLoading: false,
  error: null,
  success: false,
};

const cashbackSlice = createSlice({
  name: 'cashback',
  initialState,
  reducers: {
    addCashbackHistory: (state, action) => {
      state.cashbackHistory.push(action.payload);
    },
    setNextGold: (state, action) => {
      set(state.cashbackDetails, 'next_gold', action.payload);
    },
    setNextGoldRequiredSpend: (state, action) => {
      set(state.cashbackDetails, 'next_gold_required_spend', action.payload);
    },
    setNextGeneralCashbackRate: (state, action) => {
      set(state.cashbackDetails, 'next_general_cashback_rate', action.payload);
    },
    setCashbackDetails: (state, action) => {
      state.cashbackDetails = action.payload;
    },
    setCashbackHistory: (state, action) => {
      state.cashbackHistory = action.payload;
    },
    setCurrentCashback: (state, action) => {
      state.currentCashback = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
  },
});

export const {
  addCashbackHistory,
  setCurrentCashback,
  setCashbackHistory,
  setLoading,
  setError,
  setSuccess,
  setCashbackDetails,
  setNextGold,
  setNextGoldRequiredSpend,
  setNextGeneralCashbackRate,
  
  
} = cashbackSlice.actions;

export default cashbackSlice.reducer;
