import React from "react";
import {
  Grid,
  CssBaseline,
  ThemeProvider,
  createTheme,
  Box,
  Typography,
  Link,
} from "@mui/material";

import Header from "../../molecules/headers/header";
import GeneralButton from "../../atoms/buttons/GeneralButton";
import StepIndicator from "../../molecules/progress/StepIndicator";

const theme = createTheme(); // Customize your theme here

const PageLayout = ({
  children,
  sideImageUrl,
  currentStep,
  handleSubmit,
  
  description,
  title,
  onBack,
  backArrow,
  signIn,
  disabled = false, 
}) => {
 

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Grid container component="main" sx={{ height: "100vh" }}>
        <Grid item xs={false} sm={4} md={7}>
          <Box
            sx={{
              position: "fixed", // This will fix the position relative to the viewport
              width: { sm: "40%", md: "60%" }, // Adjust the width to match your Grid layout
              height: "100vh",
              boxSizing: "border-box",
              backgroundImage: `url(${sideImageUrl})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              top: 0, // Position at the top
              left: 0, // Align to the left side
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          md={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            p: 4,
            maxWidth: 600,
            mx: "auto",
            width: "100%",
          }}
        >
          <Box
            sx={{
              maxWidth: 500,
              width: "100%",
              mx: "auto",
              textAlign: "center",
            }}
          >
            <Header
              title={title}
              description={description}
              backArrow={backArrow}
              onBackClick={onBack}
            />
            {children}
            {handleSubmit && (
              <GeneralButton
                label="Submit"
                onClick={handleSubmit}
                type="contained"
                expandable={true}
                disabled={disabled}
                center={true}
              />
            )}
          
            {currentStep !== undefined && (
              <StepIndicator currentStep={currentStep} sx={{ mt: 4 }} />
            )}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default PageLayout;
