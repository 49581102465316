// DarkGreenTextButton.js
import React from "react";
import Button from "@mui/material/Button";

const PlainTextButton = ({ label, onClick, disabled, importedSx, fullWidth }) => {
  return (
    <Button
      onClick={onClick}
      variant="text"
      disabled={disabled}
      fullWidth={fullWidth}
      

      sx={{
       
        fontFamily: "SF Pro Display, Arial, sans-serif",
        fontSize: "0.875rem",
        fontWeight: 500,
        textTransform: "none",
        display: "block",
        color: "#3da843", // Darker green color for text
        
        "&:hover": {
          color: "#2f953a", // Slightly darker shade for hover
          backgroundColor: "transparent", // No background change
        },
        "&:disabled": {
          color: "#aeaeae", // Disabled state color
        },
        mt: 1,
        mb: 1,
        ...importedSx,
      }}
    >
      {label}
    </Button>
  );
};

export default PlainTextButton;
