import { createSlice } from '@reduxjs/toolkit';

export const alertSlice = createSlice({
  name: 'alert',
  initialState: {
    info: { isOpen: false, message: '' },
    success: { isOpen: false, message: '' },
    warning: { isOpen: false, message: '' },
    error: { isOpen: false, message: '' },
  },
  reducers: {
    showAlert: (state, action) => {
      const { type, message } = action.payload;
      state[type] = { isOpen: true, message };
    },
    hideAlert: (state, action) => {
      const type = action.payload;
      state[type] = { isOpen: false, message: '' };
    },
  },
});

export const { showAlert, hideAlert } = alertSlice.actions;

export default alertSlice.reducer;
