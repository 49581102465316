import React, { useEffect } from "react";
import { TextField } from "@mui/material";

const SSNFormattedTextField = ({
  label,
  value,
  onChange,
  name,
  error,
  errorMessageAndhelp,
  onFocus,
  multiline = false,
  rows,
  commonStyles,
  fullSSN = false, // Prop to determine if full SSN is required
}) => {


    useEffect(() => {
        if (!fullSSN) {
          // Truncate to the last 4 digits if fullSSN is false
          
          const truncatedValue = value.slice(-4);
     
          onChange({ target: { name, value: truncatedValue } });
        } 
      }, [fullSSN]);


  const handleSSNChange = (e) => {
    const inputValue = e.target.value;
    let cleanedValue = inputValue.replace(/[^0-9]/g, '');
    let formattedValue = '';

    if (fullSSN) {
      // Format the input value to nnn-nn-nnnn
      if (cleanedValue.length <= 3) {
        formattedValue = cleanedValue;
      } else if (cleanedValue.length <= 5) {
        formattedValue = `${cleanedValue.slice(0, 3)}-${cleanedValue.slice(3)}`;
      } else {
        formattedValue = `${cleanedValue.slice(0, 3)}-${cleanedValue.slice(3, 5)}-${cleanedValue.slice(5, 9)}`;
      }
    } else {
      // Allow only 4 digits for partial SSN
      formattedValue = cleanedValue.slice(0, 4);
    }

    onChange({ target: { name, value: formattedValue } });
  };

  return (
    <TextField
      label={label}
      variant="outlined"
      value={value}
      name={name}
      onChange={handleSSNChange}
      onFocus={onFocus}
      fullWidth
      error={!!error}
      helperText={errorMessageAndhelp}
      multiline={multiline}
      rows={multiline ? rows : undefined}
      sx={commonStyles}
    />
  );
};

export default SSNFormattedTextField;
