import { Box, Checkbox, Typography } from "@mui/material";
import GeneralInput from "../../../../components/atoms/inputs/GeneralInput";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import GeneralButton from "../../../../components/atoms/buttons/GeneralButton";
import GoogleImage from "../../../../images/google-icon.png";
import { Link } from "react-router-dom";
import { useState } from "react";

import VerificationDialog from "../../../signUp/email/ui/organisms/dialog/main";
import CombinedFunctionality from "../../combined/organisms/Functionality";

const SignUpFormUI = () => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState({ email: "", password: "" });
  const { isLoadingCode,
    isLoadingAccount,
    isResendingCode,
    isSuccess,
    errorMessage,
    codeValues,
    inputRefs,
    handleChange,
    handleKeyDown,
    resendCode,
    confirmCode,
    resetCode,
    handleGoToDashboard,
    handleCloseCodeDialog,
    handleEmailSubmit,
    isCodeDialogOpen,
    handleGoogleSignUp,
} = CombinedFunctionality({setError});

  const handleEmailChange = (value) => {
    setEmail(value);
    if (!/^\S+@\S+\.\S+$/.test(value)) {
      setError((prev) => ({ ...prev, email: "Must be a valid email address" }));
    } else {
      setError((prev) => ({ ...prev, email: "" }));
    }
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
    if (
      value.length < 12 ||
      !/[a-z]/.test(value) ||
  
      !/[0-9]/.test(value) ||
      !/[@$!%*?&#]/.test(value)
    ) {
      setError((prev) => ({
        ...prev,
        password:
          "Password must be at least 12 characters long, and include an uppercase letter, lowercase letter, number, and special character",
      }));
    } else {
      setError((prev) => ({ ...prev, password: "" }));
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <>
     <VerificationDialog
      email={email}
      isLoadingCode={isLoadingCode}
      isLoadingAccount={isLoadingAccount}
      errorMessage={errorMessage}
      onResendCode={resendCode}
      open={isCodeDialogOpen}
      onClose={handleCloseCodeDialog}
      isSuccess={isSuccess}
      codeLength={6}
      onGoToDashboard={handleGoToDashboard}
      inputRefs={inputRefs}
      codeValues={codeValues}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      isResendingCode={isResendingCode}
    />
    <Box
      sx={{
        backgroundColor: "white",
        padding: "2rem",
        borderTopLeftRadius: "2rem",
        borderTopRightRadius: "2rem",
        width: {
          lg: "60%",
          md: "100%",
        },
      }}
    >
      <Box>
        <Typography
          variant="h5"
          sx={{
            color: "primary.dark",
            fontSize: "2.5rem",
            fontWeight: "800",
            fontFamily: "Epilogue, sans-serif",
          }}
          gutterBottom
        >
          Clavaa
        </Typography>
        <Typography
          variant="h3"
          sx={{
            fontSize: "1.5rem",
            fontWeight: "700",
            fontFamily: "Epilogue, sans-serif",
            lineHeight: "2rem",
          }}
          gutterBottom
        >
          Sign up to access your Clavaa Dashboard
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            lineHeight: "1.5",
            fontFamily: "Epilogue, sans-serif",
          }}
          gutterBottom
        >
          Your email and password secure your account and transactions.
        </Typography>
      </Box>
      <Box>
        <Box sx={{ height: 85 }}>
          <GeneralInput
            type={"text"}
            label="Email"
            name={"email"}
            error={error.email}
            errorMessageAndhelp={error.email}
            commonStyles={{
              marginTop: "2rem",
            }}
            onChange={(e) => handleEmailChange(e.target.value)}
          />
        </Box>
        <Box sx={{ height: 110 }}>
          <GeneralInput
            type={"password"}
            label="Password"
            error={error.password}
            errorMessageAndhelp={error.password}
            name={"password"}
            commonStyles={{
              marginTop: "2rem",
            }}
            onChange={(e) => handlePasswordChange(e.target.value)}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            margin: "1.5rem 0 2rem 0",
          }}
        >
          <Checkbox
            sx={{
              padding: "0",
              marginRight: "8px",
              color: "#36B266",
              "&.Mui-checked": {
                color: "#36B266",
              },
            }}
            onChange={handleCheckboxChange}
          />
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              lineHeight: "1.5",
              fontFamily: "Epilogue, sans-serif",
            }}
          >
            By checking this box, you agree to our{" "}
            <Link
              to="https://clavaa.com/business-terms-of-service/"
              target="_blank"
              underline="hover"
              style={{ color: "#36B266" }}
            >
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link
              to="https://clavaa.com/privacy-policy/"
              target="_blank"
              underline="hover"
              style={{ color: "#36B266" }}
            >
              Privacy Policy
            </Link>
            , as well as our partner Dwolla’s{" "}
            <Link
              to="https://www.dwolla.com/legal/tos/"
              target="_blank"
              underline="hover"
              style={{ color: "#36B266" }}
            >
              Terms of Service
            </Link>{" "}
            and{" "}
            <Link
              to="https://www.dwolla.com/legal/privacy/"
              target="_blank"
              underline="hover"
              style={{ color: "#36B266" }}
            >
              Privacy Policy
            </Link>
            .
          </Typography>
        </Box>
        <GeneralButton
          label={
            <>
              Sign Up <ArrowOutwardOutlinedIcon style={{ marginLeft: "8px" }} />
            </>
          }
          onClick={ () => handleEmailSubmit(email, password) }
          type="circularIcon"
          variant="outlined"

          disabled={!!error.email || !!error.password || !isChecked}
          sx={{
            width: "100%",
            margin: "auto",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "2rem 0",
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderBottomColor: "secondary.light",
            flex: 1,
          }}
        />
        <Typography
          variant="body1"
          sx={{ fontSize: "1rem", lineHeight: "1.5", margin: "0 1rem" }}
        >
          or
        </Typography>
        <Box
          sx={{
            borderBottom: 1,
            borderBottomColor: "secondary.light",
            flex: 1,
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: 1,
          borderColor: "secondary.light",
          borderRadius: 50,
          p: 1.5,
          cursor: "pointer",
        }}
        onClick={() => handleGoogleSignUp(isChecked)}
      >
        <Box
          component="img"
          src={GoogleImage}
          alt="GoogleImage"
          sx={{
            mr: 2,
          }}
        />
        <Typography
          variant="body1"
          sx={{
            fontSize: "0.8rem",
            lineHeight: "1.5",
            fontWeight: "500",
            fontFamily: "Epilogue, sans-serif",
          }}
        >
          Sign Up with Google
        </Typography>
      </Box>
      <Box
        sx={{ marginTop: "2rem", display: "flex", justifyContent: "center" }}
      >
        <Typography
          variant="body1"
          sx={{
            fontSize: "1rem",
            lineHeight: "1.5",
            fontWeight: "400",
            marginRight: "0.5rem",
            fontFamily: "Epilogue, sans-serif",
          }}
        >
          Already have an account?
        </Typography>
        <Link to="/sign-in" style={{ textDecoration: "none" }}>
          <Typography
            variant="body1"
            sx={{
              fontSize: "1rem",
              lineHeight: "1.5",
              fontWeight: "900",
              cursor: "pointer",
              color: "primary.main",
              fontFamily: "Epilogue, sans-serif",
            }}
          >
            Sign In
          </Typography>
        </Link>
      </Box>
    </Box>
    </>
  );
};

export default SignUpFormUI;