import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Grid,
  IconButton,
} from '@mui/material';
import {
  AttachMoney,
  People,
  VerifiedUser,
  LocationOn,
  LocalOffer,
  AccountBalance,
  VpnKey,
} from '@mui/icons-material';

const steps = [
  'Verify Your Business Details',
  'Set Up Location Details',
  'Set Your Cashback Offer',
  'Upload Bank Account Details',
  'Set Up Register Credentials',
];

const stepDescriptions = [
  'Required to comply with regulations and enable secure payment processing for your business.',
  'Ensure customers can easily find your store in our app. Complete information helps drive new traffic to your business.',
  'Higher cashback rates help drive customer retention. We recommend offering over 5% cashback.',
  'Securely receive payments directly to your account by uploading your bank details.',
  'Allow your employees to access the Clavaa POS add-on tablet with ease by setting up register credentials.',
];

const icons = [
  React.createElement(VerifiedUser, { style: { fontSize: '6rem' }, color: 'primary' }),
  React.createElement(LocationOn, { style: { fontSize: '6rem' }, color: 'primary' }),
  React.createElement(LocalOffer, { style: { fontSize: '6rem' }, color: 'primary' }),
  React.createElement(AccountBalance, { style: { fontSize: '6rem' }, color: 'primary' }),
  React.createElement(VpnKey, { style: { fontSize: '6rem' }, color: 'primary' }),
];

const OnboardingModal = ({ open, handleClose }) => {
  const [activeStep, setActiveStep] = useState(-1);

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      handleClose(); // Close the modal after the last step
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === -1) {
      handleClose(); // Close the modal if back is pressed on the welcome screen
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const renderWelcomeContent = () => {
    return React.createElement(
      Box,
      { textAlign: 'center', px: 4, py: 4 },
      React.createElement(
        Typography,
        { style: { fontSize: '2.5rem', fontWeight: 'bold', color: '#0EAD69' }, gutterBottom: true },
        'Welcome to Clavaa Merchant Services!'
      ),
      React.createElement(
        Box,
        { mt: 4 },
        React.createElement(
          Typography,
          { style: { fontSize: '1.8rem', fontWeight: 'bold', color: '#0EAD69' }, gutterBottom: true },
          'What You’ll Gain with Clavaa'
        ),
        React.createElement(
          Grid,
          { container: true, spacing: 4, justifyContent: 'center' },
          React.createElement(
            Grid,
            { item: true, xs: 12, sm: 6 },
            React.createElement(
              Box,
              { display: 'flex', flexDirection: 'column', alignItems: 'center' },
              React.createElement(
                IconButton,
                { disabled: true },
                React.createElement(AttachMoney, { style: { fontSize: '4rem' }, color: 'primary' })
              ),
              React.createElement(
                Typography,
                { style: { fontSize: '1.4rem', textAlign: 'center', marginTop: '10px' } },
                React.createElement('strong', null, 'No Swipe Fees'),
                React.createElement('br'),
                'Save on transaction costs and reinvest in growth through our cashback program.'
              )
            )
          ),
          React.createElement(
            Grid,
            { item: true, xs: 12, sm: 6 },
            React.createElement(
              Box,
              { display: 'flex', flexDirection: 'column', alignItems: 'center' },
              React.createElement(
                IconButton,
                { disabled: true },
                React.createElement(People, { style: { fontSize: '4rem' }, color: 'primary' })
              ),
              React.createElement(
                Typography,
                { style: { fontSize: '1.4rem', textAlign: 'center', marginTop: '10px' } },
                React.createElement('strong', null, 'Attract New Customers'),
                React.createElement('br'),
                'Gain visibility and drive more traffic to your business.'
              )
            )
          )
        )
      )
    );
  };

  const renderStepContent = () => {
    return React.createElement(
      Box,
      { textAlign: 'center', px: 4, py: 4 },
      React.createElement(
        Typography,
        { style: { fontSize: '2.2rem', fontWeight: 'bold', color: '#0EAD69' }, gutterBottom: true },
        `Step ${activeStep + 1}: ${steps[activeStep]}`
      ),
      React.createElement(
        Box,
        { mt: 2 },
        React.createElement(
          IconButton,
          { disabled: true },
          React.cloneElement(icons[activeStep])
        )
      ),
      React.createElement(
        Typography,
        { style: { fontSize: '1.4rem', marginTop: '20px' } },
        stepDescriptions[activeStep]
      )
    );
  };

  return React.createElement(
    Dialog,
    { open: open, fullWidth: true, maxWidth: 'md' },
    React.createElement(
      DialogContent,
      null,
      activeStep === -1 ? renderWelcomeContent() : renderStepContent(),
      // Show progress dots only on step screens
      activeStep >= 0 &&
        React.createElement(
          Box,
          { display: 'flex', justifyContent: 'center', mt: 4 },
          steps.map((_, index) =>
            React.createElement(Box, {
              key: index,
              width: 20,
              height: 20,
              borderRadius: '50%',
              bgcolor: index === activeStep ? 'primary.main' : 'grey.400',
              mx: 0.5,
            })
          )
        )
    ),
    React.createElement(
      DialogActions,
      { style: { justifyContent: 'center', paddingBottom: '24px' } },
      activeStep === -1
        ? React.createElement(
            Button,
            {
              onClick: handleNext,
              variant: 'contained',
              style: {
                fontSize: '1.2rem',
                padding: '14px 28px',
                backgroundColor: '#0EAD69',
                color: '#FFFFFF',
                borderRadius: '8px',
                textTransform: 'none',
              },
            },
            'See How to Get Started'
          )
        : [
            React.createElement(
              Button,
              {
                key: 'back',
                onClick: handleBack,
                style: {
                  fontSize: '1.2rem',
                  padding: '10px 20px',
                  textTransform: 'none',
                },
              },
              'Back'
            ),
            React.createElement(
              Button,
              {
                key: 'next',
                onClick: handleNext,
                variant: 'contained',
                style: {
                  fontSize: '1.2rem',
                  padding: '10px 20px',
                  backgroundColor: '#0EAD69',
                  color: '#FFFFFF',
                  borderRadius: '8px',
                  textTransform: 'none',
                },
              },
              activeStep === steps.length - 1 ? 'Finish' : 'Next'
            ),
          ]
    )
  );
};

export default OnboardingModal;
