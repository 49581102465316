import PropTypes from 'prop-types';
import React from 'react';

// material-ui
import { Avatar, Box, Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from "react-redux";
import {
    selectDashboardData,
  
  } from "../../../../../features/dashboard/dashboardSelectors";
// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonTotalOrderCard from 'ui-component/cards/Skeleton/EarningCard';

// assets
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const CardWrapper = styled(MainCard)({
    backgroundColor: '#FFFFFF', // White background for a clean look
    color: '#333', // Dark text for contrast
    overflow: 'hidden',
    position: 'relative',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)', // Subtle shadow for depth
    borderRadius: '12px',
});

// ==============================|| DASHBOARD - TOTAL TRANSACTIONS CARD ||============================== //

// Assume SkeletonTotalOrderCard, CardWrapper, and selectDashboardData are correctly imported

const TotalTransactionsCard = ({ isLoading }) => {
    const [timeValue, setTimeValue] = React.useState(false);
    const { transactions } = useSelector(selectDashboardData);
    const [transactionTotal, setTransactionTotal] = React.useState(0);
    console.log(transactions)

    const handleChangeTime = (event, newValue) => {
        setTimeValue(newValue);
        if (transactions) {
            console.log(typeof transactions); // Should print "object"
            console.log(transactions)
            console.log(transactions.year)
            console.log(transactions.month)
            const total = newValue ? transactions.month : transactions.year;
            setTransactionTotal(total || 0); // Fallback to 0 if the value is null or undefined
        }
    };

    // Set default to "Month" total on initial render
    React.useEffect(() => {
        if (transactions) {
            setTransactionTotal(transactions.month || 0);
        }
    }, [transactions]);

    console.log(transactionTotal)
    return (
        <>
            {isLoading ? (
                <SkeletonTotalOrderCard />
            ) : (
                <CardWrapper border={false} content={false}>
                    <Box sx={{ p: 2.25 }}>
                        <Grid container direction="column">
                            <Grid item>
                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                mt: 1,
                                                backgroundColor: '#F0F0F5', // Soft grey background for avatar
                                                color: '#606060', // Dark grey for icon
                                            }}
                                        >
                                            <AttachMoneyIcon fontSize="inherit" />
                                        </Avatar>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            disableElevation
                                            variant={timeValue ? 'contained' : 'text'}
                                            size="small"
                                            sx={{
                                                color: timeValue ? '#606060' : '#B0B0B0', // Active: medium grey, Inactive: light grey
                                                fontWeight: timeValue ? 'bold' : 'normal',
                                                borderRadius: '4px',
                                            }}
                                            onClick={(e) => handleChangeTime(e, true)}
                                        >
                                            Month
                                        </Button>
                                        <Button
                                            disableElevation
                                            variant={!timeValue ? 'contained' : 'text'}
                                            size="small"
                                            sx={{
                                                color: !timeValue ? '#606060' : '#B0B0B0',
                                                fontWeight: !timeValue ? 'bold' : 'normal',
                                                borderRadius: '4px',
                                            }}
                                            onClick={(e) => handleChangeTime(e, false)}
                                        >
                                            Year
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ mb: 0.75, mt: 1 }}>
                                <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, color: '#333' }}>
                                ${transactionTotal != null && !isNaN(transactionTotal) ? Number(transactionTotal).toFixed(2) : '0.00'}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 500,
                                        color: '#8E8E93',
                                    }}
                                >
                                    Total Transactions Amount ({timeValue ? 'Last Thirty Days' : 'Last Year'})
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

TotalTransactionsCard.propTypes = {
    isLoading: PropTypes.bool
};

export default TotalTransactionsCard;
