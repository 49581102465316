import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import CloseIcon from "@mui/icons-material/Close";
import MainLayout from "../../development/page/parts/layout/MainLayout";
import PageHeader from "../../components/molecules/pageHeader/PageHeader";
import LocationImage from "../../images/no-location.png";
import GeneralButton from "../../components/atoms/buttons/GeneralButton";
import locationImageOne from "../../images/location-one.png";
import locationImageTwo from "../../images/location-two.png";
import locationIcon from "../../images/location-icon.png";
import storeIcon from "../../images/store-icon.png";
import phoneIcon from "../../images/phone-icon.png";
import emailIcon from "../../images/email-icon.png";
import websiteIcon from "../../images/website-icon.png";

import DeleteDialog from "../../components/molecules/dialogues/delete";
import { useDispatch, useSelector } from "react-redux";
import { selectAllStoreLocations } from "../../features/stores/storesSelectors";
import LocationModal from "./AddLocationDialog";
import useFullFetchFeedback from "../../functions/apiFetchers/fullPageLoaders";
import { deleteStoreLocation } from "../../features/stores/storesSlice";

const LocationPage = () => {
  const locationList = useSelector(selectAllStoreLocations);
  console.log(locationList);
  const fetchFeedback = useFullFetchFeedback();
  const [steps, setSteps] = useState(1);
  const [open, setOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const [selectedLocationId, setSelectedLocationId] = useState(null);

  const [locationsExpanded, setLocationsExpanded] = useState([]);

  const toggleExpanded = (index) => {
    setLocationsExpanded((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index]; // Toggle the state at the given index
      return newState;
    });
  };
  useEffect(() => {
    if (locationList) {
      setLocationsExpanded(new Array(locationList.length).fill(false));
    }
  }, [locationList]);


  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedLocationId(null); // Reset the selected location ID
  };

  const handleDeleteClick = (locationId) => {
    setSelectedLocationId(locationId); // Store the location ID to delete
    setOpenDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    setOpenDeleteModal(false);
    await fetchFeedback({
      endpoint: `merchant/locations/${selectedLocationId}/`,
      loaderMessage: "Deleting location...",
      successMessage: "Location Deleted successfully!",
      requestType: "DELETE",
      loaderType: "dialogueLoader",
      onSuccess: (responseData) => {
       
        dispatch(deleteStoreLocation(selectedLocationId));
      },
      onError: (responseData) => {
        console.log(responseData);
    
      },
    });
  };


  return (
    <MainLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          margin: "auto",
          alignItems: "center",
        }}
      >
        <PageHeader
          title={"Location Management"}
          description={
            "Add and showcase consumer-friendly details to help customers discover, connect, and engage with your locations effortlessly."
          }
          backArrow={false}
          action={
            <GeneralButton
              label={
                <>
                  Add New Location
                  <ArrowOutwardOutlinedIcon style={{ marginLeft: "8px" }} />
                </>
              }
              type="circularIcon"
              variant="outlined"
              onClick={() => setOpen(true)}
              sx={{
                margin: "auto",
                marginTop: "1rem",
              }}
            />
          }
        />
        {locationList?.length === 0 ? (
          <Box
            sx={{
              background: "white",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              padding: 4,
              borderRadius: 2,
              width: {
                sm: "100%",
                md: "100%",
              },
              alignSelf: "center",
            }}
          >
            <Box>
              <Box
                component="img"
                src={LocationImage}
                alt="No Location"
                sx={{
                  width: "15rem",
                  margin: "auto",
                  display: "block",
                }}
              />
              <Typography
                variant="h3"
                sx={{
                  fontSize: "1.25rem",
                  fontWeight: "700",
                  fontFamily: "Epilogue, sans-serif",
                  lineHeight: "1.25rem",
                  margin: "1rem 0",
                }}
              >
                No Location Added Yet
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "1rem",
                  fontWeight: "400",
                  fontFamily: "Epilogue, sans-serif",
                  lineHeight: "1.25rem",
                }}
              >
                Your store locationList will appear here. Add a new location to
                start showcasing your stores.
              </Typography>
              <GeneralButton
                label={
                  <>
                    Add Your First Location.
                    <ArrowOutwardOutlinedIcon style={{ marginLeft: "8px" }} />
                  </>
                }
                type="circularIcon"
                variant="outlined"
                onClick={() => { setOpen(true); }}
                sx={{
                  margin: "auto",
                  marginTop: "1rem",
                }}
              />
            </Box>
          </Box>
        ) : (
          <Grid container>
            {locationList?.map((location, index) => {
               const isExpanded = locationsExpanded[index];
               const isBase64 =
               typeof location.store_photo === 'string' &&
               location.store_photo.startsWith('data:image');
             
             const isHttpUrl =
               typeof location.store_photo === 'string' &&
               (location.store_photo.startsWith('http') || location.store_photo.startsWith('/'));
             
             const logoSrc = isBase64
               ? location.store_photo // Base64 with 'data:image' prefix
               : isHttpUrl
               ? location.store_photo // Valid HTTP(S) URL or local path
               : typeof location.store_photo === 'string'
               ? `data:image/png;base64,${location.store_photo}` // Base64 without prefix
               : LocationImage; // Fallback image
               console.log(logoSrc);
              return (
                <Grid
                  item
                  md={12}
                  lg={6}
                  sx={{
                    padding: 1,
                  }}
                >
                  <Box
                    sx={{
                      background: "white",
                      borderRadius: 5,
                      padding: "1rem",
                    }}
                  >
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: "15rem",
                        margin: "auto",
                        display: "block",
                        borderRadius: 5,
                      }}
                    >
                    <Box
            component="img"
            src={logoSrc} // Use the computed logoSrc
            alt="Location"
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: 5,
              objectFit: "cover",
            }}
          />
                      <Box
                        sx={{
                          position: "absolute",
                          top: 10,
                          right: 10,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            background: "white",
                            padding: "0.5rem",
                            border: "1px solid green",
                            borderRadius: 2,
                            mb: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        >
                          <EditIcon
                            sx={{ color: "primary.main", fontSize: 20 }}
                          />
                        </Box>
                        <Box
                          sx={{
                            background: "white",
                            padding: "0.5rem",
                            border: "1px solid red",
                            borderRadius: 2,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => handleDeleteClick(location.id)}
                        >
                          <DeleteIcon sx={{ color: "red", fontSize: 20 }} />
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        margin: "1rem 0",
                      }}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          fontSize: "1.25rem",
                          fontWeight: "700",
                          fontFamily: "Epilogue, sans-serif",
                          lineHeight: "1.25rem",
                        }}
                      >
                        {locationList[index].name}
                      </Typography>
                      <GeneralButton
                        label={locationList[index].status}
                        type="PlainText"
                        variant="outlined"
                        onClick={() => {}}
                        sx={{
                          background:
                            locationList[index].status === "approved"
                              ? "#ECF7F3"
                              : "#FEF8EB",
                          color:
                            locationList[index].status === "approved"
                              ? "primary.main"
                              : "#F9BD38",
                          textTransform: "capitalize",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="img"
                        src={locationIcon}
                        alt="Location"
                        sx={{
                          display: "block",
                          mr: 1,
                        }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: "1rem",
                          fontWeight: "400",
                          fontFamily: "Epilogue, sans-serif",
                          lineHeight: "1.25rem",
                        }}
                      >
                        {locationList[index].address}
                      </Typography>
                    </Box>
                    {isExpanded && (
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            margin: "1rem 0",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mr: 2,
                            }}
                          >
                            <Box
                              component="img"
                              src={storeIcon}
                              alt="Store"
                              sx={{
                                display: "block",
                                mr: 1,
                              }}
                            />
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "1rem",
                                fontWeight: "400",
                                fontFamily: "Epilogue, sans-serif",
                                lineHeight: "1.25rem",
                              }}
                            >
                              {locationList[index].type}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mr: 2,
                            }}
                          >
                            <Box
                              component="img"
                              src={phoneIcon}
                              alt="Phone"
                              sx={{
                                display: "block",
                                mr: 1,
                              }}
                            />
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "1rem",
                                fontWeight: "400",
                                fontFamily: "Epilogue, sans-serif",
                                lineHeight: "1.25rem",
                              }}
                            >
                              {locationList[index].number}
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexWrap: "wrap",
                            margin: "1rem 0",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mr: 2,
                            }}
                          >
                            <Box
                              component="img"
                              src={emailIcon}
                              alt="email"
                              sx={{
                                display: "block",
                                mr: 1,
                              }}
                            />
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "1rem",
                                fontWeight: "400",
                                fontFamily: "Epilogue, sans-serif",
                                lineHeight: "1.25rem",
                              }}
                            >
                              {locationList[index].email}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mr: 2,
                            }}
                          >
                            <Box
                              component="img"
                              src={websiteIcon}
                              alt="website"
                              sx={{
                                display: "block",
                                mr: 1,
                              }}
                            />
                            <Typography
                              variant="body1"
                              sx={{
                                fontSize: "1rem",
                                fontWeight: "400",
                                fontFamily: "Epilogue, sans-serif",
                                lineHeight: "1.25rem",
                              }}
                            >
                              {locationList[index].website}
                            </Typography>
                          </Box>
                        </Box>
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: "1rem",
                            fontWeight: "400",
                            fontFamily: "Epilogue, sans-serif",
                            lineHeight: "1.8rem",
                          }}
                        >
                          {locationList[index].description}
                        </Typography>
                      </Box>
                    )}
                    <GeneralButton
                      label={
                        <>
                          {isExpanded ? "See Less" : "See More"}
                          <ArrowOutwardOutlinedIcon
                            style={{ marginLeft: "8px" }}
                          />
                        </>
                      }
                      type="circularIcon"
                      variant="outlined"
                      onClick={() => toggleExpanded(index)}
                      sx={{
                        margin: "auto",
                        marginTop: "1rem",
                      }}
                    />
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
      <LocationModal open={open} onClose={() => setOpen(false)} />
      <DeleteDialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        title={"Are you sure you want to delete this location?"}
        content={
          "This action cannot be undone. Once deleted, the location will be permanently removed."
        }
        onConfirm={handleConfirmDelete}
      />
    </MainLayout>
  );
};

export default LocationPage;
