import MainLayout from "../../../../development/page/parts/layout/MainLayout/index";
import RegisterPageComponent from "./../organisms/registerPageComponent";

const RegisterPage = () => {    
    return (
        <MainLayout>
            <RegisterPageComponent />
        </MainLayout>
    );
}

export default RegisterPage;