// src/components/LoadingComponent.js

import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

const LoadingComponent = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '200px',
        textAlign: 'center',
      }}
    >
      <SaveIcon color="primary" sx={{ fontSize: 60, mb: 2 }} />
      <CircularProgress />
      <Typography variant="h6" sx={{ mt: 2 }}>
        Saving your personal info...
      </Typography>
    </Box>
  );
};

export default LoadingComponent;
