import SignInPageContainerTwo from "../../../../components/pageStructures/authPages/combined/pages/signInPageContainerTwo";
import SignInFormUI from "../molecules/SignInFormUI";
import { useMediaQuery } from "@mui/material";

const SignInPage = () => {
  const isMobile = useMediaQuery("(max-width:899px)"); 
  return (
    <>
     {isMobile ? (
        <SignInFormUI />
      ) : (
      <SignInPageContainerTwo>
        <SignInFormUI />
      </SignInPageContainerTwo>
         )}
    </>
  );
};

export default SignInPage;
