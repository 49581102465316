// validateInput.js
// Validates that input is provided and that the input is only numbers

export function validateNumbers(input) {
    if (!input) {
        return 'Input is required.';
    }

    const numberPattern = /^\d+$/;
    return numberPattern.test(input) ? '' : 'Invalid input. Please enter only numbers.';
}
