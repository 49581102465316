import React from 'react';
import { Box, Typography, Button, useTheme } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

const ResubmitIdentity = ({ onResubmit }) => {
  const theme = useTheme();
  const primaryColor = '#FF4C4C';

  return (
    <Box
      sx={{
        maxWidth: 500,
        width: "100%",
        mx: "auto",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <ErrorIcon sx={{ fontSize: 50, color: primaryColor, mb: theme.spacing(2) }} />
        <Typography variant="h5" gutterBottom sx={{ color: '#333333', fontWeight: '600' }}>
          Identity Verification Failed
        </Typography>
        <Typography variant="body1" sx={{ color: '#666666', mb: theme.spacing(4) }}>
          We were unable to verify your identity. 
          <br />
          <br />
          <strong>Please review and correct your information before resubmitting.</strong> 
          <br />
          Submitting the <strong>same information again</strong> will likely result in another failure.
          <br />
          <br />
          Ensure all details are accurate to improve your chances of successful verification.
        </Typography>
        <Button
          variant="contained"
          onClick={onResubmit}
          sx={{
            backgroundColor: primaryColor,
            color: '#FFFFFF',
            borderRadius: '30px',
            px: theme.spacing(4),
            py: theme.spacing(1.5),
            textTransform: 'none',
            fontWeight: '500',
            '&:hover': {
              backgroundColor: '#e04343',
            },
          }}
        >
          Resubmit Information
        </Button>
      </Box>
    </Box>
  );
};

export default ResubmitIdentity;
