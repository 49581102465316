import React from "react";
import { Button, Stack } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

const DocumentStackUI = ({ data, onItemClick }) => {
  return (
    <Stack spacing={2}>
      {data?.map((item, index) => (
        <Button
          key={index}
          fullWidth
          onClick={() => onItemClick(item, index)}
          sx={{
            justifyContent: "space-between",
            bgcolor: "background.paper",
            border: 0.2,
            borderColor: "grey.300",
            py: 1,
            px: 2,
            textTransform: "none",
            borderRadius: "0",
            boxShadow: "none",
            ":hover": {
              bgcolor: "grey.100",
              boxShadow: "none",
            },
          }}
        >
          {item.name}
          {item.isCompleted ? (
            <CheckCircleOutlineIcon color="success" />
          ) : (
            <RadioButtonUncheckedIcon color="disabled" />
          )}
        </Button>
      ))}
    </Stack>
  );
};

export default DocumentStackUI;
