import MainLayout from "../../../development/page/parts/layout/MainLayout/index";
import KYCPageInside from "./forms/main";


const KycPage = () => {
  return (
    <MainLayout>
     <KYCPageInside/>
    </MainLayout>
  );
};

export default KycPage;
