import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectEmployees } from "../../../../features/register/registerSelectors";
import { addEmployee, deleteEmployee } from "../../../../features/register/registerSlice";
import useFullFetchFeedback from "../../../../functions/apiFetchers/fullPageLoaders";


const EmployeeLogic = () => {
    const employeesList = useSelector(selectEmployees);
    const dispatch = useDispatch();
    const fetchFeedback = useFullFetchFeedback();

    const addEmplyeeSubmit = async (firstName, LastName, email) => {
     
        
        await fetchFeedback({
        endpoint:`merchant/add/employee/`,
        loaderType: "dialogueLoader",
        data: { first_name: firstName, last_name:LastName,  email },
        requestType: "POST",
    
        loaderMessage: "Adding Employee...",
        successMessage: "Added Employee successfully!",
        // setError, // function to handle validation errors
        onSuccess: (responseData) => {
          dispatch(addEmployee({first_name: firstName, last_name:LastName,  email}));
     
        
        },
        });
  };
  const DeleteEmplyeeSubmit = async (email) => {

    
    await fetchFeedback({
    endpoint:`merchant/delete/employee/`,
    loaderType: "dialogueLoader",
    data: { email },
    requestType: "POST",

    loaderMessage: "Deleting Employee...",
    successMessage: "Deleted Employee successfully!",
    // setError, // function to handle validation errors
    onSuccess: (responseData) => {
      dispatch(deleteEmployee(email));
 
    
    },
    });
};

const sendEmailToOneEmplyeeSubmit = async (emailListWithOneEmail) => {

    
    await fetchFeedback({
    endpoint:`merchant/send/credentials/`,
    loaderType: "dialogueLoader",
    data: { emails: emailListWithOneEmail },
    requestType: "POST",

    loaderMessage: "Sending Email to Employee...",
    successMessage: "Email sent successfully!",
    // setError, // function to handle validation errors
    onSuccess: (responseData) => {
    
 
    
    },
    });
};

const sendEmailToAllEmplyesSubmit = async (employeeEmailList) => {
 
    
    await fetchFeedback({
    endpoint:`merchant/send/credentials/`,
    loaderType: "dialogueLoader",
    loaderMessage: "Sending Email to all your employees...",

    data: { emails: employeeEmailList },
    requestType: "POST",
  

    onSuccess: (responseData) => {
      
 
    
    },
    });
};
    
   

    return {
        employeesList,
        sendEmailToAllEmplyesSubmit,
        sendEmailToOneEmplyeeSubmit,
        DeleteEmplyeeSubmit,
        addEmplyeeSubmit,
       
    }

}

export default EmployeeLogic;