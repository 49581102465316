// src/components/VerificationCompleteComponent.js

import React from 'react';
import { Box, Typography } from '@mui/material';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';

const VerificationCompleteComponent = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '300px',
        textAlign: 'center',
        padding: 4,
        borderRadius: '16px',
      }}
    >
      {/* Icon */}
      <VerifiedUserIcon color="primary" sx={{ fontSize: 80, mb: 3 }} />

      {/* Header */}
      <Typography
        variant="h4"
        component="h2"
        sx={{
          mb: 2,
          fontWeight: 700,
          fontFamily: 'Helvetica, Arial, sans-serif',
          color: '#333333',
        }}
      >
        Verification Information Complete
      </Typography>

      {/* Subheader */}
      <Typography
        variant="subtitle1"
        sx={{
          mb: 4,
          color: 'text.secondary',
          fontFamily: 'Helvetica, Arial, sans-serif',
          maxWidth: '600px',
        }}
      >
        Thank you! Your personal and owner information has been successfully submitted. We’re now processing your details for verification. You’ll be notified once the verification is complete.
      </Typography>

      {/* Action Message */}
      <Typography
        variant="body1"
        sx={{
          color: '#1976d2',
          fontWeight: 500,
          fontFamily: 'Helvetica, Arial, sans-serif',
        }}
      >
        Thank you for providing all required information.
      </Typography>
    </Box>
  );
};

export default VerificationCompleteComponent;
