import useCustomerRetryInfo from "../organisms/useCustomerRetryInfo";
import useUpdateRetryInfo from "../organisms/useUpdateRetryInfo";

import useChangeDataDialog from "../molecules/useChangeDataDialog";

const useCustomerRetry = () => {
  const {
    selectedRetryData,
    setSelectedRetryData,
    handleSubmit: customerRetryInfoHandleSubmit,
    personalInfo,
    businessInfo,
    owners,
    handleItemClick: customerRetryInfoHandleItemClick,
  } = useCustomerRetryInfo();

  const {
    dialogOpen,
    handleItemClick: updateRetryInfoHandleItemClick,
    setDialogOpen,
    dialogData,
    formatValue,
  } = useUpdateRetryInfo();


  const {
    handleSubmit: changeDataDialogHandleSubmit,
    formData,
    formatAddress,
    handleInputChange,
  } = useChangeDataDialog({
    selectedRetryData,
    dialogData,
    handleClose: () => setDialogOpen(false),
  });

  return {
  

    selectedRetryData,
    setSelectedRetryData,
    customerRetryInfoHandleSubmit,
    personalInfo,
    businessInfo,
    owners,
    customerRetryInfoHandleItemClick,

    dialogOpen,
    updateRetryInfoHandleItemClick,
    setDialogOpen,
    dialogData,
    formatValue,

    changeDataDialogHandleSubmit,
    formData,
    formatAddress,
    handleInputChange,
  };
};

export default useCustomerRetry;
