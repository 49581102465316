import React from "react";
import { TextField } from "@mui/material";

const EINFormattedTextField = ({
  label,
  value,
  onChange,
  name,
  error,
  errorMessageAndhelp,
  onFocus,

  commonStyles,
}) => {


  const handleEINChange = (e) => {
    const inputValue = e.target.value;
    let cleanedValue = inputValue.replace(/[^0-9]/g, '');
    let formattedValue = '';

    // Format the input value to nn-nnnnnnn
    if (cleanedValue.length <= 2) {
      formattedValue = cleanedValue;
    } else {
      formattedValue = `${cleanedValue.slice(0, 2)}-${cleanedValue.slice(2, 9)}`;
    }

    onChange({ target: { name, value: formattedValue } });
  };

  return (
    <TextField
      label={label}
      variant="outlined"
      value={value}
      name={name}
      onChange={handleEINChange}
      onFocus={onFocus}
      fullWidth
      error={!!error}
      helperText={errorMessageAndhelp}
      sx={commonStyles}
    />
  );
};

export default EINFormattedTextField;
