import MainLayout from "../../../../development/page/parts/layout/MainLayout/index";
import EmployeeManagementPageComponent from "../organisms/employeeManagementPageComponent";

const employeeManagementPage = () => {
  return (
    <MainLayout>
      <EmployeeManagementPageComponent />
    </MainLayout>
  );
};

export default employeeManagementPage;