import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import GeneralButton from "../../atoms/buttons/GeneralButton";

const Infopop = ({ open, onClose, title, info }) => {
  // Render the info content based on the type
  // If it's an array, render a list of items
  // If it's a string, render a single item

  const renderInfoContent = (info) => {
    if (Array.isArray(info)) {
      return (
        <List>
          {info.map((item, index) => (
            <ListItem key={index}>{item}</ListItem>
          ))}
        </List>
      );
    } else {
      return (
        <DialogContentText id="info-dialog-description">
          {info}
        </DialogContentText>
      );
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="info-dialog-title"
      aria-describedby="info-dialog-description"
      maxWidth="sm"
    >
      <DialogTitle id="info-dialog-title">{title}</DialogTitle>
      <DialogContent>{renderInfoContent(info)}</DialogContent>
      <DialogActions>
        <GeneralButton label="Close" onClick={onClose} type="PlainText" />
      </DialogActions>
    </Dialog>
  );
};

export default Infopop;
