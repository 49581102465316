// src/components/loading/CodeLoading.js
import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Verified } from '@mui/icons-material';
import { keyframes } from '@emotion/react';

const bounce = keyframes`
  0%, 100% { transform: translateY(0); } 
  50% { transform: translateY(-5px); } 
`;

function CodeLoading() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
       
        padding: 2,
        borderRadius: 2,
      }}
    >
      <Verified
        sx={{
          fontSize: 60,
          color: '#1EC674',
          mb: 2,
          animation: `${bounce} 2s infinite`,
        }}
      />
      <CircularProgress sx={{ color: '#1EC674', mb: 2 }} size={50} />
      <Typography
        variant="h6"
        sx={{ color: '#000000', fontFamily: 'SF Pro Text, Helvetica Neue, Arial, sans-serif' }}
      >
        Verifying code...
      </Typography>
    </Box>
  );
}

export default CodeLoading;
