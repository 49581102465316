import React from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  CircularProgress,
  Fade,
  Button,
} from '@mui/material';

const LoaderDialog = ({ open, message, onClose }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Fade}
      onClose={onClose}
      PaperProps={{
        style: {
          padding: '24px',
          borderRadius: '10px',
          width: '400px',
          textAlign: 'center',
        },
      }}
      BackdropProps={{
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0.6)', // Semi-transparent background
        },
      }}
    >
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={60} style={{ color: '#1BC575' }} />
        {message && (
          <Typography
            variant="h6"
            style={{ marginTop: '20px', color: '#111111' }}
          >
            {message}
          </Typography>
        )}
        {onClose && (
          <Button
            variant="contained"
            onClick={onClose}
            style={{
              marginTop: '20px',
              backgroundColor: '#1BC575',
              color: '#FFFFFF',
            }}
          >
            Close
          </Button>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LoaderDialog;