// BankingPage.js

import React from "react";
import { Box, Grid, Typography, Divider } from "@mui/material";
import BankLogic from "../../functional/organisms/bank";
import BankAccountCard from "./BankAccountCard";
import PendingFundsCard from "./PendingFundsCard";
import LoadingModal from "./LoadingModal";
import SuccessModal from "./SuccessModal";

const BankingPage = () => {
  const {
    bankData,
    hasBankAccount,
    pendingBalance,
    openModal,
    success,
    onClose,
    handleAccountClick,
  } = BankLogic();

  return (
    <Box >
     

      {/* Improved Page Structure */}
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <BankAccountCard
            bankData={bankData}
            hasBankAccount={hasBankAccount}
            handleAccountClick={handleAccountClick}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <PendingFundsCard pendingBalance={pendingBalance} />
        </Grid>
      </Grid>

      {/* Modals */}
      <LoadingModal open={openModal} />
      <SuccessModal open={success} onClose={onClose} />
    </Box>
  );
};

export default BankingPage;
