// UploadPhotoButton.js
import React from "react";
import Button from "@mui/material/Button";

const UploadPhotoButton = ({ onClick, sx = {}, label = "Upload Photo" }) => {
  return (
    <Button
      variant="contained"
      component="label"
      
      sx={{
        justifyContent: "space-between",
        textTransform: "none",
        ...sx,
      }}
    >
      {label}
      <input type="file" hidden onChange={onClick} accept="image/*" />
    </Button>
  );
};

export default UploadPhotoButton;
