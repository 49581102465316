import React from 'react';
import {
    Paper,
    Box,
    Typography,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    Skeleton,
    useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

const CustomTable = ({
    title,
    columns,
    data,
    loading = false,
    rowsPerPage = 10,
    totalRows = data.length,
    page = 0,
    handlePageChange = () => {},
    showPagination = false,
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <TableContainer
            component={Paper}
            elevation={3}
            sx={{
                borderRadius: '15px',
                overflowX: 'auto', // Enable horizontal scrolling
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    p: 2,
                    flexDirection: isMobile ? 'column' : 'row',
                    textAlign: isMobile ? 'center' : 'left',
                }}
            >
                <Typography
                    variant={isMobile ? 'subtitle1' : 'h6'}
                    component="div"
                    sx={{ fontWeight: 600 }}
                >
                    {title}
                </Typography>
            </Box>
            <Table
                sx={{
                    // Removed tableLayout fixed to give columns more space
                    '& th, & td': { whiteSpace: 'nowrap' }, // Prevent word wrapping
                }}
            >
                <TableHead>
                    <TableRow>
                        {columns.map((column, index) => (
                            <TableCell
                                key={index}
                                align="center"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: isMobile ? '0.9rem' : '1rem',
                                }}
                            >
                                {column.title}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading
                        ? Array.from({ length: rowsPerPage }).map((_, index) => (
                              <TableRow key={index}>
                                  <TableCell colSpan={columns.length} align="center">
                                      <Skeleton
                                          animation="wave"
                                          height={40}
                                          variant="text"
                                          width="100%"
                                      />
                                  </TableCell>
                              </TableRow>
                          ))
                        : data.length > 0 ? (
                              data.map((row, index) => (
                                  <TableRow
                                      key={index}
                                      sx={{
                                          '&:nth-of-type(odd)': {
                                              backgroundColor: theme.palette.action.hover,
                                          },
                                      }}
                                  >
                                      {columns.map((column, columnIndex) => (
                                          <TableCell
                                              key={columnIndex}
                                              align="center"
                                              sx={{
                                                  fontSize: isMobile ? '0.9rem' : '1rem',
                                              }}
                                          >
                                              {row[column.key]}
                                          </TableCell>
                                      ))}
                                  </TableRow>
                              ))
                          ) : (
                              <TableRow>
                                  <TableCell colSpan={columns.length} align="center">
                                      No data found.
                                  </TableCell>
                              </TableRow>
                          )}
                </TableBody>
            </Table>
            {showPagination && (
                <TablePagination
                    component="div"
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) => handlePageChange(newPage)}
                    rowsPerPageOptions={[rowsPerPage]} // Fixed rows per page
                    sx={{
                        '.MuiTablePagination-toolbar': {
                            flexWrap: isMobile ? 'wrap' : 'nowrap',
                        },
                    }}
                />
            )}
        </TableContainer>
    );
};

export default CustomTable;