import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import GeneralButton from "../../../../../components/atoms/buttons/GeneralButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EastIcon from "@mui/icons-material/East";
import CloseIcon from "@mui/icons-material/Close";

const InfoDailog = ({
  open,
  onClose,
  handleNextButtonClick,
  handleBackButtonClick,
  carouselItems,
  activeIndex,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDialog-paper": {
          padding: 2,
          borderRadius: 2,
          width: "100%",
          textAlign: "center",
          minHeight: "40rem",
        },
      }}
    >
      <GeneralButton
        label={
          <>
            <CloseIcon />
          </>
        }
        onClick={onClose}
        type="PlainText"
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          fontColor: "text.secondary",
          hover: "none",
        }}
      />
      <Typography
        variant="body1"
        sx={{
          color: "text.secondary",
          paddingBottom: "10px",
          letterSpacing: "0.5px",
          width: "100%",
          lineHeight: "1.5",
          margin: "auto",
          textAlign: "center",
          fontSize: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <AccessTimeIcon style={{ marginRight: "8px" }} />
        Set Up in Just 2 Minutes-No Integrations Needed.
      </Typography>
      <Box
        component="img"
        src={carouselItems[activeIndex].image}
        alt="admin setup image"
        sx={{
          width: "50%",
          margin: "auto",
          display: "block",
          "@media (max-width: 768px)": {
            width: "100%",
            marginTop: "20px",
            padding: 2,
          },
        }}
      />

      <Typography
        sx={{ fontSize: "1.5rem", fontWeight: "bold", marginY: "10px" }}
      >
        {carouselItems[activeIndex].title}
      </Typography>
      <DialogContent
        sx={{
          color: "text.secondary",
          paddingY: "10px",
          letterSpacing: "0.5px",
          width: "100%",
          lineHeight: "1.5",
          margin: "auto",
          textAlign: "center",
          fontSize: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {carouselItems[activeIndex].description}
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
            textColor: "text.secondary",
          }}
        >
          <GeneralButton
            label={
              <>
                <ArrowBackIcon
                  style={{ marginRight: "8px", textColor: "text.secondary" }}
                />
                Back
              </>
            }
            onClick={handleBackButtonClick}
            type="circularIcon"
            variant="outlined"
            sx={{
              marginTop: "20px",
            }}
            disabled={activeIndex === 0}
          />

          <GeneralButton
            label={
              <>
                Next
                <EastIcon style={{ marginLeft: "8px" }} />
              </>
            }
            onClick={handleNextButtonClick}
            type="circularIcon"
            variant="outlined"
            sx={{
              marginTop: "20px",
            }}
            disabled={activeIndex === carouselItems.length - 1}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default InfoDailog;
