import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const LoadScript = (src, callback) => {
  const script = document.createElement('script');
  script.src = src;
  script.async = true;
  script.defer = true;
  script.onload = () => callback(null);
  script.onerror = (error) => callback(error);
  document.head.appendChild(script);
};

// Function to validate the address format and values
const isValidAddress = (address) => {
  return (
    address &&
    typeof address === 'object' &&
    address.address &&
    address.city &&
    address.state &&
    address.zip_code
  );
};

const formatAddress = (addressObj) => {
  return `${addressObj.address}, ${addressObj.city}, ${addressObj.state}, ${addressObj.zip_code}`;
};

const GoogleMapsAutocomplete = ({ onChange, label, name, error, errorMessageAndhelp, commonStyles, value }) => {
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [addressParts, setAddressParts] = useState({
    address: '', city: '', state: '', zip_code: ''
  });

  useEffect(() => {
    LoadScript(
      `https://maps.googleapis.com/maps/api/js?key=AIzaSyCkVBA9waHAo7hb8-Xu8ycaEXj24bl6IeE&libraries=places`,
      (error) => {
        if (error) {
          console.error("Google Maps API script not loaded");
          return;
        }
      }
    );
  }, []);

  useEffect(() => {
    if (isValidAddress(value)) {
      const formattedValue = formatAddress(value);
      setInputValue(formattedValue);
    }
  }, [value]);

  const handleAddressSelected = async (placeId) => {
    if (!placeId) return;

    const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
    placesService.getDetails({ placeId }, (place, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const addressObj = place.address_components.reduce((acc, component) => {
          const type = component.types[0];
          if (type === 'street_number') {
            acc.address = component.long_name + (acc.address ? ' ' + acc.address : '');
          } else if (type === 'route') {
            acc.address = (acc.address ? acc.address + ' ' : '') + component.long_name;
          } else if (type === 'locality') {
            acc.city = component.long_name;
          } else if (type === 'administrative_area_level_1') {
            acc.state = component.short_name;
          } else if (type === 'postal_code') {
            acc.zip_code = component.long_name;
          }
          return acc;
        }, { address: '', city: '', state: '', zip_code: '' });

        if (isValidAddress(addressObj)) {
          setAddressParts(addressObj);
          const formattedValue = formatAddress(addressObj);
          setInputValue(formattedValue);

          // Mimic the event structure expected by a TextField onChange event
          const event = {
            target: {
              name,
              value: addressObj
            }
          };

          // Trigger onChange event
          onChange(event);
        }
      }
    });
  };

  const formatSuggestion = (description, addressObj) => {
    return `${description}, ${addressObj.zip_code}`.replace(', USA', '');
  };

  const fetchSuggestionDetails = async (suggestions) => {
    const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
    const detailedSuggestions = await Promise.all(
      suggestions.map(async (suggestion) => {
        return new Promise((resolve) => {
          placesService.getDetails({ placeId: suggestion.place_id }, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
              const addressObj = place.address_components.reduce((acc, component) => {
                const type = component.types[0];
                if (type === 'postal_code') {
                  acc.zip_code = component.long_name;
                }
                return acc;
              }, { zip_code: '' });

              resolve({
                ...suggestion,
                label: formatSuggestion(suggestion.label, addressObj)
              });
            } else {
              resolve(suggestion);
            }
          });
        });
      })
    );
    return detailedSuggestions;
  };

  useEffect(() => {
    if (!inputValue) {
      setSuggestions([]);
    } else if (window.google) {
      const autocompleteService = new window.google.maps.places.AutocompleteService();
      autocompleteService.getPlacePredictions(
        { input: inputValue },
        async (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
            const detailedSuggestions = await fetchSuggestionDetails(predictions.map(prediction => ({
              label: prediction.description,
              place_id: prediction.place_id
            })));
            setSuggestions(detailedSuggestions);
          } else {
            setSuggestions([]);
          }
        }
      );
    }
  }, [inputValue]);

  return (
    <Autocomplete
      freeSolo
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
      onChange={(event, newValue) => handleAddressSelected(newValue?.place_id)}
      options={suggestions || []}
      getOptionLabel={(option) => option?.label || ''}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          fullWidth
          name={name}
          error={error}
          helperText={errorMessageAndhelp}
          sx={commonStyles}
        />
      )}
    />
  );
};

export default GoogleMapsAutocomplete;
