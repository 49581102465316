// src/components/loading/ResendCodeLoading.js
import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Email } from '@mui/icons-material';
import { keyframes } from '@emotion/react';

const shake = keyframes`
  0% { transform: rotate(0deg); } 
  25% { transform: rotate(-10deg); } 
  75% { transform: rotate(10deg); } 
  100% { transform: rotate(0deg); }
`;

function ResendCodeLoading() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      <Email
        sx={{
          fontSize: 60,
          color: '#007AFF',
          mb: 2,
          animation: `${shake} 1s infinite`,
        }}
      />
      <CircularProgress sx={{ color: '#007AFF', mb: 2 }} size={50} />
      <Typography
        variant="h6"
        sx={{ color: '#3C3C43', fontFamily: 'SF Pro Text, Helvetica Neue, Arial, sans-serif' }}
      >
        Resending code...
      </Typography>
    </Box>
  );
}

export default ResendCodeLoading;
