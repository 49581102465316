import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

const VerificationPending = () => {
  const theme = useTheme();

  return (
    <Box
    sx={{
      border: `1px solid #E0E0E0`,
      borderRadius: '16px',
      padding: theme.spacing(4),
      textAlign: 'center',
      backgroundColor: '#FFFFFF',
      transition: 'box-shadow 0.3s ease, transform 0.3s ease',
      '&:hover': {
        boxShadow: '0 8px 30px rgba(0, 0, 0, 0.1)',
        transform: 'translateY(-4px)',
      },
    }}
  >
    <Box textAlign="center">
      <HourglassEmptyIcon sx={{ fontSize: 50, color: '#FFA500', marginBottom: theme.spacing(2) }} />
      <Typography variant="h5" gutterBottom sx={{ color: '#333333', fontWeight: '600' }}>
        Verification in Progress
      </Typography>
      <Typography variant="body1" sx={{ color: '#666666', marginBottom: theme.spacing(4) }}>
        We are reviewing your information. Verification can take anywhere from a few minutes to 2-3 days. Thank you for your patience!
      </Typography>
    </Box>
    </Box>
  );
};

export default VerificationPending;
