import { Box, Typography } from "@mui/material";
import GeneralButton from "../../../../components/atoms/buttons/GeneralButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import noEmployeeFound from "../../../../images/no-employee-found.png";
import { selectEmployees } from "../../../../features/register/registerSelectors";
import { useDispatch, useSelector } from "react-redux";
import useFullFetchFeedback from "../../../../functions/apiFetchers/fullPageLoaders";
import { deleteEmployee } from "../../../../features/register/registerSlice";

const EmployeesListCardUI = ({ handleOpenDeleteDialog, employeeConstant }) => {
  const employeesList = useSelector(selectEmployees);

 
  return (
    <Box
      sx={{
        flex: 1,
        maxWidth: "50%",
        "@media (max-width: 768px)": {
          maxWidth: "100%",
        },
      }}
    >
      <Box
        sx={{
          background: "white",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          padding: 4,
          borderRadius: 2,
          width: "90%",
          margin: "auto",
          "@media (max-width: 768px)": {
            width: "100%",
            marginTop: "20px",
            padding: 2,
          },
        }}
      >
        <Box>
          <Typography variant="h2" component="h1" sx={{ marginBottom: 2 }}>
            {employeesList?.length === 0 ? "" : "List of Employees"}
          </Typography>
          {employeesList?.length === 0 ? (
            <Box sx={{ textAlign: "center", marginTop: "1rem" }}>
              <Box
                component="img"
                src={noEmployeeFound}
                alt="accept Payment image"
                sx={{
                  width: "40%",
                  margin: "auto",
                  display: "block",
                  "@media (max-width: 768px)": {
                    width: "100%",
                    padding: 2,
                  },
                }}
              />
              <Typography
                variant="h2"
                component="h2"
                sx={{ marginY: 2, fontSize: "1.2rem" }}
              >
                No Employees Added Yet
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "text.secondary",
                  paddingBottom: "10px",
                  letterSpacing: "0.5px",
                  width: "100%",
                  lineHeight: "1.5",
                  margin: "auto",
                  textAlign: "center",
                  fontSize: "0.9rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No employees added yet. Add your first employee by filling out
                the form on the right side.
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                maxHeight: "50vh",
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "5px",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#d9d9d9",
                  borderRadius: "10px",
                },
              }}
            >
              {employeesList?.map((employee) => (
                <Box
                  key={employee.first_name}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: "1px solid #e0e0e0",
                    padding: "5px 0",
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: "normal" }}>
                    {employee.first_name} {employee.last_name}
                  </Typography>

                  <GeneralButton
                    label={<DeleteOutlineIcon />}
                    onClick={() => handleOpenDeleteDialog(employee.email)}
                    type="PlainText"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "red",
                      backgroundColor: "#ffe7e7",
                      marginRight: 1,
                    }}
                  />
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default EmployeesListCardUI;
