import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import GeneralButton from "../../atoms/buttons/GeneralButton";

const DeleteDialog = ({ open, onClose, title, content, onConfirm }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          borderRadius: 2,
        },
      }}
    >
      <DeleteOutlineIcon
        sx={{
          textAlign: "center",
          margin: "auto",
          color: "red",
          backgroundColor: "#ffe7e7",
          fontSize: "3rem",
          borderRadius: "20px",
          padding: "5px",
        }}
      />

      <DialogTitle
        sx={{
          textAlign: "center",
          fontWeight: "bold",
          width: "90%",
          margin: "auto",
          fontSize: "1.4rem",
        }}
      >
        {title}
      </DialogTitle>

      <DialogContent
        sx={{
          width: "90%",
          margin: "auto",
          textAlign: "center",
          fontSize: "1.3rem",
        }}
      >
        <DialogContentText sx={{ fontSize: "1.2rem" }}>
          {content}
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ display: "flex", justifyContent: "space-around" }}>
        <GeneralButton label="Cancel" onClick={onClose} type="circularIcon" />
        <GeneralButton
          label="Delete"
          onClick={onConfirm}
          type="circularIcon"
          sx={{ backgroundColor: "#3eb489" }}
        />
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
