import React from "react";
import { useSelector } from "react-redux";
import LoaderDialog from "../components/organisms/loaders/loaderDialog";
import Loader from "../components/organisms/loaders/simple";
import {
  selectIsLoading,
  selectLoaderMessage,
} from "../features/loading/loadingSelectors";

const ConditionalLoader = () => {
  const isPageLoading = useSelector((state) =>
    selectIsLoading(state, "pageLoader")
  );
  const pageLoaderMessage = useSelector((state) =>
    selectLoaderMessage(state, "pageLoader")
  );
  const isDialogueLoading = useSelector((state) =>
    selectIsLoading(state, "dialogueLoader")
  );
  const dialogueLoaderMessage = useSelector((state) =>
    selectLoaderMessage(state, "dialogueLoader")
  );

  return (
    <>
      {isPageLoading && <Loader text={pageLoaderMessage} />}
      {isDialogueLoading && (
        <LoaderDialog
          open={isDialogueLoading}
          message={dialogueLoaderMessage}
        />
      )}
    </>
  );
};

export default ConditionalLoader;
