// validateFullSSN.js
// Validates a full SSN, expecting a 9-digit number
// validateSSN.js
// Validates a full SSN in the format nnn-nn-nnnn or as a 9-digit number

export function validateFullSSN(ssn) {
    if (!ssn) return 'SSN is required.';
    const ssnPattern = /^\d{3}-\d{2}-\d{4}$/;
    return ssnPattern.test(ssn) ? '' : 'Invalid SSN. Format should be nnn-nn-nnnn.';
}
