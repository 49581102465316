import React, { useState, useEffect } from "react";
import { Card, CardContent, Snackbar, Alert, Box, CircularProgress } from "@mui/material";
import IntroCard from "./IntroCard";
import TiersCard from "./TiersCard";
import CurrentCashbackCard from "./CurrentCashbackCard";
import useCashbackUpdateUI from "../../../functional/molecules/useCashbackUpdateModalUI";

const MasterCardComponent = () => {
  const { currentCashback, openSnackbar, handleCloseSnackbar, isLoading, isSuccess, resetSuccess } = useCashbackUpdateUI();
  const [step, setStep] = useState("intro"); // Possible steps: intro, tiers, current

  useEffect(() => {
    setStep(currentCashback > 0 ? "current" : "intro");
  }, []);

  useEffect(() => {
    if (isSuccess) {
      const timer = setTimeout(() => {
        setStep("current");
        resetSuccess();
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [isSuccess, resetSuccess]);

  const handleGetStarted = () => setStep("tiers");
  const handleEdit = () => setStep("tiers");
  const handleBack = () => setStep(step === "tiers" ? (currentCashback > 0 ? "current" : "intro") : "tiers");

  return (
    <Card sx={{ margin: "auto", mt: 5, p: { xs: 2, sm: 4 },  backgroundColor: "transparent", borderRadius: 4, height: 600, mb: 4, display: "flex" ,justifyContent:"center", alignItems: "center"  }}>
      <CardContent>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" minHeight="300px">
            <CircularProgress />
          </Box>
        ) : (
          <>
            {step === "intro" && <IntroCard onGetStarted={handleGetStarted} />}
            {step === "tiers" && <TiersCard onSelectTier={() => setStep("current")} onBack={handleBack} />}
            {step === "current" && (
              <CurrentCashbackCard currentCashback={currentCashback} onEdit={handleEdit} onBack={handleBack} />
            )}
          </>
        )}
          <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: "100%" }}>
          Cashback offer updated successfully!
        </Alert>
      </Snackbar>
      </CardContent>


    </Card>
  );
};

export default MasterCardComponent;
