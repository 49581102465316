// src/components/BusinessMaster.js

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import BusinessStart from './start';
import BusinessInfoPageInputsUI from './businessInfoPageInputsUI';
import BusinessGeneralInfoPageInputsUI from './generalInfo';
import LoadingComponent from './loading';
import BusinessInfoCompleteComponent from './BusinessInfoCompleteComponent';
import { Box, useTheme } from '@mui/material';
import { selectBusinessType, selectBusinessLoading } from '../../../../../../features/verification/verificationInfoSelectors';
import { selectUserStatus } from '../../../../../../features/user/userSelectors';

const BusinessMaster = () => {
    const theme = useTheme();
    const businessType = useSelector(selectBusinessType);
    const businessLoading = useSelector(selectBusinessLoading);
    const userStatus = useSelector(selectUserStatus);
    const [started, setStarted] = useState(false);
  
    const handleGetStarted = () => {
      setStarted(true);
    };
  
    const handleBack = () => {
      setStarted(false);
    };
  
    return (
      <Box
        sx={{
          width: 600,
          border: `1px solid #E0E0E0`,
          borderRadius: '16px',
          padding: theme.spacing(4),
          textAlign: 'center',
          cursor: 'pointer',
          transition: 'box-shadow 0.3s ease, transform 0.3s ease',
          backgroundColor: '#FFFFFF',
          '&:hover': {
            boxShadow: '0 8px 30px rgba(0, 0, 0, 0.1)',
            transform: 'translateY(-4px)',
          },
        }}
      >
        {businessLoading ? (
          <LoadingComponent />
        ) : userStatus !== 'businessInfo' && userStatus !== 'notAuthenticated' ? (
          <BusinessInfoCompleteComponent />
        ) : (
          <>
            {!started && !businessType && <BusinessStart onGetStarted={handleGetStarted} />}
            {started && !businessType && <BusinessInfoPageInputsUI onBack={handleBack} />}
            {businessType && <BusinessGeneralInfoPageInputsUI />}
          </>
        )}
      </Box>
    );
  };
  
  export default BusinessMaster;