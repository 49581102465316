import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useDispatch, useSelector } from 'react-redux';
import { setPersonalOwnerStatus } from '../../../../../../features/verification/verificationInfoSlice';
import { selectPersonalInfo } from '../../../../../../features/verification/verificationInfoSelectors';

// Define the ownership status options
const options = [
  {
    value: false,
    label: 'I am not an owner of the business.',
  },
  {
    value: true,
    label: 'I am an owner, and there are other owners holding at least 25% each.',
  },
  {
    value: 'full_owner',
    label: 'I am the sole owner of the business.',
  },
];

// OptionCard Component for rendering each option
const OptionCard = ({ option, selected, onSelect }) => (
  <Card
    variant="outlined"
    sx={{
      mb: 2,
      borderColor: selected ? '#1976d2' : 'grey.300',
      backgroundColor: selected ? 'rgba(25, 118, 210, 0.05)' : '#fff',
      boxShadow: selected ? '0 4px 20px rgba(0, 0, 0, 0.1)' : 'none',
      transition: 'all 0.3s ease-in-out',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
        cursor: 'pointer',
      },
    }}
    onClick={onSelect}
    role="button"
    aria-pressed={selected}
  >
    <CardActionArea>
      <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
        {selected ? (
          <CheckCircleIcon sx={{ mr: 2, color: '#1976d2' }} />
        ) : (
          <RadioButtonUncheckedIcon sx={{ mr: 2, color: 'grey.500' }} />
        )}
        <Typography variant="body1">{option.label}</Typography>
      </CardContent>
    </CardActionArea>
  </Card>
);

const OwnerStatusPageInputsUI = ({ onBack }) => {
  const [selectedOwnerStatus, setSelectedOwnerStatus] = useState(null);
  const dispatch = useDispatch();

  // Retrieve personal information from Redux store
  const personalInfo = useSelector(selectPersonalInfo);
  const { first_name, last_name } = personalInfo;

  // Handle the submission of the selected owner status
  const handleSubmit = () => {
    if (selectedOwnerStatus !== null) {
      dispatch(setPersonalOwnerStatus(selectedOwnerStatus));
    }
  };

  return (
    <Box
      sx={{
        maxWidth: 500,
        width: '100%',
        mx: 'auto',
        textAlign: 'center',
 
      }}
    >
      {/* Header */}
      <Typography variant="h4" sx={{ mb: 2 }}>
        Ownership Status for {first_name} {last_name}, Account Administrator
      </Typography>
      {/* Subheader */}
      <Typography variant="subtitle1" sx={{ mb: 4, color: 'text.secondary' }}>
        Please select the ownership status of {first_name} {last_name}, the account administrator.
      </Typography>

      {/* Render the ownership status options */}
      {options.map((option) => (
        <OptionCard
          key={option.value}
          option={option}
          selected={selectedOwnerStatus === option.value}
          onSelect={() => setSelectedOwnerStatus(option.value)}
        />
      ))}

      {/* Action Buttons */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 4,
        }}
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={onBack}
          sx={{
            fontSize: '1rem',
            padding: '12px 24px',
            borderRadius: '8px',
            flex: 1,
            mr: 1,
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={selectedOwnerStatus === null}
          onClick={handleSubmit}
          sx={{
            fontSize: '1rem',
            padding: '12px 24px',
            borderRadius: '8px',
            flex: 1,
            ml: 1,
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default OwnerStatusPageInputsUI;
