import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Box,
  Grid,
  useTheme,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'; // Updated Icon
import { styled } from '@mui/system';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Custom Step Icon Component
const StepIcon = ({ completed }) => {
  return completed ? (
    <CheckCircleIcon color="success" />
  ) : (
    <VerifiedUserIcon color="action" />
  );
};

// Styled Button for consistent appearance
const CompleteButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 500,
  borderRadius: '8px',
}));

// Styled Activate Button
const ActivateButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 600,
  padding: '12px 24px',
  borderRadius: '8px',
}));

const SetupStepsModal = ({
  open,
  onClose,
  onComplete,
  accountActive,
  activationLoading,
  stepStatuses, // Step statuses passed from AccountManager
  onActivateAccount,
}) => {
  const theme = useTheme();

  // Define the five steps with hardcoded titles and descriptions
  const steps = [
    {
      title: 'Business Details Verified (KYC)',
      description: 'Ensure your business information is verified according to regulations.',
      status: stepStatuses.kyc,
    },
    {
      title: 'Setup Bank Account',
      description: 'Link your bank account to receive payments.',
      status: stepStatuses.bankConnected,
    },
    {
      title: 'Setup Location',
      description: 'Add your business location for customers to discover you in the app.',
      status: stepStatuses.locationVerified,
    },

  
  ];

  // Determine if all steps are completed
  const allStepsCompleted = steps.every((step) => step.status);

  // Function to render the Activation Panel content
  const renderActivationPanel = () => {
    if (allStepsCompleted) {
      if (accountActive) {
        return (
          <>
            <CheckCircleIcon
              sx={{
                fontSize: '72px',
                color: theme.palette.success.main,
                marginBottom: '24px',
              }}
            />
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                marginBottom: '16px',
                color: theme.palette.success.main,
              }}
            >
              Your account is active!
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: '#333333',
                marginBottom: '24px',
                maxWidth: '300px',
              }}
            >
              Your account is up and running. Start accepting transactions and watch your customer base grow!
            </Typography>
          </>
        );
      } else {
        return (
          <>
            <HourglassEmptyIcon
              sx={{
                fontSize: '72px',
                color: theme.palette.warning.main,
                marginBottom: '24px',
              }}
            />
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                marginBottom: '16px',
                color: theme.palette.warning.main,
              }}
            >
              Your account is ready to be activated!
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: '#333333',
                marginBottom: '24px',
                maxWidth: '300px',
              }}
            >
              Activate your account to start accepting transactions and engage your customers effectively.
            </Typography>
            <ActivateButton
              variant="contained"
              color="success"
              onClick={onActivateAccount}
              disabled={activationLoading} // Disable button during activation
            >
              {activationLoading ? 'Activating...' : 'Activate Account'}
            </ActivateButton>
          </>
        );
      }
    } else {
      const completedSteps = steps.filter((step) => step.status).length;
      const totalSteps = steps.length;
      return (
        <>
          <VerifiedUserIcon
            sx={{
              fontSize: '72px',
              color: '#e0e0e0',
              marginBottom: '24px',
            }}
          />
          <Typography
            variant="h6"
            sx={{
              fontWeight: 600,
              marginBottom: '16px',
              color: '#555555',
            }}
          >
            Complete All Setup Steps
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ maxWidth: '300px' }}
          >
            You have completed {completedSteps} of {totalSteps} steps. Finish setting up your account to activate it.
          </Typography>
        </>
      );
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle sx={{ fontWeight: '600', fontSize: '1.8rem', textAlign: 'center' }}>
        Account Setup Steps
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={4}>
          {/* Left Side: Setup Steps */}
          <Grid item xs={12} md={6}>
            <Stepper orientation="vertical" nonLinear>
              {steps.map((step, index) => (
                <Step key={index} active={!step.status}>
                  <StepLabel StepIconComponent={() => <StepIcon completed={step.status} />}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Box>
                        <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                          {step.title}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {step.description}
                        </Typography>
                      </Box>
                      {!step.status && (
                        <Box sx={{ marginLeft: 2 }}>
                          <CompleteButton
                            variant="contained"
                            color="primary"
                            size="small"
                            endIcon={<ArrowForwardIosIcon />}
                            onClick={() => onComplete(index)}
                          >
                            Complete
                          </CompleteButton>
                        </Box>
                      )}
                    </Box>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>

          {/* Right Side: Activation Panel */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                border: '1px solid #e0e0e0',
                borderRadius: '16px',
                padding: '32px',
                textAlign: 'center',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: theme.shadows[2],
                backgroundColor: '#ffffff',
              }}
            >
              {renderActivationPanel()}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px' }}>
        <Button onClick={onClose} color="primary" variant="outlined" sx={{ borderRadius: '8px' }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SetupStepsModal;
