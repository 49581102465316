import { useState } from "react";
import { employeeConstant } from "../constant/employeeConstant";
import useDeleteDialog from "../molecules/useDeleteDailog";

const EmployeeManagementLogic = () => {
  const [showInfoDailog, setShowInfoDailog] = useState(false);

  const handleOpenInfoDailog = () => {
    setShowInfoDailog(true);
  };

  const handleCloseInfoDailog = () => {
    setShowInfoDailog(false);
  };

  const {
    handleOpenDeleteDialog,
    handleCloseDeleteDialog,
    showDeleteDialog,
    handleDelete,
  } = useDeleteDialog();

  return {
    handleOpenInfoDailog,
    handleCloseInfoDailog,
    showInfoDailog,
    handleOpenDeleteDialog,
    handleCloseDeleteDialog,
    showDeleteDialog,
    employeeConstant,
    handleDelete,
  };
};

export default EmployeeManagementLogic;
