// src/features/loading/loadingSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dialogueLoader: { isLoading: false, message: '' },
  pageLoader: { isLoading: false, message: '' },
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    // Generic action to activate any loader with a message
    activateLoader(state, action) {
      const { loaderType, message } = action.payload;
      state[loaderType].isLoading = true;
      state[loaderType].message = message;
    },
    // Generic action to deactivate any loader and clear the message
    deactivateLoader(state, action) {
      const  loaderType  = action.payload;
      state[loaderType].isLoading = false;
      state[loaderType].message = '';
    },
  },
});

export const { activateLoader, deactivateLoader } = loadingSlice.actions;

export default loadingSlice.reducer;
