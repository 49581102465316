import useDocumentTypeField from "../molecules/useDocumentTypeField";
import useUploadField from "../molecules/useUploadField";
import useDocumentManager from "../organisms/useDocumentManager";
import useDocumentUploadField from "../organisms/useDocumentUploadField";


const useDocument = () => {
  const {
    onDocumentUpload,
    isAllDocumentsUploaded,
    documentList,
    setSelectedDocument,
    selectedDocument: documentDetails,
    setDocumentList,
  } = useDocumentManager();

  const { handleDocumentUpload, type, setType, setFile } =
    useDocumentUploadField({
      documentDetails,
      setDocumentList,
      setSelectedDocument,
    });

  const {
    handleFileChange,
    onClick: documentUploadFieldOnClick,
    documentName,
  } = useUploadField({
    onFileSelect: (selectedFile) => {
      setFile(selectedFile);
    },
  });

  const { onClick: documentTypeFieldOnClick } = useDocumentTypeField({
    document: documentDetails?.document,
  });

  return {
    documentDetails,
    setDocumentList,
    setSelectedDocument,
    handleDocumentUpload,
    type,
    setType,
    setFile,

    onDocumentUpload,
    isAllDocumentsUploaded,
    documentList,

    documentTypeFieldOnClick,
    documentUploadFieldOnClick,

    handleFileChange,
    documentName,
  };
};

export default useDocument;
