import React, { useState, useCallback, useEffect } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  Typography,
  IconButton,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CloseIcon from "@mui/icons-material/Close";
import GeneralButton from "../../components/atoms/buttons/GeneralButton";
import GeneralInput from "../../components/atoms/inputs/GeneralInput";
import checkIcon from "../../images/check-icon.png";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import useFullFetchFeedback from "../../functions/apiFetchers/fullPageLoaders";
import { useDispatch, useSelector } from "react-redux";
import { addStoreLocation } from "../../features/stores/storesSlice";
import { selectAllStoreLocations } from "../../features/stores/storesSelectors";

const LocationModal = ({ open, onClose }) => {
    const locationList = useSelector(selectAllStoreLocations);
    const phoneOptions = locationList.map((loc) => ({ label: loc.number, value: loc.number }));
    const emailOptions = locationList.map((loc) => ({ label: loc.email, value: loc.email }));
    const websiteOptions = locationList.map((loc) => ({ label: loc.website, value: loc.website }));
    const MAX_DESCRIPTION_LENGTH = 50; // Adjust as needed

    const descriptionOptions = locationList.map((loc) => ({
      label:
        loc.description.length > MAX_DESCRIPTION_LENGTH
          ? `${loc.description.substring(0, MAX_DESCRIPTION_LENGTH)}...`
          : loc.description,
      value: loc.description,
    }));
    const existingLocationName = locationList.length > 0 ? locationList[0].name : null;
  const [steps, setSteps] = useState(1);
  const fetchFeedback = useFullFetchFeedback();
  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    website: "",
    description: "",
    type: "",
    address: "",
    storePhoto: null,
  });
  useEffect(() => {
    if (!open) {
      // Reset form data when the modal is closed
      setFormData({
        name: "",
        number: "",
        email: "",
        website: "",
        description: "",
        type: "",
        address: "",
        storePhoto: null,
      });
    } else {
      // Populate only the name field when the modal opens
      const existingLocationName = locationList.length > 0 ? locationList[0].name : null;
      setFormData((prev) => ({
        ...prev,
        name: existingLocationName || "", // Update only the name field
      }));
    }
  }, [open, locationList]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const handleChange = (event) => {

    const { name, value } = event.target;
    console.log(name, value);
    console.log(formData);
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateStep = () => {
    const newErrors = {};
    if (steps === 1) {
      if (!formData.name) newErrors.name = "Location Name is required";
      if (!formData.type) newErrors.type = "Store Type is required";
        if (!formData.description) newErrors.description = "Description is required";
    }
    if (steps === 2) {
      if (!formData.address) newErrors.address = "Address is required";
      if (!formData.number) newErrors.number = "Phone Number is required";
      if (!formData.email) newErrors.email = "Email is required";
        if (!formData.website) newErrors.website = "Website is required";
    }
    if (steps === 3) {
      if (!selectedImage) newErrors.storePhoto = "Photo is required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep()) {
      if (steps === 3) {
        createLocation();
      } else {
        setSteps((prevSteps) => prevSteps + 1);
      }
    }
  };

  const createLocation = async () => {
    const submissionData = {
      ...formData,
      storePhoto: selectedImage,
    };

    await fetchFeedback({
      endpoint: `merchant/locations/`,
      data: submissionData,
      loaderMessage: "Saving location details...",
      successMessage: "Location details saved successfully!",
      requestType: "POST",
      loaderType: "dialogueLoader",
      onSuccess: (responseData) => {
        dispatch(addStoreLocation(responseData));
        handleLocationAdded();
      },
      onError: (error) => {
        console.error("Error submitting location:", error);
      },
    });
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => setSelectedImage(reader.result);
      reader.readAsDataURL(file);
    }
  }, []);

  const handleRemoveImage = () => setSelectedImage(null);

  const handleLocationAdded = () => {
    onClose();
    resetForm();
  };

  const resetForm = () => {
    setSteps(1);
    setFormData({
      name: "",
      number: "",
      email: "",
      website: "",
      description: "",
      type: "",
      address: "",
      storePhoto: null,
    });
    setSelectedImage(null);
    setErrors({});
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
  });

  const progressValue = (steps / 3) * 100;


  const renderForm = () => {
    switch (steps) {
      case 1:
        return (
          <>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{
                color: "text.primary",
                marginTop: 2,
                fontSize: "1.5rem",
                fontFamily: "Epilogue, sans-serif",
                lineHeight: "1.5rem",
              }}
            >
              Basic Details
            </Typography>
            <Typography
              variant="body2"
              sx={{
                marginTop: 1,
                color: "text.secondary",
                fontSize: "1rem",
                fontFamily: "Epilogue, sans-serif",
                lineHeight: "2rem",
                mb: 2,
              }}
            >
              Provide key details about your store to help customers identify it
              easily.
            </Typography>
            <Box sx={{height: 87, width: "100%" }}>
            <GeneralInput
    type="text"
    label="Location Name"
    name="name"
    value={existingLocationName || formData.name} // Use existing name if available
    error={!!errors.name}
    errorMessageAndhelp={
      existingLocationName
        ? "A location already exists. Name is pre-filled and cannot be changed."
        : errors.name
    }
    onChange={handleChange}
    disabled={!!existingLocationName} // Disable if an existing name is used
  />
            </Box>
              <Box sx={{height: 87, width: "100%" }}>
            <GeneralInput
              type="autocomplete"
              label="Select Store Type"
              name="type"
              error={!!errors.type}
              errorMessageAndhelp={errors.type}
              value={formData.type}
  
              onChange={handleChange}
              options={[
                { label: "Other", value: "Other" },
                { label: "Retail", value: "Retail" },
                { label: "Wholesale", value: "Wholesale" },
                { label: "Cafe", value: "Cafe" },
                { label: "Boutique", value: "Boutique" },
                { label: "Bookstore", value: "Bookstore" },
              ]}
            />
            </Box>
              <Box sx={{height: 87, width: "100%" }}>
              <GeneralInput
      type="optionOrInput"
      label="Enter Description"
      name="description"
      value={formData.description}
      error={!!errors.description}
      errorMessageAndhelp={errors.description}
      onChange={handleChange}
      options={descriptionOptions}
      multiline={true}


     
    />
            </Box>
          </>
        );
      case 2: 
        return (
          <>
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{
                color: "text.primary",
                marginTop: 2,
                fontSize: "1.5rem",
                fontFamily: "Epilogue, sans-serif",
                lineHeight: "1.5rem",
              }}
            >
              Add Contact Information
            </Typography>
            <Typography
              variant="body2"
              sx={{
                marginTop: 1,
                color: "text.secondary",
                fontSize: "1rem",
                fontFamily: "Epilogue, sans-serif",
                lineHeight: "2rem",
                mb: 2,
              }}
            >
              Share the contact details so customers can reach you effortlessly.
            </Typography>
            <Box sx={{height: 87, width: "100%" }}>
            <GeneralInput
              type="address"
              label="Enter Address"
              name="address"
              value={formData.address}
              error={!!errors.address}
              errorMessageAndhelp={errors.address}
            
              onChange={handleChange}
            />
            </Box>
            <Box sx={{ height: 87, width: "100%" }}>
      <GeneralInput
        type="optionOrInput"
        label="Enter Phone Number"
        name="number"
        value={formData.number}
        error={!!errors.number}
        errorMessageAndhelp={errors.number}
        onChange={handleChange}
        options={phoneOptions}
        commonStyles={{
          margin: "0 0 1rem 0",
        }}
      />
    </Box>
    <Box sx={{ height: 87, width: "100%" }}>
     <GeneralInput
  type="optionOrInput"
  label="Enter Email Address"
  name="email"
  value={formData.email || ""} // Ensure this always reflects formData.email
  error={!!errors.email}
  errorMessageAndhelp={errors.email}
  onChange={handleChange}
  options={emailOptions} // Correct options for email
/>
    </Box>
    <Box sx={{ height: 87, width: "100%" }}>
      <GeneralInput
        type="optionOrInput"
        label="Enter Your Website"
        name="website"
        value={formData.website}
        error={!!errors.website}
        errorMessageAndhelp={errors.website}
        onChange={handleChange}
        options={websiteOptions}
      />
    </Box>
          </>
        );
    case 3:
  return (
    <>
      <Typography
        variant="h5"
        fontWeight="bold"
        sx={{
          color: "text.primary",
          marginTop: 2,
          fontSize: "1.5rem",
          fontFamily: "Epilogue, sans-serif",
          lineHeight: "1.5rem",
        }}
      >
        Upload Store Photo
      </Typography>
      <Typography
        variant="body2"
        sx={{
          marginTop: 1,
          color: "text.secondary",
          fontSize: "1rem",
          fontFamily: "Epilogue, sans-serif",
          lineHeight: "2rem",
          mb: 2,
        }}
      >
        Add a clear photo of your store to make it recognizable to your
        customers.
      </Typography>

      <Box sx={{ mb: 2 }}>
        <Typography variant="body2" sx={{ fontWeight: "bold", mb: 1 }}>
          Or select a photo from an existing location:
        </Typography>
        {locationList.map((loc) => (
          loc.storePhoto && (
            <Box
              key={loc.id}
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                cursor: "pointer",
                backgroundColor: selectedImage === loc.storePhoto ? "#F0FFF4" : "transparent",
                border: selectedImage === loc.storePhoto ? "2px solid #1BC575" : "2px solid transparent",
                borderRadius: 2,
                padding: 1,
                transition: "background-color 0.3s, border 0.3s",
              }}
              onClick={() => setSelectedImage(loc.storePhoto)}
            >
              <img
                src={loc.storePhoto}
                alt={loc.address}
                style={{ width: 80, height: 80, borderRadius: 8, marginRight: 16 }}
              />
              <Typography
                variant="body2"
                sx={{ fontSize: "1rem", color: "text.primary", fontWeight: "bold" }}
              >
                {loc.address}
              </Typography>
            </Box>
          )
        ))}
      </Box>

      <Box
        sx={{
          border: "2px dashed",
          borderColor: selectedImage ? "transparent" : "primary.main",
          borderRadius: 2,
          padding: selectedImage ? 0 : 5,
          textAlign: "center",
          backgroundColor: "#F5FBF9",
          cursor: "pointer",
          transition: "background-color 0.2s ease",
          width: "100%",
          position: "relative",
        }}
      >
        {selectedImage ? (
          <Box sx={{ position: "relative", textAlign: "center" }}>
            <img
              src={selectedImage}
              alt="Uploaded Preview"
              style={{
                width: "100%",
                height: "200px",
                borderRadius: "8px",
              }}
            />
            <IconButton
              onClick={handleRemoveImage}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                backgroundColor: "rgba(255, 255, 255, 0.7)",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <Box {...getRootProps()}>
            <input {...getInputProps()} accept="image/*" />
            <CloudUploadOutlinedIcon
              sx={{ fontSize: 48, color: "primary.main" }}
            />
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                color: "text.primary",
                marginTop: 2,
                fontFamily: "Epilogue, sans-serif",
                fontSize: "1rem",
              }}
            >
              Drag & drop files or{" "}
              <Typography
                component="span"
                sx={{
                  color: "primary.main",
                  textDecoration: "underline",
                  fontFamily: "Epilogue, sans-serif",
                  fontSize: "1rem",
                  fontWeight: "bold",
                }}
              >
                Browse
              </Typography>
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "text.secondary", marginTop: 1 }}
            >
              Upload your store photo here
            </Typography>
          </Box>
        )}
      </Box>
    </>
  );
      case 4:
        return (
          <>
            <Box
              component="img"
              src={checkIcon}
              alt="Location"
              sx={{
                display: "block",
                mb: 3,
              }}
            />
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{
                color: "text.primary",
                marginTop: 2,
                fontSize: "1.5rem",
                fontFamily: "Epilogue, sans-serif",
                lineHeight: "1.5rem",
              }}
            >
              Location Added Successfully!
            </Typography>
            <Typography
              variant="body2"
              sx={{
                marginTop: 1,
                color: "text.secondary",
                fontSize: "1rem",
                fontFamily: "Epilogue, sans-serif",
                lineHeight: "2rem",
                mb: 2,
              }}
            >
              Your store location is now live pending our review. Add more locations or view your
              list.
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                flexWrap: "wrap",
              }}
            >
              <GeneralButton
                label={
                  <>
                    Add New Location
                    <ArrowOutwardOutlinedIcon style={{ marginLeft: "8px" }} />
                  </>
                }
                type="circularIcon"
                variant="outlined"
                onClick={handleLocationAdded}
                sx={{
                  margin: "auto",
                  marginTop: "1rem",
                }}
              />
              <GeneralButton
                label={
                  <>
                    View locationList
                    <ArrowOutwardOutlinedIcon style={{ marginLeft: "8px" }} />
                  </>
                }
                type="circularIcon"
                variant="outlined"
                onClick={handleLocationAdded}
                sx={{
                  margin: "auto",
                  marginTop: "1rem",
                }}
              />
            </Box>
          </>
        );
      default:
        return (
          <Typography
            variant="body1"
            sx={{ color: "text.secondary", marginTop: 2 }}
          >
            No content available for this step.
          </Typography>
        );
    }
  };
  return (
    <Dialog
    open={open}
    onClose={() => {
        onClose();
      setSteps(1);
      setSelectedImage(null);
    }}
    sx={{
      "& .MuiDialog-paper": {
        padding: 4,
        borderRadius: 2,
        width: "100%",
        textAlign: "center",
        // minHeight: "38rem",
      },
    }}
  >
    {steps <= 3 && (
      <Box sx={{ width: "100%", paddingX: 2 }}>
        <LinearProgress
          variant="determinate"
          value={progressValue}
          sx={{
            height: 8,
            borderRadius: 5,
            "& .MuiLinearProgress-bar": {
              backgroundColor: "primary.main",
            },
          }}
        />
      </Box>
    )}
    <DialogContent
      sx={{
        color: "text.secondary",
        paddingY: "10px",
        letterSpacing: "0.5px",
        width: "100%",
        lineHeight: "1.5",
        margin: "auto",
        textAlign: "center",
        fontSize: "1rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {renderForm()}
    </DialogContent>
    {steps <= 3 && (
      <DialogActions>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
            textColor: "text.secondary",
            paddingX: 2,
          }}
        >
          <GeneralButton
            label={
              <>
                {steps === 3 ? "Submit" : "Next"}
                <ArrowOutwardOutlinedIcon style={{ marginLeft: "8px" }} />
              </>
            }
            type="circularIcon"
            variant="outlined"
            onClick={handleNext}
            sx={{
              margin: "auto",
              marginTop: "1rem",
              width: "100%",
            }}
          />
        </Box>
      </DialogActions>
    )}
  </Dialog>
  );
};

export default LocationModal;