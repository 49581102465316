import React, { useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import RetryIcon from '@mui/icons-material/Replay';
import DocumentIcon from '@mui/icons-material/Description';
import DeniedIcon from '@mui/icons-material/Cancel';
import VerifiedIcon from '@mui/icons-material/CheckCircle';
import { useDispatch, useSelector } from 'react-redux';
import { selectVerificationstatus } from '../../../../../features/verification/verificationInfoSelectors';
import { setStatus } from '../../../../../features/user/userSlice';
import { setDocumentData, setRetryData, setVerificationstatus, ResetVerificationstatus } from '../../../../../features/verification/verificationInfoSlice';
import useFullFetchFeedback from '../../../../../functions/apiFetchers/fullPageLoaders';
import { showAlert } from '../../../../../features/alerts/alertSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '80%', sm: 400 },
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  outline: 'none',
  textAlign: 'center',
};

const iconStyles = {
  fontSize: 60,
  mb: 2,
  display: 'block',
  mx: 'auto',
};

const getContent = (state) => {
  switch (state) {
    case 'loading':
      return {
        icon: <CircularProgress size={60} />,
        title: 'Verifying Your Identity',
        description: 'Please wait while we verify your identity with our partners.',
        showClose: false,
      };
    case 'retry':
      return {
        icon: <RetryIcon color="error" sx={iconStyles} />,
        title: 'Verification Failed',
        description: 'There was an issue verifying your identity. Please try again.',
        showClose: true,
      };
    case 'documentpending':
      return {
        icon: <DocumentIcon color="info" sx={iconStyles} />,
        title: 'Document Pending',
        description: 'Your documents are under review. We will notify you once approved.',
        showClose: true,
      };
    case 'denied':
      return {
        icon: <DeniedIcon color="error" sx={iconStyles} />,
        title: 'Verification Denied',
        description: 'We were unable to verify your identity. Please contact support for assistance.',
        showClose: true,
      };
    case 'certified':
      return {
        icon: <VerifiedIcon color="success" sx={iconStyles} />,
        title: 'Verification Successful',
        description: 'Your identity has been successfully verified.',
        showClose: true,
      };
    case 'verifyPending':
      return {
        icon: <DocumentIcon color="info" sx={iconStyles} />,
        title: 'Verification in Progress',
        description: 'Your verification is currently pending. We will notify you once completed.',
        showClose: true,
      };
    case 'complete':
      return {
        icon: <VerifiedIcon color="success" sx={iconStyles} />,
        title: 'Verification Complete',
        description: 'Your verification process is complete. Thank you!',
        showClose: true,
      };
    case 'verifySuspended':
      return {
        icon: <DeniedIcon color="error" sx={iconStyles} />,
        title: 'Verification Suspended',
        description: 'Your verification has been suspended. Please contact support.',
        showClose: true,
      };
    case 'document':
      return {
        icon: <DocumentIcon color="info" sx={iconStyles} />,
        title: 'Additional Document Required',
        description: 'Please upload the necessary document for verification to proceed.',
        showClose: true,
      };
    default:
      return {
        icon: null,
        title: '',
        description: '',
        showClose: false,
      };
  }
};

const VerificationModal = () => {
  const dispatch = useDispatch();
  const verificationState = useSelector(selectVerificationstatus);
  const fetchFeedback = useFullFetchFeedback();

  const fetchVerificationStatus = async () => {
    await fetchFeedback({
      endpoint: 'merchant/verification-status/',
      requestType: 'GET',
      onSuccess: (responseData) => {
        switch (responseData.status) {
          case 'documentPending':
            dispatch(setVerificationstatus('verifyPending'));
            break;
          case 'certified':
            dispatch(setVerificationstatus('complete'));
            break;
          case 'retry':
            dispatch(setVerificationstatus('retry'));
            dispatch(setRetryData({
              retry_controller_data: responseData?.retry_controller_data,
              retry_business_data: responseData?.retry_business_data,
              owner_retry_data: responseData?.owner_retry_data,
            }));
            break;
          case 'document':
            dispatch(setVerificationstatus('document'));
            dispatch(setDocumentData(responseData?.documents));
            break;
          case 'suspended':
            dispatch(setVerificationstatus('verifySuspended'));
            break;
          default:
            console.warn('Unknown status:', responseData.status);
            dispatch(showAlert({
              type: 'warning',
              message: 'Unrecognized verification status.',
            }));
            break;
        }
      },
      onError: (err) => {
        console.error('Error fetching verification status:', err);
        dispatch(showAlert({
          type: 'warning',
          message: 'We are encountering an error. Try again later or contact customer service.',
        }));
      },
    });
  };

  useEffect(() => {
    if (verificationState === 'loading') {
      fetchVerificationStatus();
    }
  }, [verificationState]);

  const open = [
    'loading', 'retry', 'documentpending', 'denied', 'certified', 'verifyPending', 
    'complete', 'verifySuspended', 'document'
  ].includes(verificationState);

  const content = getContent(verificationState);

  const handleClose = () => {
    if (verificationState !== 'loading') {
      dispatch(setStatus(verificationState));
      dispatch(ResetVerificationstatus());
    }
  };

  const modalProps = {
    open: open,
    onClose: verificationState !== 'loading' ? handleClose : null,
    'aria-labelledby': 'verification-modal-title',
    'aria-describedby': 'verification-modal-description',
    disableEscapeKeyDown: verificationState === 'loading',
    hideBackdrop: false,
  };

  return (
    <Modal {...modalProps}>
      <Box sx={style}>
        {content.icon}
        <Typography id="verification-modal-title" variant="h6" component="h2" align="center">
          {content.title}
        </Typography>
        <Typography id="verification-modal-description" sx={{ mt: 2 }} align="center">
          {content.description}
        </Typography>
        {content.showClose && (
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
            <Button variant="outlined" color="primary" onClick={handleClose}>
              Continue
            </Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default VerificationModal;
