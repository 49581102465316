// SuccessModal.js

import React from "react";
import { Modal, Backdrop, Fade, Box, Typography, Button } from "@mui/material";
import { CheckCircleOutline as SuccessIcon } from "@mui/icons-material";

const SuccessModal = ({ open, onClose }) => {
  const brandColor = "#1EC674"; // Your main brand color

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      aria-labelledby="success-modal-title"
      aria-describedby="success-modal-description"
    >
      <Fade in={open}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#FFFFFF",
            p: 4,
            outline: "none",
            borderRadius: 2,
            textAlign: "center",
            minWidth: 300,
          }}
        >
          <SuccessIcon sx={{ fontSize: 60, color: brandColor }} />
          <Typography id="success-modal-title" variant="h5" mt={2}>
            Bank Account Connected
          </Typography>
          <Typography id="success-modal-description" variant="body1" mt={1}>
            Your bank account has been successfully connected.
          </Typography>
          <Button
            variant="contained"
            onClick={onClose}
            sx={{
              mt: 3,
              backgroundColor: brandColor,
              color: "#FFFFFF",
              borderRadius: 8,
              px: 4,
              py: 1,
              "&:hover": {
                backgroundColor: "#17a35a",
              },
            }}
          >
            Close
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};

export default SuccessModal;
