// validateAddress.js
// Validates an address object containing street, city, state, and zipCode
// If `separate` is true, returns an object with individual error messages.
// Otherwise, returns a single concatenated error string or nothing if no errors.

export function validateAddress(FullAddress, separate = false) {
    
    // Destructure the address object into its individual components
    const { address, city, state, zip_code } = FullAddress;
    
    const errors = {};

    // Check each field and add an error message if the field is empty
    if (!address) errors.street = 'Street is required.';
    if (!city) errors.city = 'City is required.';
    if (!state) errors.state = 'State is required.';
    if (!zip_code) errors.zipCode = 'Zip Code is required.';

    // Check if there are no errors
    const noErrors = Object.keys(errors).length === 0;

    // Return an object or a string depending on `separate` argument
    if (separate) {
        // If `separate` is true, return an object containing individual error messages
        return noErrors ? {} : errors;
    } else {
        // Concatenate errors into a single string

        return noErrors ? '' : "Full valid address is required";
    }
}
