import PropTypes from 'prop-types';
import React from 'react';

import { useSelector } from "react-redux";


// material-ui
import { Avatar, Box, Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
    selectDashboardData,
  
  } from "../../../../../features/dashboard/dashboardSelectors";
// project imports
import MainCard from 'ui-component/cards/MainCard';
import SkeletonTotalOrderCard from 'ui-component/cards/Skeleton/EarningCard';

// assets
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';

const CardWrapper = styled(MainCard)({
    backgroundColor: '#FFFFFF',
    color: '#333',
    overflow: 'hidden',
    position: 'relative',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '12px',
});
const TotalUsersCard = ({ isLoading }) => {
    const [timeValue, setTimeValue] = React.useState(false);
    const { totalUsers } = useSelector(selectDashboardData);

    const handleChangeTime = (event, newValue) => {
        setTimeValue(newValue);
    };

    // Dynamically determine the total users based on timeValue (true for month, false for year)
    const userTotal = timeValue ? totalUsers.month : totalUsers.year;
    const displayUserTotal = !isNaN(userTotal) ? userTotal : 0;

    return (
        <>
            {isLoading ? (
                <SkeletonTotalOrderCard />
            ) : (
                <CardWrapper border={false} content={false}>
                    <Box sx={{ p: 2.25 }}>
                        <Grid container direction="column">
                            <Grid item>
                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        <Avatar
                                            variant="rounded"
                                            sx={{
                                                mt: 1,
                                                backgroundColor: '#F0F0F5',
                                                color: '#606060', // Subtle icon color
                                            }}
                                        >
                                            <LocalMallOutlinedIcon fontSize="inherit" />
                                        </Avatar>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            disableElevation
                                            variant={timeValue ? 'contained' : 'text'}
                                            size="small"
                                            sx={{
                                                color: timeValue ? '#606060' : '#B0B0B0', // Active: medium grey, Inactive: light grey
                                                fontWeight: timeValue ? 'bold' : 'normal',
                                                borderRadius: '4px',
                                            }}
                                            onClick={(e) => handleChangeTime(e, true)}
                                        >
                                            Month
                                        </Button>
                                        <Button
                                            disableElevation
                                            variant={!timeValue ? 'contained' : 'text'}
                                            size="small"
                                            sx={{
                                                color: !timeValue ? '#606060' : '#B0B0B0',
                                                fontWeight: !timeValue ? 'bold' : 'normal',
                                                borderRadius: '4px',
                                            }}
                                            onClick={(e) => handleChangeTime(e, false)}
                                        >
                                            Year
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item sx={{ mb: 0.75, mt: 1 }}>
                                <Typography sx={{ fontSize: '2.125rem', fontWeight: 500, color: '#333' }}>
                                    {displayUserTotal.toLocaleString()}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 500,
                                        color: '#8E8E93',
                                    }}
                                >
                                    Total Users ({timeValue ? 'Last Thirty Days' : 'Last Year'})
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </CardWrapper>
            )}
        </>
    );
};

TotalUsersCard.propTypes = {
    isLoading: PropTypes.bool
};

export default TotalUsersCard;