import React from "react";
import ContainedButton from "./components/ContainedButton";
import OutlinedButton from "./components/OutlinedButton";
import IconButton from "./components/IconButton";
import PlainTextButton from "./components/PlainTextButton";
import UploadPhotoButton from "./components/UploadPhotoButton";
import CircularIconButton from "./components/CircularIconButton";


// Define the default size styles
const sizeStyles = {
    xs: { fontSize: "0.625rem", padding: "4px 8px" }, // Extra small
    small: { fontSize: "0.875rem", padding: "6px 14px" }, // Small
    medium: { fontSize: "1rem", padding: "8px 18px" }, // Medium
    large: { fontSize: "1.125rem", padding: "10px 25px" }, // Large
};

const GeneralButton = ({
  label,
  onClick,
  type = "contained",
  disabled = false,
  iconName = "",
  size = "",
  center = false,
  expandable = false,
  sx = {},
}) => {
    const centering = center ? { margin: "auto" } : {};

    // Determine the styles based on size
    const sizeSx = sizeStyles[size] || {};
  
    // Combine size styles with custom styles and centering styles
    const finalSx = {
      ...sizeSx,
      ...centering,
      ...sx,
     
    };
  
  const buttonProps = {
    label,
    onClick,
    disabled,
    importedSx: finalSx,
    fullWidth: expandable,  
  };

  if (type === "icon" || type === "circular-icon") {
    buttonProps.iconName = iconName;
  }

  switch (type) {
    case "contained":
      return <ContainedButton {...buttonProps} />;
    case "outlined":
      return <OutlinedButton {...buttonProps} />;
    case "PlainText":
      return <PlainTextButton {...buttonProps} />;
    case "icon":
      return <IconButton {...buttonProps} />;
    case "circularIcon":
      return <CircularIconButton {...buttonProps} />;
    case "upload-photo":
      return <UploadPhotoButton {...buttonProps} />;
    default:
      console.warn(`Unknown button type: ${type}`);
      return null;
  }
};

export default GeneralButton;
