import React from "react";
import Button from "@mui/material/Button";

const CircularIconButton = ({
  label,
  onClick,
  disabled,
  importedSx,
  fullWidth,
}) => {
  return (
    <Button
      onClick={onClick}
      variant="text"
      disabled={disabled}
      fullWidth={fullWidth}
      sx={{
        ...importedSx,
        fontFamily: "SF Pro Display, Arial, sans-serif",
        fontSize: "0.875rem",
        fontWeight: 500,
        textTransform: "none",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "8px 16px",
        border: "1px solid",
        borderColor: "primary.main",
        borderRadius: "25px",
        color: "primary.main",
        backgroundColor: "transparent",
        cursor: "pointer",
        textAlign: "center",
        "&:hover": {
          backgroundColor: "primary.light",
          borderColor: "primary.dark",
          color: "secondary.light",
        },
        "@media (max-width: 768px)": {
          fontSize: "0.7rem",
          padding: "4px 8px",
        },
        "&:disabled": {
          color: "#aeaeae",
          borderColor: "#aeaeae",
        },
      
      }}
    >
      {label}
    </Button>
  );
};

export default CircularIconButton;
