import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useFullFetchFeedback from "../../../../../../../functions/apiFetchers/fullPageLoaders";
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from "../../../../../../../features/alerts/alertSlice";

import { setDocumentLoading, setVerificationstatus } from "../../../../../../../features/verification/verificationInfoSlice";
import { selectDocumentData } from '../../../../../../../features/verification/verificationInfoSelectors';
const useDocumentManager = () => {


  const documents = useSelector(selectDocumentData);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [documentList, setDocumentList] = useState(documents);
  const fetchFeedback = useFullFetchFeedback();
 
  const dispatch = useDispatch();

  const isAllDocumentsUploaded = documentList?.every((doc) => doc.isCompleted);



  useEffect(() => {
    const invalidDocuments = documents?.filter(
      (doc) => doc.errors && doc.errors.length > 0
    );
    if (invalidDocuments?.length > 0) {
      dispatch(
        showAlert({
          type: "error",
          message:
            "Some of your documents were invalid. We need you to update them for verification.",
        })
      );
    }
  }, []);

  const onDocumentUpload = async (event) => {
    try {
      await fetchFeedback({
        endpoint: "merchant/document/upload/",
        data: documentList,
        setLoader: (isLoading) => dispatch( setDocumentLoading(isLoading)),
       
        requestType: "POST",
        onSuccess: (responseData) => {
    
          dispatch(setVerificationstatus("loading"));
        },
        onError: (error) => {
          console.error("Upload error:", error);
        },
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return {
 
    onDocumentUpload,
    isAllDocumentsUploaded,
    documentList,
    setSelectedDocument,

    selectedDocument,
    setDocumentList
  };

}

export default useDocumentManager