import React, { useState } from "react";
import {
  Typography,
  Button,
  Box,
  Paper,
  Modal,
  Stack,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import { keyframes } from "@mui/system";
import CostCalculatorModal from "./CostCalculatorModal";

// Define a pulse animation for the icon
const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
`;

// Define Cashback Tiers
const cashbackTiers = [
  {
    name: "Standard",
    min: 5,
    max: 9.99,
    description:
      "A solid cashback rate that encourages regular customer engagement.",
  },
  {
    name: "Premium",
    min: 10,
    max: 14.99,
    description:
      "An attractive rate that significantly boosts customer loyalty.",
  },
  {
    name: "Elite",
    min: 15,
    max: Infinity,
    description:
      "Top-tier cashback rate that maximizes customer retention and satisfaction.",
  },
  {
    name: "Basic",
    min: 0,
    max: 4.99,
    description:
      "Your cashback rate is below the standard level. Consider increasing to attract more customers.",
  },
];

const CurrentCashbackCard = ({ currentCashback, onEdit }) => {
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // Determine the current tier based on cashback value
  const currentTier =
    cashbackTiers.find(
      (tier) => currentCashback >= tier.min && currentCashback <= tier.max
    ) || cashbackTiers[0]; // Default to the first tier if not found

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          padding: { xs: 4, sm: 6 },
          borderRadius: 4,
          maxWidth: 1200,
          margin: "auto",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={4}
          alignItems="stretch"
          justifyContent="space-between"
        >
          {/* Left Side - Current Cashback */}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              borderRight: { md: "1px solid #E0E0E0" },
              paddingRight: { md: 4 },
              marginBottom: { xs: 4, md: 0 },
            }}
          >
            <Typography variant="h4" fontWeight="bold" gutterBottom>
              Your Current Cashback Rate
            </Typography>

            <Typography
              variant="h1"
              fontWeight="bold"
              sx={{ fontSize: "4rem", mb: 2 }}
            >
              {currentCashback}%
            </Typography>

            <Typography variant="h5" fontWeight="600" gutterBottom>
              {currentTier.name} Tier
            </Typography>

            <Typography variant="body1" color="textSecondary" sx={{ mb: 3 }}>
              {currentTier.description}
            </Typography>

            <Button
              variant="outlined"
              startIcon={<EditIcon />}
              onClick={onEdit}
              sx={{
                width: "fit-content",
                textTransform: "none",
                borderRadius: "8px",
                paddingX: 3,
                paddingY: 1.5,
              }}
            >
              Edit Cashback
            </Button>
          </Box>

          {/* Right Side - Cashback Cost Calculator */}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingLeft: { md: 4 },
            }}
          >
            <Typography variant="h4" fontWeight="bold" gutterBottom>
              Cashback Cost Calculator
            </Typography>

            <Typography variant="body1" color="textSecondary" sx={{ mb: 3 }}>
              Curious if your cashback rate is costing you more than you think?
              Use our calculator to find out.
            </Typography>

            <Button
              variant="contained"
              startIcon={<InfoIcon />}
              onClick={handleOpenModal}
              sx={{
                width: "fit-content",
                textTransform: "none",
                borderRadius: "8px",
                paddingX: 3,
                paddingY: 1.5,
              }}
            >
              Calculate Your Costs
            </Button>
          </Box>
        </Stack>
      </Paper>

      {/* Modal for Cost Calculator */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: { xs: 4, sm: 6 },
            borderRadius: 4,
            width: "90%",
            maxWidth: 800,
          }}
        >
          <CostCalculatorModal onClose={handleCloseModal} />
        </Box>
      </Modal>
    </>
  );
};

export default CurrentCashbackCard;
