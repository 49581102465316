// PersonalStart.js
import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

const PersonalStart = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        border: `1px solid #E0E0E0`,
        borderRadius: '16px',
        padding: theme.spacing(4),
        textAlign: 'center',
        backgroundColor: '#F9F9F9',
        cursor: 'default',
      }}
    >
      <PersonIcon sx={{ fontSize: 50, color: '#CCCCCC', marginBottom: theme.spacing(2) }} />
      <Typography variant="h5" gutterBottom sx={{ color: '#333333', fontWeight: '600' }}>
        Personal Information
      </Typography>
      <Typography variant="body1" sx={{ color: '#666666', marginBottom: theme.spacing(4) }}>
        We need to collect your personal and owner information for regulatory purposes.
      </Typography>
      <Typography variant="button" sx={{ color: '#999999', fontWeight: '500' }}>
        Finish Business Information First
      </Typography>
    </Box>
  );
};

export default PersonalStart;
