import { useState, useEffect } from "react";
import { usePlaidLink } from "react-plaid-link";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useCloudFetch } from "../../../../config";
import { setBankData } from "./../../../../features/banking/bankSlice";
import useFullFetchFeedback from "../../../../functions/apiFetchers/fullPageLoaders"; // Assuming fetchFeedback is here

const useInitializePlaid = () => {
  const [openModal, setOpenModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [linkToken, setLinkToken] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fetchFeedback = useFullFetchFeedback(); // Use fetchFeedback function

  const BASE_URL = useCloudFetch
    ? "https://clavaav1.ue.r.appspot.com/api/"
    : "http://192.168.1.187:8000/api/";

    useEffect(() => {
      const fetchLinkToken = async () => {
        try {
          const storedLinkToken = localStorage.getItem("plaidLinkToken");
          const storedExpiry = localStorage.getItem("plaidLinkTokenExpiry");
          const now = new Date();
    
          if (storedLinkToken && storedExpiry && new Date(storedExpiry) > now) {
            console.log("Using stored link token.");
            setLinkToken(storedLinkToken);
            return;
          }
    
          console.log("Fetching a new link token...");
          
          // Wrap fetchFeedback in a Promise directly
          const data = await new Promise((resolve, reject) => {
            fetchFeedback({
              endpoint: "merchant/token/create/browser/",
              requestType: "GET",
              onSuccess: (data) => {
                resolve(data);
              },
              onError: (error) => {
                reject(error);
              },
            });
          });
    
          const expiryDate = new Date();
          expiryDate.setHours(expiryDate.getHours() + 2); // Set expiry for 2 hours
    
          localStorage.setItem("plaidLinkToken", data.link_token);
          localStorage.setItem("plaidLinkTokenExpiry", expiryDate.toISOString());
    
          console.log("New link token fetched and stored:", data.link_token);
          setLinkToken(data.link_token);
        } catch (error) {
          console.error("Failed to fetch link token:", error);
        }
      };
    
      fetchLinkToken();
    }, [BASE_URL, fetchFeedback]);

  const onSuccess = async (public_token, metadata) => {
    console.log(public_token);
    console.log(metadata);
    setOpenModal(true);

    // Exchange the public token for an access token using fetchFeedback
    await fetchFeedback({
      endpoint: "merchant/token/exchange/",
      requestType: "POST",
      data: { public_token: public_token, metadata: metadata },

      onSuccess: (accountData) => {
        dispatch(setBankData(accountData)); // Dispatch the bank data to Redux
        setOpenModal(false);
        setSuccess(true);
      },
      onError: (err) => {
        console.error("Failed to exchange public token:", err);
      },
    });
  };

  const onExit = () => {
    // Handle the case when the user exits the Plaid flow
    navigate(-1);
  };

  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess,
    onExit,
  });

  return { open, ready, openModal, success, setSuccess };
};

export default useInitializePlaid;
