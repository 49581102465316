// src/components/loading/AccountLoading.js
import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import { keyframes } from '@emotion/react';

const pulse = keyframes`
  0% { opacity: 0.8; } 
  50% { opacity: 1; } 
  100% { opacity: 0.8; }
`;

function AccountLoading() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
       
        padding: 2,
        borderRadius: 2,
      }}
    >
      <AccountCircle
        sx={{
          fontSize: 60,
          color: '#1EC674', // Use core color for highlight
          mb: 2,
          animation: `${pulse} 2s infinite`,
        }}
      />
      <CircularProgress sx={{ color: '#1EC674', mb: 2 }} size={50} />
      <Typography
        variant="h6"
        sx={{ color: '#000000', fontFamily: 'SF Pro Text, Helvetica Neue, Arial, sans-serif' }}
      >
        Loading account details...
      </Typography>
    </Box>
  );
}

export default AccountLoading;
