import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  businessInfo: {
    business_name: "",
    business_classification: "",
    business_classification_id: "",
    business_structure: "",
    ein: "",
    address: {},
  },
  personalInfo: {
    first_name: "",
    last_name: "",
    title: "",
    date_of_birth: "",
    address: { address: "", city: "", state: "", zip_code: "" },
    ssn: "",
    isOwner: null,
    isSet: false,
  },
  owners: [],
  businessLoading: false,
  personalLoading: false,
  personalInfoComplete: false,
  verificationstatus: null,
  retryData: null,
  documentData: null,
  retryComplete: false,        // Added for retry completion status
  retryLoading: false,         // Added for retry loading status
  documentComplete: false,     // Added for document completion status
  documentLoading: false,      // Added for document loading status
};

const verificationInfoSlice = createSlice({
  name: 'verificationInfo',
  initialState,
  reducers: {
    setBusinessType: (state, action) => {
      state.businessInfo.business_structure = action.payload;
    },    
    setBusinessName: (state, action) => {
      state.businessInfo.business_name = action.payload;
    },
    setPersonalOwnerStatus: (state, action) => {
      state.personalInfo.isOwner = action.payload;
    },
    setBusinessInfo: (state, action) => {
      state.businessInfo = { ...state.businessInfo, ...action.payload };
    },
    setPersonalInfo: (state, action) => {
      state.personalInfo = { ...state.personalInfo, ...action.payload };
    },
    setPersonalIsSet: (state, action) => {
      state.personalInfo.isSet = action.payload;
    },
    setBusinessLoading: (state, action) => {
      state.businessLoading = action.payload;
    },
    setPersonalLoading: (state, action) => {
      state.personalLoading = action.payload;
    },
    setVerificationstatus: (state, action) => {
      state.verificationstatus = action.payload;
    },
    setPersonalSsn: (state, action) => {
      state.personalInfo.ssn = action.payload;
    },
    addOwner: (state, action) => {
      const newId = state.owners.length > 0 ? state.owners.length : 0;
      state.owners.push({ ...action.payload, id: newId });
    },
    removeOwner: (state, action) => {
      state.owners = state.owners.filter(owner => owner.id !== action.payload);
    },
    setRetryData: (state, action) => {
      state.retryData = action.payload;
    },
    setDocumentData: (state, action) => {
      state.documentData = action.payload;
    },
    setPersonalInfoComplete: (state) => {
      state.personalInfoComplete = true;
    },
    setRetryComplete: (state, action) => {  // New action to set retryComplete
      state.retryComplete = action.payload;
    },
    setRetryLoading: (state, action) => {    // New action to set retryLoading
      state.retryLoading = action.payload;
    },
    setDocumentComplete: (state, action) => {  // New action to set documentComplete
      state.documentComplete = action.payload;
    },
    setDocumentLoading: (state, action) => {   // New action to set documentLoading
      state.documentLoading = action.payload;
    },
    ResetVerificationstatus: (state, action) => {   // New action to set documentLoading
      state.documentComplete = false;
      state.retryComplete = false;
    },
  },
});

export const {
  setBusinessType,
  setBusinessInfo,
  setPersonalInfo,
  setBusinessLoading,
  setPersonalLoading,
  setPersonalOwnerStatus,
  setPersonalIsSet,
  addOwner,
  setVerificationstatus,
  setPersonalSsn,
  removeOwner,
  setRetryData,
  setDocumentData,
  setPersonalInfoComplete,
  setRetryComplete,       // Export new actions
  setRetryLoading,
  setDocumentComplete,
  setDocumentLoading,
  ResetVerificationstatus,
  setBusinessName,
} = verificationInfoSlice.actions;

export default verificationInfoSlice.reducer;