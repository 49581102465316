// src/features/bank/bankSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bankData: {
   
  },
  pendingBalance: 0,
};

const bankSlice = createSlice({
  name: 'bank',
  initialState,
  reducers: {
    setBankData: (state, action) => {
      state.bankData = {...action.payload };
    },
    setPendingBalance: (state, action) => {
      state.pendingBalance = action.payload;
    },
  },
});

export const { setBankData, setPendingBalance } = bankSlice.actions;
export default bankSlice.reducer;
