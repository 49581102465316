import { useState } from "react";
import { carouselItems } from "../constant/employeeConstant";

export default function useInfoDailog() {
  const [showInfoDailog, setShowInfoDailog] = useState(false);
  const [activeIndex, setActineIndex] = useState(0);
  const handleOpenInfoDailog = () => {
    setShowInfoDailog(true);
  };

  const handleCloseInfoDailog = () => {
    setShowInfoDailog(false);
    setActineIndex(0);
  };

  const handleBackButtonClick = () => {
    if (activeIndex === 0) return;
    setActineIndex((prev) => prev - 1);
  };

  const handleNextButtonClick = () => {
    if (activeIndex === 2) {
      return;
    } else {
      setActineIndex((prev) => prev + 1);
    }
  };

  return {
    showInfoDailog,
    handleOpenInfoDailog,
    handleCloseInfoDailog,
    handleNextButtonClick,
    handleBackButtonClick,
    carouselItems,
    activeIndex,
  };
}
