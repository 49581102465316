import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

const TermsPop = ({ open, onClose, title, subTitle, content }) => (
  <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
    <DialogTitle>
      <Typography variant="h5">{title}</Typography>
      {subTitle && <Typography variant="subtitle1" color="textSecondary">{subTitle}</Typography>}
    </DialogTitle>

    <DialogContent dividers>
      {content.map((section, index) => (
        <div key={index} style={{ marginBottom: '16px' }}>
          <Typography variant="h6" gutterBottom>
            {section.header}
          </Typography>
          <Typography variant="body1">
            {section.body}
          </Typography>
        </div>
      ))}
    </DialogContent>

    <DialogActions>
      <Button onClick={onClose} color="primary">
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

export default TermsPop;
