// src/components/loading/Success.js
import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';

function Success({ onGoToDashboard }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'center',
        
        padding: 2,
        borderRadius: 2,
      }}
    >
      <CheckCircleOutline
        sx={{
          fontSize: 60,
          color: '#1EC674',
          mb: 2,
        }}
      />
      <Typography
        variant="h5"
        sx={{ color: '#000000', fontFamily: 'SF Pro Text, Helvetica Neue, Arial, sans-serif', mb: 2 }}
      >
        Success! Account Created
      </Typography>
      <Typography
        variant="body1"
        sx={{ color: '#3C3C43', fontFamily: 'SF Pro Text, Helvetica Neue, Arial, sans-serif', mb: 4, textAlign: 'center' }}
      >
        A sales representative will reach out shortly to help you set up your account. If you have any questions, feel free to contact us at{' '}
        <a href="mailto:help@clavaa.com" style={{ color: '#1EC674', textDecoration: 'none' }}>help@clavaa.com</a>.
      </Typography>
      <Button
        variant="contained"
        onClick={onGoToDashboard}
        sx={{
          backgroundColor: '#1EC674',
          '&:hover': {
            backgroundColor: '#17a85f',
          },
          textTransform: 'none',
          fontSize: '16px',
          padding: '8px 24px',
          borderRadius: '24px',
        }}
      >
        Go to Dashboard
      </Button>
    </Box>
  );
}

export default Success;
