import { useState } from "react";
import useFullFetchFeedback from "../../../../functions/apiFetchers/fullPageLoaders";
import { useDispatch } from "react-redux";
import { deleteEmployee } from "../../../../features/register/registerSlice";

const useDeleteDialog = () => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [email, setEmail] = useState(false);
  const dispatch = useDispatch();
  const fetchFeedback = useFullFetchFeedback();
  const DeleteEmplyeeSubmit = async (email) => {

    
    await fetchFeedback({
    endpoint:`merchant/delete/employee/`,
    loaderType: "dialogueLoader",
    data: { email },
    requestType: "POST",

    loaderMessage: "Deleting Employee...",
    successMessage: "Deleted Employee successfully!",
    // setError, // function to handle validation errors
    onSuccess: (responseData) => {
      console.log(responseData);
      dispatch(deleteEmployee(email));
 
    
    },
    onError: (error) => {
      console.log(error);
    }
    });
};
  const handleOpenDeleteDialog = (email) => {
    setEmail(email);
    setShowDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setShowDeleteDialog(false);
  };

  const handleDelete = () => {
    setShowDeleteDialog(false);
    DeleteEmplyeeSubmit(email);
  };

  return {
    handleOpenDeleteDialog,
    handleCloseDeleteDialog,
    showDeleteDialog,
    handleDelete,
  };
};

export default useDeleteDialog;
