// CashbackOffersPage.js

import React from 'react';
import { Box, Typography, Divider, Grid } from '@mui/material';
import CurrentCashbackOffer from './CurrentCashbackOffer';
import UpdateCashbackOfferForm from './UpdateCashbackOfferForm';
import PastCashbackOffers from './PastCashbackOffers';
import CashBackLogic from '../../functional/organisms/cashBackLogic';
import MasterCardComponent from './cashback/master';

const CashbackOffersPage = () => {
  const {
    currentCashback,
    cashbackHistory,
    handleCashbackChange,
    handleUpdateCashbackSubmit,
    cashbackValue,
    error,
    openSnackbar,
    handleCloseSnackbar,
  } = CashBackLogic();

  return (
    <Box sx={{ p: { xs: 3, md: 6 } }}>
      {/* Page Header */}
      <Typography
        variant="h1"  // Increased header to variant "h1" for bigger text
        gutterBottom
        sx={{ fontWeight: "bold", color: "#000", mb: 1 }}
      >
        Cashback Offers
      </Typography>
      <Typography
        variant="h4"  // Increased subtitle to variant "h4" for emphasis
        color="textSecondary"
        sx={{ mb: 3 }}
      >
        Manage your cashback offers and track their history.
      </Typography>
      <Divider sx={{ mb: { xs: 4, md: 6 } }} />

 <MasterCardComponent/>

      {/* Past Cashback Offers (full width at the bottom) */}
      <PastCashbackOffers cashbackHistory={cashbackHistory} />
    </Box>
  );
};

export default CashbackOffersPage;
