import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import useFullFetchFeedback from "../../../../functions/apiFetchers/fullPageLoaders";
import { setStatus } from "../../../../features/user/userSlice";
import { 
  getAuth, 
  signInWithEmailAndPassword, 
  GoogleAuthProvider, 
  signInWithPopup 
} from "firebase/auth";
import {
  activateLoader,
  deactivateLoader,
} from "../../../../features/loading/loadingSlice";

const useAccountInfo = ({ email, password, setError }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = getAuth();

  // Google Sign-In Provider
  const provider = new GoogleAuthProvider();

  // Handle Email/Password Sign-In
  const handleSubmit = () => {
    dispatch(
      activateLoader({
        loaderType: "pageLoader",
        loaderMessage: "Verifying Your Credentials",
      })
    );

    // Firebase authentication attempt
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        // Navigate to the dashboard on success
        dispatch(deactivateLoader("pageLoader"));
        navigate("/dashboard");
      })
      .catch((error) => {
        dispatch(deactivateLoader("pageLoader"));
        console.error("Firebase authentication error:", error);

        setError({
          email: "",
          password: "Incorrect credentials",
        });
      });
  };

  // Handle Google Sign-In
  const handleGoogleSignIn = () => {
   

    signInWithPopup(auth, provider)
      .then((result) => {
        // Successful sign-in
     
        console.log("Google Sign-In successful:", result.user);

        // Save user status and navigate to the dashboard
        dispatch(setStatus({ isLoggedIn: true }));
        navigate("/dashboard");
      })
      .catch((error) => {
        dispatch(deactivateLoader("pageLoader"));
        console.error("Google Sign-In error:", error);

        setError({
          email: "",
          password: "Google Sign-In failed",
        });
      });
  };

  return {
    handleSubmit,
    handleGoogleSignIn,
  };
};

export default useAccountInfo;