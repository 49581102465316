// validators.js
// Master validator function that delegates to specific validators based on type

import { validatePassword } from "./functions/9password";
import { validateAddress } from "./functions/address";
import { validateDOB } from "./functions/dob";
import { validateEIN } from "./functions/ein";
import { validateEmail } from "./functions/email";
import { validateDetails } from "./functions/hasValues";
import { validatePhoneNumber } from "./functions/number";
import { validatePartialSSN } from "./functions/partialSsn";
import { validateFullSSN } from "./functions/validateFullSsn";
import { validateList } from "./functions/validList";
import { validateNumbers } from "./functions/validNumber";
import { validateWebsite } from "./functions/website";


/**
 * A general-purpose validation function that delegates to the appropriate specific validator
 * based on the provided `type`.
 * 
 * @param {string/ Dict} type - The type of validation to perform. Expected values:
 *                        - 'email': A string representing the email address to validate.
 *                        - 'phoneNumber': A string representing a phone number (expected to contain 10 digits).
 *                        - 'website': A string representing the website URL to validate.
 *                        - 'address': An object containing address parts (street, city, state, zipCode).
 *                        - 'content': An object containing key values to validate and send back dict error.
 *                        - 'fullSSN': A string representing the full SSN (expected to contain 9 digits).
 *                        - 'partialSSN': A string representing the last 4 digits of the SSN.
 *                        - 'dob': A string representing the date of birth in the format mm/dd/yyyy.
 * 
 * @param {any} value - The value to be validated. Should correspond to the `type` parameter:
 *                      - For 'email', provide a string containing the email address.
 *                      - For 'phoneNumber', provide a string containing the phone number.
 *                      - For 'website', provide a string containing the website URL.
 *                      - For 'address', provide an object with the structure: 
 *                        { street: '', city: '', state: '', zipCode: '' }.
 *                      - For 'fullSSN', provide a string containing the full SSN.
 *                      - For 'partialSSN', provide a string containing the last 4 digits of the SSN.
 *                      - For 'dob', provide a string containing the date of birth.
 * 
 * @returns {string|object} - Returns a string with an error message for simple types ('email', 'phoneNumber', 'website', 'fullSSN', 'partialSSN', 'dob'),
 *                            or an object with individual error messages for each part of the address.
 *                            Returns an empty string if the validation passes for simple types,
 *                            and an empty object if all address parts are valid.
 *                            If an unrecognized type is provided, returns the string 'Invalid validation type'.
 */
export function validateField(type, value) {
    switch (type) {
        case 'email':
            return validateEmail(value);
        case 'content':
            return validateDetails(value);
        case 'number':
            return validateNumbers(value);
        case 'phoneNumber':
            return validatePhoneNumber(value);
        case 'website':
            return validateWebsite(value);
        case 'address':
            // `value` should be an object with keys: street, city, state, zipCode
            return validateAddress(value);
        case 'fullSsn':
            return validateFullSSN(value);
        case 'password':
            return validatePassword(value);
        case 'partialSsn':
            return validatePartialSSN(value);
        case 'dob':
            return validateDOB(value);
        case 'list':
            return validateList(value);
        case "ein":
            return validateEIN(value)
        default:
            return 'Invalid validation type';
    }
}
