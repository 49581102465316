import React from "react";
import { Button, Stack, Box, Typography } from "@mui/material";

const nameMapping = {
  personalInfo: "Personal Information",
  businessInfo: "Business Information",
};

const RetryStackUI = ({ data, onItemClick, onSubmit, onBack }) => {
  const renderButton = (category, item, id = null) => {
    const displayName =
      category in nameMapping
        ? nameMapping[category]
        : `Owner - ${item["First Name"].first_name} ${item["Last Name"].last_name}`;

    return (
      <Button
        key={id !== null ? `${category}-${id}` : category}
        fullWidth
        onClick={() => onItemClick({ category, item, id })}
        sx={{
          justifyContent: "center",
          bgcolor: "background.paper",
          border: 0.2,
          borderColor: "grey.300",
          py: 1,
          px: 2,
          textAlign: "center",
          textTransform: "none",
          borderRadius: "0",
          boxShadow: "none",
          ":hover": {
            bgcolor: "grey.100",
            boxShadow: "none",
          },
        }}
      >
        {displayName}
      </Button>
    );
  };

  return (
    <Box sx={{ maxWidth: 500, width: "100%", mx: "auto", textAlign: "center" }}>
      {/* Heading and Subheading */}
      <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
        Resubmit Required Information
      </Typography>
      <Typography variant="body2" sx={{ color: "text.secondary", mb: 3 }}>
        The following information needs to be resubmitted with updates. 
        <br />
        <strong>Submitting the same information again will likely result in failure.</strong> 
        <br />
        Please ensure all details are accurate and up-to-date to improve your chances of successful verification.
      </Typography>

      {/* List of Items to Resubmit */}
      <Stack spacing={2} sx={{ mb: 3 }}>
        {Object.entries(data).map(([category, value]) =>
          Array.isArray(value)
            ? value.map(
                (item, index) =>
                  value != null && renderButton(category, item, item["Id"]?.id)
              )
            : value != null && renderButton(category, value)
        )}
      </Stack>

      {/* Back and Submit Buttons */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={onBack}
          sx={{
            fontSize: '1rem',
            padding: '12px 24px',
            borderRadius: '8px',
            flex: 1,
            mr: 1,
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          sx={{
            fontSize: '1rem',
            padding: '12px 24px',
            borderRadius: '8px',
            flex: 1,
            ml: 1,
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default RetryStackUI;
