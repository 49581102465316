import { FormattedMessage } from 'react-intl';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';

const icons = {
    AppRegistrationIcon,
    AccountCircleIcon,
    SettingsIcon
};

const accountMenu = {
    id: 'account',
    title: <FormattedMessage id="account" />,
    type: 'group',
  
    children: [
        {
            id: 'business-info',
            title: <FormattedMessage id="Business Information" />,
            type: 'item',
            url: '/dashboard/kyc',
            icon: icons.AppRegistrationIcon,
            breadcrumbs: false
        },
        {
            id: 'settings',
            title: <FormattedMessage id="Settings" />,
            type: 'item',
            url: '/settings',
            icon: icons.SettingsIcon,
            breadcrumbs: false
        }
    ]
};

export default accountMenu;
