import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import DynamicForm from '../../../../../../components/atoms/inputs/dynamic';
import { setBusinessInfo, setBusinessType, setBusinessLoading } from '../../../../../../features/verification/verificationInfoSlice';
import { selectBusinessType, selectBusinessInfo } from '../../../../../../features/verification/verificationInfoSelectors';
import businessClassifications from './classifications';
import useFullFetchFeedback from '../../../../../../functions/apiFetchers/fullPageLoaders';
import { setStatus } from "../../../../../../features/user/userSlice";

const BusinessGeneralInfoPageInputsUI = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const fetchFeedback = useFullFetchFeedback();

  const businessInfo = useSelector(selectBusinessInfo);
  const businessType = useSelector(selectBusinessType);
  const [errors, setErrors] = useState("");
  console.log(errors)

  const fieldConfig = [
    {
      type: "text",
      label: "Business Name",
      name: "business_name",
      grid: { xs: 12 },
    },
    { type: "address", label: "Address", name: "address", grid: { xs: 12 } },
    {
      type: "autocomplete",
      label: "Business Classification",
      name: "business_classification_id",
      grid: { xs: 12, sm: 12 },
      options: businessClassifications,
    },
    ...(businessType !== "soleProprietorship"
      ? [
          {
            type: "ein",
            label: "EIN",
            name: "ein",
            grid: { xs: 12 },
          },
        ]
      : []),
  ];


  useEffect(() => {
    const selectedClassification = businessClassifications.find(
      (classification) =>
        classification.value === businessInfo.business_classification_id
    );

    dispatch(setBusinessInfo({
      ...businessInfo,
      business_classification: selectedClassification
        ? selectedClassification.label
        : ""
    }));
  }, [businessInfo.business_classification_id, dispatch]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    dispatch(setBusinessInfo({ [name]: value }));
  };

  const handleSubmit = async () => {
    const validateBusinessDetails = [
      {
        field: "business_name",
        type: "content",
        required: true,
        customMessage: "Business name is required.",
      },
      {
        field: "business_classification_id",
        type: "content",
        required: true,
        customMessage: "Business classification is required.",
      },
      {
        field: "business_structure",
        type: "content",
        required: true,
        customMessage: "Business structure is required.",
      },
      { field: "address", type: "address", required: true },
          ...(businessType !== "soleProprietorship"
      ? [
          {
            type: "ein",
            required: true,
            field: "ein",
            
          
          },
        ]
      : []),
    ];

    await fetchFeedback({
      endpoint: "merchant/business_info/",
      data: { ...businessInfo },
      requestType: "POST",
      setLoader: (isLoading) => dispatch( setBusinessLoading(isLoading)),
      onSuccess: (responseData) => {
        localStorage.setItem('isSoleProp', JSON.stringify(businessType === "soleProprietorship"));
        dispatch(setStatus("personalInfo"));
       
      },
      onError: (error) => {
        console.error(error);
      },    
      validationRules: validateBusinessDetails,
      setError: setErrors,
    });
  };

  const handleBack = () => {
    dispatch(setBusinessInfo({
      business_name: "",
      business_classification: "",
      business_classification_id: "",
      business_structure: "",
      ein: "",
      address: {},
    }));
    dispatch(setBusinessType(""));
   
  };

  const isFormComplete = businessInfo.business_name &&
    businessInfo.business_classification_id &&
    businessInfo.address &&
    (businessType === "soleProprietorship" || businessInfo.ein);

  return (
    <Box sx={{ maxWidth: 500, width: "100%", mx: "auto", textAlign: "center" }}>
      <Typography variant="h5" sx={{ mb: 3 }}>
        Enter Your Business Information
      </Typography>
      
      <DynamicForm
        fieldConfig={fieldConfig}
        formData={businessInfo}
        handleInputChange={handleInputChange}
        errors={errors}
      />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 3,
        }}
      >
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleBack}
          sx={{
            fontSize: '1rem',
            padding: '12px 24px',
            borderRadius: '8px',
            flex: 1,
            mr: 1,
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={ !isFormComplete}
          onClick={handleSubmit}
          sx={{
            fontSize: '1rem',
            padding: '12px 24px',
            borderRadius: '8px',
            flex: 1,
            ml: 1,
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );


};

export default BusinessGeneralInfoPageInputsUI;
