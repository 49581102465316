// ConnectingBankAccountUI.js

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Typography,
  IconButton,
  useTheme,
} from "@mui/material";
import { ArrowDownward as ArrowDownwardIcon, AccountBalance as AccountBalanceIcon } from "@mui/icons-material";
import { keyframes } from "@mui/system";

const ConnectingBankAccountUI = ({ open }) => {
  const theme = useTheme();
  const brandColor = "#1EC674"; // Your brand color

  // Define the animation for the arrow icon
  const moveUpDown = keyframes`
    0% { transform: translateY(0); }
    50% { transform: translateY(15px); }
    100% { transform: translateY(0); }
  `;

  // Dots animation for "Connecting..."
  const [dots, setDots] = React.useState("");
  React.useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev.length >= 3 ? "" : prev + "."));
    }, 500);
    return () => clearInterval(interval);
  }, []);

  return (
    <Dialog
      open={open}
      aria-labelledby="connecting-account-title"
      PaperProps={{
        sx: {
          borderRadius: 2,
          maxWidth: 400,
          p: 3,
          boxShadow: "0 3px 15px rgba(0,0,0,0.2)",
          textAlign: "center",
        },
      }}
    >
      <DialogTitle id="connecting-account-title" sx={{ textAlign: "center", pb: 1 }}>
        Connecting Your Account
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            height: 200,
            mt: 2,
          }}
        >
          <Typography
            variant="h4"
            component="div"
            sx={{ color: brandColor, fontWeight: 600 }}
          >
            Clavaa
          </Typography>
          <IconButton
            sx={{
              color: brandColor,
              animation: `${moveUpDown} 1.5s ease-in-out infinite`,
              fontSize: 50,
              mt: -1,
            }}
            disableRipple
            disableFocusRipple
            aria-label="animated arrow indicating data movement"
          >
            <ArrowDownwardIcon fontSize="large" />
          </IconButton>
          <AccountBalanceIcon sx={{ fontSize: 60, color: brandColor, mt: -1 }} />
          <Typography
            component="div"
            sx={{
              position: "absolute",
              bottom: 0,
              fontSize: "1rem",
              width: "100%",
            }}
          >
            Connecting{dots}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ConnectingBankAccountUI;
