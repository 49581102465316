import React from "react";
import { Autocomplete, TextField } from "@mui/material";

const AutocompleteInput = ({ options, value, onChange, label, name, error, errorMessageAndhelp, commonStyles }) => {
  // Find the option that matches the passed value
  const selectedOption = options.find(option => option.value === value) || null;

  // Create an event-like object for compatibility with the higher-level onChange handler
  const handleAutocompleteChange = (event, newValue) => {
    onChange({ target: { name, value: newValue ? newValue.value : "" } });
  };

  return (
    <Autocomplete
      options={options}
      value={selectedOption}
      onChange={handleAutocompleteChange}
      getOptionLabel={(option) => (option ? option.label : "")}
      isOptionEqualToValue={(option, val) => option.value === val}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          error={error}
          helperText={errorMessageAndhelp}
          sx={commonStyles}
        />
      )}
    />
  );
};

export default AutocompleteInput;
