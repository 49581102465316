import React from "react";
import { Stack, Box, Button, Typography } from "@mui/material";
import DocumentTypeFieldUI from "../molecules/documentTypeFieldUI";
import UploadFieldUI from "../molecules/uploadFieldUI";

const DocumentUploadFieldUI = ({
  documentDetails,
  setSelectedDocument,
  handleDocumentUpload,
  type,
  setType,
  setFile,
  documentTypeFieldOnClick,
  documentUploadFieldOnClick,
  handleFileChange,
  documentName,
}) => {
  return (
    <Box sx={{ maxWidth: 500, width: "100%", mx: "auto", textAlign: "center" }}>
      <Typography variant="h5" sx={{ fontWeight: '600', mb: 1 }}>
        Upload Document
      </Typography>
      <Typography variant="body1" sx={{ color: 'text.secondary', mb: 3 }}>
        Select the document type and upload the file. Make sure the document is clear and complete.
      </Typography>

      <Stack spacing={2} sx={{ mb: 3 }}>
        <DocumentTypeFieldUI
          selectedType={type}
          handleDocumentTypeChange={(e) => setType(e.target.value)}
          document={documentDetails?.document}
          onClick={documentTypeFieldOnClick}
        />
        <UploadFieldUI
          handleFileChange={handleFileChange}
          documentName={documentName}
          onClick={documentUploadFieldOnClick}
        />
      </Stack>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => setSelectedDocument(null)}
          sx={{
            fontSize: '1rem',
            padding: '12px 24px',
            borderRadius: '8px',
            flex: 1,
            mr: 1,
          }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDocumentUpload}
          sx={{
            fontSize: '1rem',
            padding: '12px 24px',
            borderRadius: '8px',
            flex: 1,
            ml: 1,
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default DocumentUploadFieldUI;
