import { createSlice } from "@reduxjs/toolkit";

const initialState = {
transactions :[
    // {
    //   name: "Alice Johnson",
    //   id: "INV12345",
    //   date: "2023-10-01",
    //   amount: 120.0,
    //   cashbackEarned: 5.0,
    //   cashbackAmount: 2.0,
    //   processing_status: "Completed",
    //   register: "1",
    // },
    // {
    //   name: "Bob Smith",
    //   id: "INV12346",
    //   date: "2023-10-02",
    //   amount: 85.5,
    //   cashbackEarned: 3.0,
    //   cashbackAmount: 1.5,
    //   processing_status: "Pending",
    //   register: "1",
    // },
    // {
    //   name: "Carol White",
    //   id: "INV12347",
    //   date: "2023-10-03",
    //   amount: 200.0,
    //   cashbackEarned: 10.0,
    //   cashbackAmount: 5.0,
    //   processing_status: "Completed",
    //   register: "2",
    // },
    // {
    //   name: "David Brown",
    //   id: "INV12348",
    //   date: "2023-10-04",
    //   amount: 150.0,
    //   cashbackEarned: 7.5,
    //   cashbackAmount: 2.0,
    //   processing_status: "Refunded",
    //   register: "1",
    // },
    // {
    //   name: "Eva Green",
    //   id: "INV12349",
    //   date: "2023-10-05",
    //   amount: 95.0,
    //   cashbackEarned: 4.0,
    //   cashbackAmount: 1.0,
    //   processing_status: "Completed",
    //   register: "3",
    // },
    // {
    //   name: "Frank Wilson",
    //   id: "INV12350",
    //   date: "2023-10-06",
    //   amount: 60.0,
    //   cashbackEarned: 2.0,
    //   cashbackAmount: 0.5,
    //   processing_status: "Failed",
    //   register: "3",
    // },
    // {
    //   name: "Grace Lee",
    //   id: "INV12351",
    //   date: "2023-10-07",
    //   amount: 180.0,
    //   cashbackEarned: 8.0,
    //   cashbackAmount: 3.0,
    //   processing_status: "Completed",
    //   register: "4",
    // },
  ],
  
  transactionCount: 0,
  isLoading: false,
  error: null,
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    addTransaction: (state, action) => {
      state.transactions.push(action.payload);
    },
    addTransactions: (state, action) => {
      action.payload.forEach((newTransaction) => {
        if (!state.transactions.find((t) => t.id === newTransaction.id)) {
          state.transactions.push(newTransaction);
        }
      });
    },
    setTransactions: (state, action) => {
      state.transactions = action.payload;
    },
    setTransactionCount: (state, action) => {
      state.transactionCount = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { addTransaction, addTransactions, setTransactions, setTransactionCount, setLoading, setError } =
  transactionsSlice.actions;
export default transactionsSlice.reducer;
