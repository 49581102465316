import React from "react";
import TransactionsContainer from "../organisms/TransactionsContainer";
import MainLayout from "../../../../development/page/parts/layout/MainLayout/index";

const TransactionsPage = () => {
  return (
    <MainLayout centerContent={false}>
      <TransactionsContainer />
    </MainLayout>
  );
};

export default TransactionsPage;
