import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer'; // Import the root reducer

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
    devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools only in development
});

export default store;
