import React, { useState } from "react";
import { Box, Card, CardContent, Typography, Divider, Link } from "@mui/material";
import { AccessTime as PendingIcon } from "@mui/icons-material";
import InfoModal from "./InfoModal"; // Ensure the path is correct based on your project structure
import { selectDwollaId } from "features/user/userSelectors";
import { useSelector } from "react-redux";




const PendingFundsCard = ({ pendingBalance }) => {
  const customerId = useSelector(selectDwollaId);
  const brandColor = "#1EC674"; // Your main brand color
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = (event) => {
    event.preventDefault(); // Prevent default link behavior
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          borderRadius: 2,
          backgroundColor: "#FFFFFF",
          borderColor: "#E0E0E0",
          textAlign: "center", // Center all text
        }}
      >
        <CardContent>
          {/* Header Section with Centered Text */}
          <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
            <PendingIcon fontSize="large" sx={{ color: brandColor }} />
            <Typography variant="h5" sx={{ fontWeight: "bold", mt: 1 }}>
              Funds Awaiting Deposit
            </Typography>
          </Box>

          {/* Subheader / Description Text */}
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ fontSize: "1.125rem", mb: 3 }}
          >
            These are the funds from recent sales that are on their way to your bank account. Funds typically deposit within 3-4 business days.
          </Typography>

          <Divider />

          {/* Pending Amount Section */}
          <Box mt={4}>
            <Typography variant="subtitle1" fontWeight={500}>
              Pending Amount
            </Typography>
            <Typography variant="h3" fontWeight={600} sx={{ mt: 1 }}>
              ${pendingBalance.toFixed(2)}
            </Typography>
          </Box>

          <Divider sx={{ mt: 3 }} />

       

          {/* Informational Text with Modal Trigger */}
          <Box mt={4}>
            <Typography variant="body2" color="textSecondary">
              If we have trouble transferring money to your account for any reason, it will be held in your Clavaa account with partner financial institutions and transferred to you when possible.{" "}
              <Link href="#" onClick={handleOpenModal} underline="hover" sx={{ cursor: "pointer" }}>
                View your balance here.
              </Link>
            </Typography>
          </Box>
        </CardContent>
      </Card>

      {/* Info Modal */}
      <InfoModal open={isModalOpen} onClose={handleCloseModal} customerId={customerId} />
    </>
  );
};

export default PendingFundsCard;
