import { useDispatch } from "react-redux";
import { showDialog } from "../../../../../../../features/dialog/dialogSlice";

const useDocumentTypeField = ({ document }) => {
  const dispatch = useDispatch();
  const onClick = () => {
    dispatch(
      showDialog({
        dialogType: "info",
        message: document?.helpText,
        title: "Document Type Help",
      })
    );
  };

  return {
    onClick,
  };
};

export default useDocumentTypeField;
