// src/components/PersonalStart.js

import React from 'react';
import { Box, Typography, Button, useTheme } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';

const PersonalStart = ({ onGetStarted }) => {
  const theme = useTheme();
  const primaryColor = '#1EC674';

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <PersonIcon sx={{ fontSize: 50, color: primaryColor, marginBottom: theme.spacing(2) }} />
      <Typography variant="h5" gutterBottom sx={{ color: '#333333', fontWeight: '600' }}>
        Personal Information
      </Typography>
      <Typography variant="body1" sx={{ color: '#666666', marginBottom: theme.spacing(4) }}>
        We need to collect your personal information for verification purposes.
      </Typography>
      <Button
        variant="contained"
        endIcon={<span style={{ fontSize: '1.25rem' }}>→</span>}
        onClick={onGetStarted}
        sx={{
          backgroundColor: primaryColor,
          color: '#FFFFFF',
          borderRadius: '30px',
          paddingX: theme.spacing(4),
          paddingY: theme.spacing(1.5),
          textTransform: 'none',
          fontWeight: '500',
          '&:hover': {
            backgroundColor: '#17b563',
          },
        }}
      >
        Get Started
      </Button>
    </Box>
  );
};

export default PersonalStart;
